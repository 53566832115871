import React from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { getVoterSurveyList, putVoterSurveyActive } from '../../services/Voters';
import { RotatingLines } from  'react-loader-spinner';
import Error from '../error';
import withParams from "../GetParams";
import StatusToast from "../toast/StatusToast";
import { Roles } from "../../config/defaults";
import {Operations} from '../toast/operations';

class VoterSurveyList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			voterSurveyList: null,
			loading: false,
			error: '',
		};
		this.handleActive = this.handleActive.bind(this);
	}

	componentDidMount() {
		this.setState({loading: true, voterSurveyList: null});
		getVoterSurveyList().then((voterSurveyList) => {
			this.setState({voterSurveyList, loading: false});
		}).catch((error) => {
			this.setState({error, loading: false});
		});
	}

	handleActive(id, activate) {
		this.setState({loading: true});
		const active = (activate === true ? false : true);
		putVoterSurveyActive(id, {active}).then((activeStatus) => {
			getVoterSurveyList().then((voterSurveyList) => {
				this.setState({voterSurveyList, loading: false});
			}).catch((error) => {
				this.setState({error, loading: false});
			})
			this.setState({activeStatus});
			if(active == true) {
				this.props.navigate(`/voter-survey-list/?op=${Operations.SURVEY_ACTIVATE}&status=success`);
			}
			else {
				this.props.navigate(`/voter-survey-list/?op=${Operations.SURVEY_DEACTIVATE}&status=success`);
			}
		}).catch((activeErr) => {
			this.setState({activeErr, loading: false});
			if(active == true && activeErr.response.status != 403) {
				this.props.navigate(`/voter-survey-list/?op=${Operations.SURVEY_ACTIVATE}&status=error`);
			}
			else if(activeErr.response.status == 403) {
				this.props.navigate(`/voter-survey-list/?op=${Operations.SURVEY_ACCESS_STATUS}&status=error`);
			}
			else {
				this.props.navigate(`/voter-survey-list/?op=${Operations.SURVEY_DEACTIVATE}&status=error`);
			}
		})
	}

	render() {
		return(
			<div>
			<StatusToast delayTime="3000"/>
				{
					this.props.role == Roles.AC_INCHARGE.value || this.props.role == Roles.SUPER_ADMIN.value ? 
						<div className="col-md-12 text-right m-2">
							<Link to={`/voter-survey`} className='btn btn-success'>Add New Voter Survey</Link>
						</div> : ''
				}
				<div className="card">
					<div className="card-body">
						<div className="overflow-x">
						{this.state.voterSurveyList != null ? 
							<table className="table table-striped ">
								<thead>
									<tr className="bg-primary">
										<th className="w-10 text-center">Sl. No.</th>
										<th>Survey Name</th>
										<th>No. of Questions</th>
										<th>No. of Responses</th>
										<th>created on</th>
										<th className="text-center">View</th>
									</tr>
								</thead>
								<tbody>
								{
									this.state.voterSurveyList?.map((voterSurvey, index) => 
										<tr key={index}>
											<td className="text-center align-middle">{index + 1}</td>
											<td className="align-middle"><Link to={`/voter-survey-view/${voterSurvey.id}`} className="align-self-center no-underline" >{voterSurvey.name}</Link></td>
											<td className="align-middle">{voterSurvey.no_of_questions}</td>
											<td className="align-middle">
												{voterSurvey.no_of_responses === 0 ? <span className="fw-600 text-danger">No Responses</span> : <Link to={`/voter-survey-responses/${voterSurvey.id}`} className="no-underline">{voterSurvey.no_of_responses}</Link>}
											</td>
											<td className="align-middle">{voterSurvey.created_on}</td>
											<td className="d-flex flex-row justify-content-end">


												{voterSurvey.no_of_responses === 0 ? <span className="fw-600 text-danger">No Responses</span> : <Link to={`/voter-survey-responses/${voterSurvey.id}`} className="btn btn-secondary text-white align-self-center">View Responses</Link>}

												{voterSurvey.active === true ? <button className="btn btn-danger mx-2 align-self-center" onClick={() => this.handleActive(voterSurvey.id, voterSurvey.active)}>Deactivate</button> : <button className="btn btn-success mx-2 align-self-center" onClick={() => this.handleActive(voterSurvey.id, voterSurvey.active)}>Activate</button>}
												{
													voterSurvey.active === true ? 
													<Link to={`/voter-survey-submit/${voterSurvey.id}`} className="btn btn-info text-white align-self-center">Submit Survey</Link> : ''
												}
											</td>
										</tr>
									)
								}
								</tbody>
							</table> : this.state.loading == true ? <div className='data-loader text-center m-5'>
								<RotatingLines strokeColor="grey"
				                  strokeWidth="5"
				                  animationDuration="0.75"
				                  width="96"
				                  visible={true} />
							</div> : this.state.error != '' ? 
							<Error /> : <div className="text-center fw-600 fs-6 text-black-50">No data found.</div>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withParams(VoterSurveyList);