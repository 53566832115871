import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link  } from 'react-router-dom';
import Combobox from "react-widgets/Combobox";
import Constituencies from "../Constituencies";
import Blocks from "../Blocks";
import Villages from "../Villages";
import PollingStations from "../PollingStations";
import { getSearchVoter } from '../../services/Voters';
import { formFilterFields, getStateConstituencies, getBlocks, getVillages, getPollingStations, getDesignation } from '../../services/Voters';

class AddIncharge extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        err: '',
        voterList: [],
        voterError:'',
        voterName:'',
        voterMobile:'',
        constituencies: [],
        selectedConstituency: null,
        selectedBlock: null,
        selectedVillage: null,
        selectedPollingStation: null,
        blocks: [],
        pollingStations: [],
        voterDetails: null,
        voterFormDetail : [],
        villages: [],
        phoneNumber: null,

        // error states
        voterIdError:'',
        voterNameError:'',
        voterMobileError:'',
        voterConstituencies:'',
        voterBlocks:'',
        voterVillage:'',
        voterPollingStation:'',
        comments:'',
        voterConstituenciesError: null,
        voterBlocksError: null,
        voterVillageError: null,
        voterPollingStationError: null,
        voterMobileError: null
      };

      this.handleVoter = this.handleVoter.bind(this);
      this.onChangeConstituencies = this.onChangeConstituencies.bind(this);
      this.onChangeBlock = this.onChangeBlock.bind(this);
      this.onChangeVillage = this.onChangeVillage.bind(this);
      this.handleSelectVoter = this.handleSelectVoter.bind(this);
      this.onChangePollingStation = this.onChangePollingStation.bind(this);
      this.handleSubmitIncharge = this.handleSubmitIncharge.bind(this);
      this.handlePhoneNumber = this.handlePhoneNumber.bind(this);
      this.handleComments = this.handleComments.bind(this);
  }

  handleComments(event) {
    this.setState({comments: event.target.value});

  }

  handlePhoneNumber(event) {
    this.setState({phoneNumber: event.target.value, voterMobileError: null});
  }

  handleSubmitIncharge(event){
    event.preventDefault();
    this.setState({voterIdError:''});
    this.setState({voterNameError:''});
    this.setState({voterMobileError:''});
    this.setState({voterConstituenciesError:''});
    this.setState({voterBlocksError: null});
    this.setState({voterVillageError: null});
    this.setState({voterPollingStationError: null});

    if(this.state.voterDetails?.id == '' || this.state.voterDetails?.id == undefined) {
      this.setState({voterIdError: "Please select voter id." });
    }

    if(this.state.phoneNumber == null) {
      this.setState({voterMobileError: "Please fill mobile number." });
    }

    if(this.state.selectedConstituency == null) {
      this.setState({voterConstituenciesError: "Please select constituency." })
    }

    if(this.state.selectedBlock == null) {
      this.setState({voterBlocksError: "Please select block." });
    }
    
    if(this.state.selectedVillage == null) {
      this.setState({voterVillageError: "Please select village." });
    }
    
    if(this.state.selectedPollingStation == null) {
      this.setState({voterPollingStationError: "Please select polling station." });
    }

    if(this.state.voterDetails != '' || this.state.phoneNumber != '' || this.state.selectedConstituency != '' || this.state.selectedBlock != '' || this.state.selectedVillage != '' || this.state.selectedPollingStation != '') {
      // let casteObject = {'voterId': voterId, 'voterName': voterName, 'voterMobile': voterMobile, 'voterConstituencies': voterConstituencies, 'voterBlocks': voterBlocks, 'voterVillage': voterVillage, 'voterPollingStation':voterPollingStation, 'comments':comments };
      // let voterFormDetail = this.state.voterFormDetail;
      // voterFormDetail = [ ...voterFormDetail, casteObject ];
    }
  }

  handleSelectVoter(event) {

    this.setState({voterIdError:''});
    this.setState({voterNameError:''});
    this.setState({voterMobileError:''});
    this.setState({voterConstituenciesError:''});
    this.setState({voterBlocksError: null});
    this.setState({voterVillageError:''});
    this.setState({voterPollingStationError:''});
    this.setState({selectedBlock: event.polling_station.village.block, selectedConstituency: event.polling_station.constituency, selectedVillage: event.polling_station.village, selectedPollingStation: event.polling_station, phoneNumber: event.mobile_number})
    
    getBlocks(event.polling_station.village.block.constituency.id).then((blocks) => {
      this.setState({blocks});
    }).catch((err) => {
      this.setState({err});
    })

    getVillages(event.polling_station.village.block.id).then((villages) => {
      this.setState({villages, voterDetails: event});
    }).catch((err) => {
      this.setState({err});
    })

    getPollingStations(event.polling_station.village.id).then((pollingStations) => {
      this.setState({pollingStations});
    }).catch((err) => {
      this.setState({err});
    })
  }

  handleVoter(event) {
    let eventValue = '';
    if(typeof event === 'object' && event !== null) {
      // eventValue = event.id_card_number;
    }
    else {
      eventValue = event;
    }
    getSearchVoter(eventValue).then((voterList) => {
      this.setState({voterList});
    }).catch((err) => {
      throw err;
    })
  }

  onChangeConstituencies(selectedConstituencyId, selectedConstituency) {
    const voterDetails = this.state.voterDetails;
    if(voterDetails != null ) {
      const pollingStation = this.state.voterDetails.polling_station;
      if(selectedConstituencyId != this.state.voterDetails?.polling_station.constituency.id) {
        const constituency = {id: selectedConstituencyId, name: selectedConstituency.name, number: selectedConstituency.number, district: selectedConstituency.district, slug: selectedConstituency.slug, state: selectedConstituency.state};
        const newPollingStation = {...pollingStation, constituency};
        const data = {...voterDetails, polling_station: newPollingStation};
        this.setState({voterDetails: data, selectedConstituency});
      }
      if(selectedConstituency.id != undefined) {
        getBlocks(selectedConstituency.id).then((blocks) => {
          this.setState({blocks, villages: [], pollingStations: [], selectedBlock: null, selectedVillage: null, selectedPollingStation: null});
        }).catch((err) => {
          console.log(err);
        })
      }
      else {
        this.setState({blocks: [], selectedBlock: null, selectedVillage: null, selectedPollingStation: null});
      }
    }
    else {
      this.setState({voterIdError: "Please select voter id."});
    }
  }

  onChangeBlock(selectedBlock) {
    this.setState({voterBlocksError: null});
      getVillages(selectedBlock).then((villages) => {
        let selectedConstituency = this.state.selectedConstituency;
        this.setState({selectedConstituency, selectedBlock, villages, selectedPollingStation: null, selectedVillage: null});
      }).catch((err) => {
        console.log(err);
      })
    }

   onChangeVillage(selectedVillage) {
    this.setState({voterVillageError: null})
    getPollingStations(selectedVillage).then((pollingStations) => {
      let selectedConstituency = this.state.selectedConstituency;
      let selectedBlock = this.state.selectedBlock;
      this.setState({selectedVillage, selectedBlock, selectedConstituency, selectedPollingStation: null, pollingStations});
    })
  }

  onChangePollingStation(selectedPollingStation) {
    let selectedConstituency = this.state.selectedConstituency;
    let selectedBlock = this.state.selectedBlock;
    let selectedVillage = this.state.selectedVillage;
    this.setState({selectedPollingStation, selectedConstituency, selectedBlock, selectedVillage, voterPollingStationError: null});
  }

  componentDidMount() {
    getStateConstituencies().then((constituencies) => {
      let selectedConstituency = 86
      getBlocks(selectedConstituency).then((blocks) => {
        this.setState({blocks, selectedConstituency});
      }).catch((err) => {
        this.setState({err});
      })
      getDesignation().then((designation) => {
        this.setState({ designation, constituencies });
      }).catch((err) => {
        console.log(err);
      })
    }).catch((err) => {
      // alert("Server error, please try again later.");
    });
  }

  render() {
    const fieldProperties = formFilterFields;
    return(
      <div className='content-wrapper px-3'>
        <div className='container-fluid pt-3'>
          <div className='card'>
            <div className='card-body'>
              <form action=''>
                <table className='table'>
                  <thead>
                    <tr>
                      <th className='text-center' colSpan={2}>Add Incharge</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='w-50 table-form-label-display align-middle fw-bold dtr-control sorting_1'>Incharge Voter Id</td>
                      <td className='w-50'>
                        <label className='d-block d-sm-none d-md-none d-lg-none'>Incharge Voter Id</label>
                        <Combobox data={this.state.voterList} options={this.state.voterList} dataKey='id' textField='id_card_number' onChange={this.handleVoter} onSelect={this.handleSelectVoter} placeholder={`Please select voter id.`} />
                        <span className="text-danger fw-600">{this.state.voterIdError && this.state.voterIdError}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Name</td>
                      <td>
                      <label className='d-block d-sm-none d-md-none d-lg-none'>Name</label>
                        <input className='form-control' type="text" placeholder='Name' defaultValue={this.state.voterDetails != null ? `${this.state.voterDetails?.first_name} ${this.state.voterDetails?.last_name}` : ''} disabled  />
                        <span className="text-danger fw-600">{this.state.voterNameError && this.state.voterNameError}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Mobile</td>
                      <td>
                        <label className='d-block d-sm-none d-md-none d-lg-none'>Mobile</label>
                        <input className='form-control' type="tel" placeholder='Mobile' value={this.state.phoneNumber != null ? this.state.phoneNumber : ''} onChange={this.handlePhoneNumber} />
                        <span className="text-danger fw-600">{this.state.voterMobileError && this.state.voterMobileError}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Assembly Constituency</td>
                      <td>
                        <label className='d-block d-sm-none d-md-none d-lg-none'>Constituencies</label>
                        <Constituencies constituencyFields={formFilterFields} onChangeConstituencies={this.onChangeConstituencies} constituencies={this.state.constituencies} selectedConstituencyObj={this.state.selectedConstituency ? this.state.selectedConstituency : '' } selectedConstituency={this.state.selectedConstituency?.id} />
                        <span className="text-danger fw-600">{this.state.voterConstituenciesError && this.state.voterConstituenciesError}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Block</td>
                      <td>
                        <label className='d-block d-sm-none d-md-none d-lg-none'>Block</label>
                        <Blocks blockFields={formFilterFields} blocks={this.state.blocks} onChangeBlock={this.onChangeBlock} blockValue={this.state.blockValue} selectedBlock={this.state.selectedBlock ? this.state.selectedBlock : ''} />
                        <span className="text-danger fw-600">{this.state.voterBlocksError && this.state.voterBlocksError}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Village</td>
                      <td>
                        <label className='d-block d-sm-none d-md-none d-lg-none'>village</label>
                        <Villages villageFields={formFilterFields} villages={this.state.villages} onChangeVillage={this.onChangeVillage} selectedVillage={this.state.selectedVillage} selectedBlock={this.state.selectedBlock ? this.state.selectedBlock : ''} />
                        <span className="text-danger fw-600">{this.state.voterVillageError && this.state.voterVillageError}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Polling Station</td>
                      <td>
                        <label className='d-block d-sm-none d-md-none d-lg-none'>Polling Station</label>
                        <PollingStations pollingStationFields={formFilterFields} pollingStations={this.state.pollingStations} onChangePollingStation={this.onChangePollingStation} selectedBlock={this.state.selectedBlock ? this.state.selectedBlock : ''} selectedPollingStation={this.state.selectedPollingStation} />
                        <span className="text-danger fw-600">{this.state.voterPollingStationError && this.state.voterPollingStationError}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Comments</td>
                      <td>
                        <label className='d-block d-sm-none d-md-none d-lg-none'>Comments</label>
                        <textarea className='form-control' placeholder='Please provide your comments here.' onChange={this.handleComments} value={this.state.comments}></textarea>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="text-right">
                        <Link to="" className='btn btn-success mx-2 px-3' onClick={this.handleSubmitIncharge} >Save</Link>
                        <Link to="/house-incharges" className='btn btn-danger mx-2 px-3'>Cancel</Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AddIncharge;