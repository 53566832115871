import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Voters.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAsc, faSortDesc, faSort } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../Pagination';
import { getVoterSummary } from '../../services/Voters';
import { RotatingLines } from  'react-loader-spinner';
import Error from '../error';


class VoterSummaryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voterSummary: [],
      pageNumber: [],
      fieldName: '',
      sortOrder: '',
      pageNumber: '',
      sortIcon: '',
      error: ''
    };
    this.onSortOrder = this.onSortOrder.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
  }

  onChangePage(event) {
    const fieldName = this.state.fieldName;
    const sortOrder = this.state.sortOrder;
    const pageNumber = event;
    getVoterSummary(fieldName, sortOrder, pageNumber).then((voterSummary) => {
      this.setState({voterSummary, fieldName, sortOrder, pageNumber});
    }).catch((error) => {
      this.setState({error:error});
    })
  }

  onSortOrder(event) {
    const fieldName = event;
    let prevSortOrder = this.state.sortOrder;
    const prevFieldName = this.state.fieldName;
    let sortOrder = '';
    let sortIcon = '';
    if(prevFieldName != fieldName) {
      prevSortOrder = '';
    }
    if(prevSortOrder == '') {
      sortOrder = 'ASC';
      sortIcon = faSortAsc;
    }
    else if(prevSortOrder == 'ASC') { 
      sortOrder = 'DESC';
      sortIcon = faSortDesc;
    }
    else if(prevSortOrder == 'DESC') {
      sortOrder = '';
      sortIcon = '';
    }    
    const pageNumber = '';
    getVoterSummary(fieldName, sortOrder, pageNumber).then((voterSummary) => {
      this.setState({voterSummary, fieldName, sortOrder, pageNumber, sortIcon});
    }).catch((err) => {
      console.log(err);
    })
  }

  componentDidMount() {
    const fieldName = '';
    const sortOrder = '';
    const pageNumber = '';
    getVoterSummary(fieldName, sortOrder, pageNumber).then((voterSummary) => {
      this.setState({voterSummary});
    }).catch((err) => {
      console.log(err);
    })
  }

  render() {
    return(
      <div className='pt-3'>
      {this.state.voterSummary != '' ? 
        <table className='table table-bordered w-100 table-responsive-sm'>
          <thead className='bg-secondary bg-opacity-10'>
            <tr className=' fw-600 text-center text-dark'>
              <td colSpan="2"></td>
              <td colSpan="7">Voter Count</td>
              <td colSpan="2">Village leader</td>
            </tr>
            <tr className='fw-600 text-center text-dark'>
              <th>PollingStation No.
                <div className={`d-inline ${this.state.fieldName == 'pollingStation' ? (this.state.sortIcon == '' ? 'py-1 px-2' : 'py-1 px-2' )  : 'py-1 px-2'} bg-gray bg-opacity-10 rounded-3 float-end`} onClick={() => this.onSortOrder('pollingStation')}>
                  {this.state.fieldName == 'pollingStation' ? ( this.state.sortIcon == '' ? <FontAwesomeIcon icon={faSort} /> : <FontAwesomeIcon icon={ this.state.sortIcon } />) : <FontAwesomeIcon icon={faSort} /> }
                </div>
              </th>
              <th>Houses 
                <div className={`d-inline ${this.state.fieldName == 'houses' ? (this.state.sortIcon == '' ? 'py-1 px-2' : 'py-1 px-2' )  : 'py-1 px-2'}  bg-gray bg-opacity-10 rounded-3 float-end`} onClick={() => this.onSortOrder('houses')}>
                  { this.state.fieldName == 'houses' ? ( this.state.sortIcon == '' ? <FontAwesomeIcon icon={ faSort } /> : <FontAwesomeIcon icon={ this.state.sortIcon } />) : <FontAwesomeIcon icon={ faSort } />}
                </div>
              </th>
              <th>Male 
                <div className={`d-inline ${this.state.fieldName == 'male' ? (this.state.sortIcon == '' ? 'py-1 px-2' : 'py-1 px-2' )  : 'py-1 px-2'}  bg-gray bg-opacity-10 rounded-3 float-end`} onClick={() => this.onSortOrder('male')}>
                {this.state.fieldName == 'male' ? (this.state.sortIcon == '' ? <FontAwesomeIcon icon={ faSort } /> : <FontAwesomeIcon icon={ this.state.sortIcon } />) : <FontAwesomeIcon icon={ faSort } />}
                </div>
              </th>
              <th>Female 
                <div className={`d-inline ${this.state.fieldName == 'female' ? (this.state.sortIcon == '' ? 'py-1 px-2' : 'py-1 px-2' )  : 'py-1 px-2'}  bg-gray bg-opacity-10 rounded-3 float-end`} onClick={() => this.onSortOrder('female')}>
                {this.state.fieldName == 'female' ? (this.state.sortIcon == '' ? <FontAwesomeIcon icon={ faSort } /> : <FontAwesomeIcon icon={ this.state.sortIcon } />) : <FontAwesomeIcon icon={ faSort } />}
                </div>
              </th>
              <th>Total 
                <div className={`d-inline ${this.state.fieldName == 'total' ? (this.state.sortIcon == '' ? 'py-1 px-2' : 'py-1 px-2' )  : 'py-1 px-2'}  bg-gray bg-opacity-10 rounded-3 float-end`} onClick={() => this.onSortOrder('total')}>
                {this.state.fieldName == 'total' ? (this.state.sortIcon == '' ? <FontAwesomeIcon icon={ faSort } /> : <FontAwesomeIcon icon={ this.state.sortIcon } />) : <FontAwesomeIcon icon={ faSort } />}
                </div>
              </th>
              <th>Death/NRI 
                <div className={`d-inline ${this.state.fieldName == 'death' ? (this.state.sortIcon == '' ? 'py-1 px-2' : 'py-1 px-2' )  : 'py-1 px-2'}  bg-gray bg-opacity-10 rounded-3 float-end`} onClick={() => this.onSortOrder('death')}>
                  {this.state.fieldName == 'death' ? (this.state.sortIcon == '' ? <FontAwesomeIcon icon={ faSort } /> : <FontAwesomeIcon icon={ this.state.sortIcon } />) : <FontAwesomeIcon icon={ faSort } />}
                </div>
              </th>
              <th>Out Station 
                <div className={`d-inline ${this.state.fieldName == 'outStation' ? (this.state.sortIcon == '' ? 'py-1 px-2' : 'py-1 px-2' )  : 'py-1 px-2'}  bg-gray bg-opacity-10 rounded-3 float-end`} onClick={() => this.onSortOrder('outStation')}>
                  { this.state.fieldName == 'outStation' ? (this.state.sortIcon == '' ? <FontAwesomeIcon icon={ faSort } /> : <FontAwesomeIcon icon={ this.state.sortIcon } />) : <FontAwesomeIcon icon={ faSort } />}
                </div>
              </th>
              <th>Votes 
                <div className={`d-inline ${this.state.fieldName == 'votes' ? (this.state.sortIcon == '' ? 'py-1 px-2' : 'py-1 px-2' )  : 'py-1 px-2'}  bg-gray bg-opacity-10 rounded-3 float-end`} onClick={() => this.onSortOrder('votes')}>
                  {this.state.fieldName == 'votes' ? (this.state.sortIcon == '' ? <FontAwesomeIcon icon={ faSort } /> : <FontAwesomeIcon icon={ this.state.sortIcon } />) : <FontAwesomeIcon icon={ faSort } />}
                </div>
              </th>
              <th>Survey Completed 
                <div className={`d-inline ${this.state.fieldName == 'surveyCompleted' ? (this.state.sortIcon == '' ? 'py-1 px-2' : 'py-1 px-2' )  : 'py-1 px-2'}  bg-gray bg-opacity-10 rounded-3 float-end`} onClick={() => this.onSortOrder('surveyCompleted')}>
                  {this.state.fieldName == 'surveyCompleted' ? (this.state.sortIcon == '' ? <FontAwesomeIcon icon={ faSort } /> : <FontAwesomeIcon icon={ this.state.sortIcon } />) : <FontAwesomeIcon icon={ faSort } />}
                </div>
              </th>
              <th className='text-start'>Name 
                <div className={`d-inline ${this.state.fieldName == 'name' ? (this.state.sortIcon == '' ? 'py-1 px-2' : 'py-1 px-2' )  : 'py-1 px-2'}  bg-gray bg-opacity-10 rounded-3 float-end`} onClick={() => this.onSortOrder('name')}>
                  {this.state.fieldName == 'name' ? (this.state.sortIcon == '' ? <FontAwesomeIcon icon={ faSort } /> : <FontAwesomeIcon icon={ this.state.sortIcon } />) : <FontAwesomeIcon icon={ faSort } />}
                </div>
              </th>
              <th className='text-start'>Mobile </th>
            </tr>
          </thead>
          <tbody>
            {this.state.voterSummary.result && this.state.voterSummary.result.map(summary => 
              <tr className='text-center' key={summary.id}>
                <td>{summary.pollingStations}</td>
                <td>{summary.houses}</td>
                <td>{summary.genderMale}</td>
                <td>{summary.genderFemale}</td>
                <td>{summary.total}</td>
                <td>{summary.deathNri}</td>
                <td>{summary.outStation}</td>
                <td>{summary.votes}</td>
                <td>{summary.surveyCompleted}</td>
                <td className='text-start'>{summary.name}</td>
                <td className='text-start'>{summary.mobile}</td>
              </tr>
              )}
            <tr className=' fw-600 text-center bg-secondary bg-opacity-10 text-dark'>
              {/* <td>Total</td>
              <td>{this.state.voterSummary.reduce((totalHouses, {houses}) => totalHouses + houses, 0)}</td>
              <td>{this.state.voterSummary.reduce((totalHouses, {genderMale}) => totalHouses + genderMale, 0)}</td>
              <td>{this.state.voterSummary.reduce((totalHouses, {genderFemale}) => totalHouses + genderFemale, 0)}</td>
              <td>{this.state.voterSummary.reduce((totalHouses, {total}) => totalHouses + total, 0)}</td>
              <td>{this.state.voterSummary.reduce((totalHouses, {deathNri}) => totalHouses + deathNri, 0)}</td>
              <td>{this.state.voterSummary.reduce((totalHouses, {outStation}) => totalHouses + outStation, 0)}</td>
              <td>{this.state.voterSummary.reduce((totalHouses, {votes}) => totalHouses + votes, 0)}</td>
              <td>{this.state.voterSummary.reduce((totalHouses, {surveyCompleted}) => totalHouses + surveyCompleted, 0)}</td>
              <td></td>
              <td></td> */}
            </tr>
            <tr>
              <td colSpan={11} className="text-center">
                <Pagination pageCount={this.state.voterSummary.count} onChangePage={this.onChangePage} />
              </td>
            </tr>
          </tbody>
        </table>: this.state.error != '' ? 
              <Error /> :
              <div className='data-loader text-center'>
                <RotatingLines strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="96"
                          visible={true} />
              </div>}
      </div>
    );
  }
}

export default VoterSummaryTable;