import React from "react";
import { getPollingStationIncharges } from '../../services/Voters';
import Pagination from '../Pagination';
import { Link } from 'react-router-dom';
import { RotatingLines } from  'react-loader-spinner';
import Error from '../error';


class PollingStationIncharges extends React.Component {
  constructor(props) {
	super(props);
	this.state = {
	  pollingStationIncharges: [],
	  error: '',
	};
	this.onChangePage = this.onChangePage.bind(this);
  }

  componentDidMount() {
	getPollingStationIncharges('', '', '', '').then((pollingStationIncharges) => {
	  this.setState({pollingStationIncharges});
	}).catch((error) => {
	  this.setState({error});
	})
  }

  onChangePage(event) {
	getPollingStationIncharges('', '', '', event).then((pollingStationIncharges) => {
	  this.setState({pollingStationIncharges});
	}).catch((error) => {
		this.setState({error});
	})
  }

  render() {
	return(
		<>
			<div className="row">
				<div className="col-12">
					<h3 className="d-inline">PollingStation Incharges</h3>
					{/* <Link to={`/add-new-pollingStation-incharge`} className='btn btn-success d-inline float-right my-3'>Add PS Incharge</Link> */}
				</div>
			</div>
			<div className="card">
			<div className="card-body">
				{ this.state.pollingStationIncharges != '' ? 
				<div>
				<table className="table table-striped">
				<thead className="bg-primary">
					<tr>
						<th className="align-middle">PollingStation No</th>
						<th className="align-middle">Name</th>
						<th className="align-middle">Mobile</th>
						<th className="align-middle">Assigned Count</th>
						<th className="align-middle">Surveyed Count</th>
						<th className="align-middle">Completed %</th>
					</tr>
				</thead>
				<tbody>
					{this.state.pollingStationIncharges.result && this.state.pollingStationIncharges.result.map((incharge, index) => 
					<tr key={ index }>
						<td className="align-middle">{ incharge.pollingStationNo }</td>
						<td className="align-middle">{ incharge.name }</td>
						<td className="align-middle">{ incharge.mobile }</td>
						<td className="align-middle">{ incharge.assignedCount }</td>
						<td className="align-middle">{ incharge.surveyedCount }</td>
						<td className="align-middle">{ incharge.completed }</td>
					</tr>
					)}
				</tbody>
				</table>
				<div className="text-center align-middle">
					<Pagination pageCount={this.state.pollingStationIncharges.count} onChangePage={this.onChangePage} />
				</div>
				</div>
				 : this.state.error != '' ? 
							<Error /> :
							<div className='data-loader text-center'>
								<RotatingLines strokeColor="grey"
				                  strokeWidth="5"
				                  animationDuration="0.75"
				                  width="96"
				                  visible={true} />
							</div>
				}
			</div>
			</div>
		</>
	)
  }
}

export default PollingStationIncharges;