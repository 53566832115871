import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEye, faEdit } from '@fortawesome/free-solid-svg-icons';
import { getVillageProfileList } from '../../services/Voters';
import { RotatingLines } from  'react-loader-spinner';
import Error from '../error';

class VillageProfileList extends React.Component {
  constructor(props) {
	super(props);
	this.state = {
	  villageProfileList: [],
	  villageProfileListError: [],
	  error: '',
	}
  }

  componentDidMount() {
	getVillageProfileList().then((villageProfileList) => {
	  this.setState({villageProfileList});
	}).catch((error) => {
	  this.setState({error});
	})
  }

  render() {
	return( 
				<>
				<div className="col-md-12 text-right m-2">
						<Link to={`/village-profile`} className="btn btn-success text-white mb-2">Add New Village Profile</Link>
	      </div>
		  	<div className="card">
					<div className="card-body">
					{ this.state.villageProfileList != '' ?
					  <table className="table table-striped">
							<thead>
								<tr className="fw-700 bg-primary">
									<td>Sl. No.</td>
									<td>Village</td>
									<td>Block</td>
									<td>Population</td>
									<td>Voter Count</td>
									<td>Category</td>
									<td className="text-center">Action</td>
								</tr>
							</thead>
							<tbody>
								{ this.state.villageProfileList?.map((villageProfile, index) => 
									<tr key={villageProfile.id}>
										<td>{index + 1}</td>
										<td>{villageProfile.village}</td>
										<td>{villageProfile.block}</td>
										<td>{villageProfile.population}</td>
										<td>{villageProfile.voter}</td>
										<td>{villageProfile.category}</td>
										<td className="text-center">
										<Link to={`/village-profile-view/${villageProfile.id}`} className='pe-3'>
											<FontAwesomeIcon icon={ faEye } />
										</Link>
										<Link to={`/village-profile-update/${villageProfile.id}`} className='ps-3'>
											<FontAwesomeIcon icon={ faEdit } />
										</Link>
										</td>
									</tr>
								)}
							</tbody>
			  		</table>: this.state.error != '' ? 
							<Error /> :
							<div className='data-loader text-center'>
								<RotatingLines strokeColor="grey"
				                  strokeWidth="5"
				                  animationDuration="0.75"
				                  width="96"
				                  visible={true} />
							</div>
				}
					</div>
		  	</div>
		 </>
		)
  }
}

export default VillageProfileList;