import React from "react";
import { getOpinion, getNameFromList } from '../../services/Voters';

class updatePartyPerceptionAnalysis extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			partyPerceptionAnalysis: [],
			opinionList: []
		}
		this.handleParty = this.handleParty.bind(this);
		this.handlePerception = this.handlePerception.bind(this);
		this.handleStrengths = this.handleStrengths.bind(this);
		this.handleWeaknesses = this.handleWeaknesses.bind(this);
	}

	componentDidMount() {
		getOpinion().then((opinionList) => {
			this.setState({opinionList});
		}).catch((err) => {
			this.setState({err});
		})
		this.setState({partyPerceptionAnalysis: this.props.partyPerceptionAnalysis});
	}

	handleParty = (event, id) => {
		const party = {id: event.target.value, name: getNameFromList(this.state.opinionList, event.target.value)};
		this.setState({
			partyPerceptionAnalysis: this.state.partyPerceptionAnalysis?.map(item => (item.id == id ? {...item, party} : item))
		});
	}

	handlePerception = (event, id) => {
		this.setState({
			partyPerceptionAnalysis: this.state.partyPerceptionAnalysis?.map(item => (item.id == id ? {...item, perception: event.target.value} : item))
		});
	}

	handleStrengths = (event, id) => {
		this.setState({
			partyPerceptionAnalysis: this.state.partyPerceptionAnalysis?.map(item => (item.id == id ? {...item, strengths: event.target.value} : item))
		});
	}

	handleWeaknesses = (event, id) => {
		this.setState({
			partyPerceptionAnalysis: this.state.partyPerceptionAnalysis?.map(item => (item.id == id ? {...item, weaknesses: event.target.value} : item))
		});
	}

	render() {
		return(
			<div className='overflow-x-auto'>
				<table className="table">
					<thead>
						<tr className="bg-secondary bg-opacity-25 text-dark">
							<th className='text-center' colSpan={5}>Party perception analysis</th>
						</tr>
						<tr>
							<th className='w-5 text-center'>Sl. No.</th>
							<th>Party</th>
							<th>Perception</th>
							<th>Strengths</th>
							<th>Weaknesses</th>
						</tr>
					</thead>
					<tbody>
					{
						this.state.partyPerceptionAnalysis?.map((partyPerceptionAnalysis, index) => (
							<tr key={partyPerceptionAnalysis.id}>
								<td className='text-center'>{index + 1}</td>
								<td>
									<select className="form-select" name="party" id="party" defaultValue={partyPerceptionAnalysis.party.id} onChange={(e) => this.handleParty(e, partyPerceptionAnalysis.id)}>
									{this.state.opinionList?.map(opinion => 
										<option value={opinion.id} key={opinion.id}>{opinion.name}</option>
									)}
									</select>
								</td>
								<td>
									<input type="text" name="perception" id="perception" className="form-control" defaultValue={partyPerceptionAnalysis.perception} onChange={(e) => this.handlePerception(e, partyPerceptionAnalysis.id)} />
								</td>
								<td>
									<input type="text" name="strengths" id="strengths" className="form-control" defaultValue={partyPerceptionAnalysis.strengths} onChange={(e) => this.handleStrengths(e, partyPerceptionAnalysis.id)} />
								</td>
								<td>
									<input type="text" name="weaknesses" id="weaknesses" className="form-control" defaultValue={partyPerceptionAnalysis.weaknesses} onChange={(e) => this.handleWeaknesses(e, partyPerceptionAnalysis.id)} />
								</td>
							</tr>
						))
					}
					</tbody>
				</table>
			</div>
		)
	}
}

export default updatePartyPerceptionAnalysis;