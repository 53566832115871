import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Voters.css';
import Constituency from '../Constituencies';
import Blocks from '../Blocks';
import Villages from '../Villages';
import PollingStations from '../PollingStations';
import { getBlocks, getStateConstituencies, getVillages, voterFilterFields, getPollingStations, getReligion, getCasteList, getSubCaste, getOpinion } from '../../services/Voters';
import Combobox from "react-widgets/Combobox";
import 'rc-slider/assets/index.css';
import TooltipSlider from './TooltipSlider';


class SmsVotersFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      selectedConstituency: this.props.selectedConstituency,
      selectedBlock: this.props.selectedBlock,
      selectedVillage: this.props.selectedVillage,
      selectedPollingStation: this.props.selectedPollingStation,
      selectedReligion: this.props.selectedReligion,
      selectedCaste: this.props.selectedCaste,
      selectedSubCaste: this.props.selectedSubCaste,
      selectedOpinion: this.props.selectedOpinion,
      selectedGender: this.props.selectedGender,
      casteList: [],
      subCasteList: this.props.subCasteList,
      blocks: this.props.blocks,
      villages: this.props.villages,
      minAge: this.props.minAge,
      maxAge: this.props.maxAge,
      governmentScheme: this.props.governmentScheme,
      influentialVoter: this.props.influentialVoter,
      opinionList: [],
      pollingStations: this.props.pollingStations,
      voterReligions: [],
      voterCastes: [],
      voterSubCastes: [],
      religion: [],
      err: '',
      clear: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.onChangeConstituencies = this.onChangeConstituencies.bind(this);
    this.onChangeBlock = this.onChangeBlock.bind(this);
    this.onChangeVillage = this.onChangeVillage.bind(this);
    this.onChangePollingStation = this.onChangePollingStation.bind(this);
    this.handleReligion = this.handleReligion.bind(this);
    this.handleCaste = this.handleCaste.bind(this);
    this.handleSubCaste = this.handleSubCaste.bind(this);
    this.handleGender = this.handleGender.bind(this);
    this.handleOpinion = this.handleOpinion.bind(this);
    this.handleGovernmentScheme = this.handleGovernmentScheme.bind(this);
    this.handleInfluentialVoter = this.handleInfluentialVoter.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.handleAge = this.handleAge.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  handleReset() {
    let filterData = {constituency: '', block: '', village: '', pollingStation: '', religion: '', caste: '', subCaste: '', gender: '', opinion: '', minAge: '', maxAge: '', benefitedByGovernmentScheme: '', influentialVoter: ''};
    this.setState({clear: !this.state.clear});
    this.props.onApplyReligion(null);
    this.props.onApplyCaste(null, this.props.subCasteList);
    this.props.onApplySubCaste(null);
    this.props.onApplyGender(null);
    this.props.onApplyOpinion(null);
    this.props.onApplyGovernmentScheme(null);
    this.props.onApplyInfluentialVoter(null);
    this.props.onApplyMinAge(18);
    this.props.onApplyMaxAge(120);
    this.props.onApplyConstituency(this.props.blocks, this.props.selectedConstituency);
    this.props.onApplyBlock(null, []);
    this.props.onApplyVillage(null, []);
    this.props.onApplyPollingStation(null);
    this.props.onApplyFilter(filterData, [], []);
  }

  handleAge(event) {
    this.props.onApplyMinAge(event[0]);
    this.props.onApplyMaxAge(event[1]);
    this.setState({minAge: event[0], maxAge: event[1]});
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  onChangeConstituencies(selectedConstituency) {
    getBlocks(selectedConstituency).then((blocks) => {
      this.props.onApplyConstituency(blocks, selectedConstituency);
      this.setState({blocks, selectedConstituency});
    }).catch((err) => {
      this.setState(err);
    })
  }

  onChangeBlock(selectedBlock) {
    getVillages(selectedBlock).then((villages) => {
      this.props.onApplyBlock(selectedBlock, villages);
      this.setState({selectedBlock, villages});
    }).catch((err) => {
      this.setState(err);
    })
  }

  onChangeVillage(selectedVillage) {
    getPollingStations(selectedVillage).then((pollingStations) => {
      this.props.onApplyVillage(selectedVillage, pollingStations);
      this.setState({selectedVillage, pollingStations});
    }).catch((err) => {
      this.setState({err});
    })
  }

  onChangePollingStation(selectedPollingStation) {
    this.props.onApplyPollingStation(selectedPollingStation);
    this.setState({selectedPollingStation})
  }

  handleReligion(event) {
    let selectedReligion = event.id;
    this.props.onApplyReligion(selectedReligion);
    this.setState({selectedReligion: event.id});
  }

  handleCaste(event) {
    let selectedCaste = event.id;
    getSubCaste(selectedCaste).then((subCasteList) => {
      this.props.onApplyCaste(selectedCaste, subCasteList);
      this.setState({selectedCaste: event.id, subCasteList});
    }).catch((err) => {
      this.setState({err});
    })
  }

  handleSubCaste(event) {
    let selectedSubCaste = event.id;
    this.props.onApplySubCaste(selectedSubCaste);
    this.setState({selectedSubCaste: event.id})
  }

  handleGender(event) {
    let selectedGender = event.id;
    this.props.onApplyGender(selectedGender);
    this.setState({selectedGender: event.id});
  }

  handleOpinion(event) {
    let selectedOpinion = event.id;
    this.props.onApplyOpinion(selectedOpinion);
    this.setState({selectedOpinion: event.id});
  }

  handleGovernmentScheme(event) {
    let governmentScheme = event.target.checked;
    this.props.onApplyGovernmentScheme(governmentScheme);
    this.setState({governmentScheme: event.target.checked});
  }

  handleInfluentialVoter(event) {
    let influentialVoter = event.target.checked;
    this.props.onApplyInfluentialVoter(influentialVoter);
    this.setState({influentialVoter: event.target.checked});
  }

  onSearch() {
    let constituency = this.props.selectedConstituency;
    let block = this.props.selectedBlock;
    let village = this.props.selectedVillage;
    let pollingStation = this.props.selectedPollingStation;
    let religion = this.props.selectedReligion;
    let caste = this.props.selectedCaste;
    let subCaste = this.props.selectedSubCaste;
    let gender = this.props.selectedGender;
    let opinion = this.props.selectedOpinion;
    let minAge = this.props.minAge;
    let maxAge = this.props.maxAge;
    let benefitedByGovernmentScheme = this.props.governmentScheme;
    let influentialVoter = this.props.influentialVoter;
    let filterData = {constituency, block, village, pollingStation, religion, caste, subCaste, gender, opinion, minAge, maxAge, benefitedByGovernmentScheme, influentialVoter};
    this.props.onApplyFilter(filterData, this.props.villages, this.props.pollingStations);
  }

  componentDidMount() {
    getStateConstituencies().then((constituencies) => {
      getBlocks(86).then((blocks) => {
        this.setState({constituencies, blocks});
      }).catch((err) => {
        this.setState({err});
      })
    }).catch((err) => {
      this.setState({err});
    })

    getReligion().then((religion) => {
      this.setState({religion});
    }).catch((err) => {
      this.setState({err});
    })

    getCasteList().then((casteList) => {
      this.setState({casteList});
    }).catch((err) => {
      this.setState({err});
    })

    getOpinion().then((opinionList) => {
      this.setState({opinionList});
    }).catch((err) => {
      this.setState({err});
    })
  }

  render() {
    return(
      <div className='col-md-3 col-lg-3 col-sm-12'>
        <div className='card shadow my-4'>
          <div className='row p-3'>
            <div className='col-12 form-group'>
              <div className='d-inline text-start header-font align-self-center'>Filters</div>
              <div className='d-inline float-right'>
                <button className='btn btn-success mx-1' onClick={this.onSearch}>Search</button>
                <button onClick={this.handleReset} className='btn btn-danger'>Clear</button>
              </div>
            </div>
            <div className='col-md-12 col-sm-12 col-12'>
              <div className='from-group voter-filter-style'>
                <input type="text" className='form-control ' placeholder='Search by Name, Mobile, VoterID, House' />
              </div>
            </div>
            <Constituency constituencyFields={voterFilterFields} onChangeConstituencies={this.onChangeConstituencies} selectedConstituency={this.props.selectedConstituency} constituencies={this.props.constituencies} />
            <Blocks clear={this.state.clear} blockFields={voterFilterFields} blocks={this.props.blocks} selectedBlock={this.props.selectedBlock} onChangeBlock={this.onChangeBlock} selectedConstituency={this.props.selectedConstituency} />
            <Villages villageFields={voterFilterFields} villages={this.props.villages} selectedVillage={this.props.selectedVillage} selectedBlock={this.props.selectedBlock} selectedConstituency={this.props.selectedConstituency} onChangeVillage={this.onChangeVillage} />
            <PollingStations pollingStations={this.props.pollingStations} selectedPollingStation={this.props.selectedPollingStation} selectedVillage={this.props.selectedVillage} selectedBlock={this.props.selectedBlock} selectedConstituency={this.props.selectedConstituency} pollingStationFields={voterFilterFields} onChangePollingStation={this.onChangePollingStation} />
            <div className='col-12'>
              <div className='form-group text-start my-auto'>
              <label>Religion</label>
                <Combobox data={this.state.religion} placeholder={``} dataKey='id' textField='name' value={ this.props.selectedReligion } onChange={this.handleReligion} />
              </div>
            </div>
            <div className='col-12'>
              <div className='form-group text-start my-auto'>
              <label>Caste</label>
                <Combobox data={this.state.casteList} placeholder={``} dataKey='id' textField='name' value={ this.props.selectedCaste } onChange={this.handleCaste} />
              </div>
            </div>
            <div className='col-12'>
              <div className='form-group text-start my-auto'>
              <label>Sub-Caste</label>
                <Combobox data={this.state.subCasteList} placeholder={``} dataKey='id' textField='name' value={ this.props.selectedSubCaste } onChange={this.handleSubCaste} />
              </div>
            </div>
            <div className='col-12'>
              <div className='form-group text-start my-auto'>
              <label htmlFor="">Gender</label>
              <Combobox data={[{id: 'M', name: 'Male'}, {id: 'F', name: 'Female'}, {id: 'O', name: 'Other'}]} placeholder={``} dataKey='id' textField='name' value={ this.props.selectedGender } onChange={this.handleGender} />
            </div>
            </div>
            <div className='col-12'>
              <div className='form-group text-start my-auto'>
              <label>Opinion</label>
                <Combobox data={this.state.opinionList} placeholder={``} dataKey='id' textField='name' value={ this.props.selectedOpinion } onChange={this.handleOpinion} />
              </div>
            </div>
            <div className='col-md-12 col-sm-12 col-12'>
              <div className='form-group text-start voter-filter-style'>
                <label className=' text-uppercase'>By Age</label>
              </div>
            </div>
            <div className='col-12'>
            <TooltipSlider
              range
              min={18}
              max={120}
              value={[this.props.minAge, this.props.maxAge]}
              onChange={this.handleAge}
              tipFormatter={(value) => `${value}`}
            />
            </div>
            <div className='col-md-12 col-sm-12 col-12'>
              <div className='form-group text-start voter-filter-style'>
                <input type='checkbox' id='governmentscheme' name='governmentscheme' checked={this.props.governmentScheme ? `checked` : ''} onChange={this.handleGovernmentScheme} /> <span>Benefited by government scheme</span>
              </div>
            </div>
            <div className='col-md-12 col-sm-12 col-12'>
              <div className='form-group text-start voter-filter-style'>
                <input type='checkbox' id='influentialVoter' name='influentialVoter' checked={this.props.influentialVoter ? `checked` : ''} onChange={this.handleInfluentialVoter} className='' /> <span>Influential Voter</span>
              </div>
            </div>
            <div className='col-md-12 col-sm-12 col-12'>
              <div className='form-group text-center'>
                <button className='btn btn-success me-1' onClick={this.onSearch}>Search</button>
                <button className='btn btn-danger' onClick={this.handleReset}>Clear</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SmsVotersFilters;