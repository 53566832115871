import React from "react";
import ReactPaginate from 'react-paginate';

class Pagination extends React.Component {
  constructor( props ) {
    super( props );
    this.handlePageClick = this.handlePageClick.bind( this );
  }

  handlePageClick( event ) {
    let pageNumber = event.selected + 1;
    this.props.onChangePage( pageNumber );
  }

  render() {
    let pageCount = Math.ceil( this.props.pageCount / this.props.pageSize );
    return(
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={ this.handlePageClick }
        pageRangeDisplayed={ 4 }
        forcePage={ this.props.currentPage }
        pageCount={ pageCount ? pageCount : 0 }
        previousLabel="<"
        renderOnZeroPageCount={ null }
        containerClassName="pagination"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        nextClassName="page-item"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        activeClassName="active"
      />
    )
  }
}

export default Pagination;