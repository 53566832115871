import { applyAuthTokenInterceptor, setAuthTokens, clearAuthTokens } from 'axios-jwt';
import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;


// 1. Create an axios instance that you wish to apply the interceptor to
const axiosInstance = axios.create({ baseURL: BASE_URL });


// 2. Define token refresh function.
const requestRefresh = (refresh) => {
  // Notice that this is the global axios instance, not the axiosInstance!  <-- important
  return axios.post(`${BASE_URL}/token/refresh/`, { refresh })
    .then(response => response.data.access)
};

// 3. Apply interceptor
applyAuthTokenInterceptor(axiosInstance, { requestRefresh });  // Notice that this uses the axiosInstance instance.  <-- important

// 4. Logging in
const login = async (params) => {
  const response = await axiosInstance.post('/token/', params)

  // save tokens to storage
  setAuthTokens({
    accessToken: response.data.access,
    refreshToken: response.data.refresh
  })
}

// 5. Logging out
const logout = () => clearAuthTokens()


// Now just make all requests using your axiosInstance instance
// axiosInstance.get('/token/').then(response => { })

export { axiosInstance, login, logout };