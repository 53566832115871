import React from "react";

class ViewIncharges extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return(
          <div className="card">
            <div className="card-body overflow-x-auto">
              <table className="table">
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td className="w-50"></td>
                  </tr>
                  <tr>
                    <td>Voter Id</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>PollingStation</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Village</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Block</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Constituency</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Comments</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
    )
  }
}

export default ViewIncharges;