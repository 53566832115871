import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Dashboard-Style.css';

export default class DashboardCards extends React.Component {

  constructor(props){
    super(props);
  }

  render() {
    return(
        <div className='row dashboard-card-line flex-row flex-nowrap'>
          {
            this.props.cards?.map((village, index) => {
              return (
                <div className='col-md-4 col-sm-6 col-6 col-lg-3 col-xl-3' key={index}>
                  <div className={`card mt-3 rounded-3 text-white`} style={{'backgroundColor': village.color}}>
                    <div className="card-body">
                      <div className='voter-village-count text-center'>{village.count}</div>
                      <div className='voter-village-name text-center'>{village.name}</div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
    );
  }
}