import React from 'react'
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { INITIAL_EVENTS, createEventId } from './event-utils'
import Modal from 'react-bootstrap/Modal';

export default class EventCalendar extends React.Component {

  state = {
    currentEvents : [],
    eventInput :'',
    eventTextArea: '',
    eventUpdateInput :'',
    dataObject : {},
    eventDetailObject :{},
    updateInput : '',
    updatedescription:'',
    isDisable : true,
    isUpdate :false,
    addModel: false,
    editModel:false,
  }

  render() {
    return (
      <div className='demo-app'>
        {this.renderSidebar()}
        <div className='demo-app-main'>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            initialView='dayGridMonth'
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
            select={this.handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={this.handleEventClick}
            eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
            /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
          />
        </div>
      </div>
    )
  }

  renderSidebar() {
    return (
        <div className='demo-app-sidebar'>
            <div className='demo-app-sidebar-section'>

            <Modal show={this.state.addModel} onHide={this.closeAddModel} backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input type='text' name='event-title' className='form-control mb-3' placeholder='Please Add Event Name' onChange={this.handleEventInput}/>
                    <textarea className="form-control mb-3" onChange={this.handelEventDescription}></textarea>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger align-bottom mr-2"  onClick={this.closeAddModel}>Close</button>
                    <button className="btn btn-success" onClick={this.handleSendSms}>Save</button>
                </Modal.Footer>
            </Modal>

            <Modal show={this.state.editModel} onHide={this.closeEditModel} backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input type='text' name='event-title' className='form-control mb-3' placeholder='Please Add Event Name' onChange={this.handleEventUpdateInput} defaultValue={this.state.updateInput} disabled={this.state.isDisable} />
                    <textarea onChange={this.handelEventUpdateDescription} defaultValue={this.state.updatedescription} disabled={this.state.isDisable} className="form-control mb-3"></textarea>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger align-bottom mr-2"  onClick={this.closeEditModel}>Close</button>
                    {this.state.isUpdate == true ? <button type="button m-auto" className="btn btn-success" onClick={this.handleUpdate}>Update</button> :<button className="btn btn-primary align-bottom mr-2" onClick={this.handleEdit}>Edit</button>}
                </Modal.Footer>
            </Modal>

        </div>
        <div className='demo-app-sidebar-section'>
          <h2>All Events ({this.state.currentEvents.length})</h2>
          <ul>
            {this.state.currentEvents.map(renderSidebarEvent)}
          </ul>
        </div>
    </div>
    )
  }

    handleSendSms = () =>{
        var selectInfo = this.state.dataObject;
        let title = this.state.eventInput;
        let description = this.state.eventTextArea;
        let calendarApi = selectInfo.view.calendar

        calendarApi.unselect() // clear date selection

        if (title) {
        calendarApi.addEvent({
            id: createEventId(),
            title,
            start: selectInfo.startStr,
            end: selectInfo.endStr,
            allDay: selectInfo.allDay,
            extendedProps: {
                description: description,
            },
        });
        this.setState({addModel:false});
        this.setState({eventInput:''});
        this.setState({dataObject:{}});
        }
    }

    handelEventDescription = (event) =>{
        this.setState({eventTextArea:event.target.value});
    }

    handleDateSelect = (selectInfo) => {
        this.setState({dataObject:selectInfo});
        this.setState({addModel:true});

    }

    handleEventInput = (event) => {
        this.setState({eventInput:event.target.value});
    }


    handleAddModel = () =>{
        this.setState({addModel:true});
    }
    closeAddModel = () =>{
        this.setState({addModel:false});
    }
    handleEditModel = () =>{
        this.setState({editModel:true});
    }
    closeEditModel = () =>{
        this.setState({editModel:false});
    }




    handleEventUpdateInput = (event) =>{
        this.setState({eventUpdateInput:event.target.value});
    }

    handleEdit = () => {
        this.setState({isDisable:false});
        this.setState({isUpdate:true});
    }

    handleUpdate = () =>{
        var selectInfo = this.state.eventDetailObject;
        let title = this.state.eventUpdateInput;
        let description = this.state.eventTextArea;
        let calendarApi = selectInfo.view.calendar;

        calendarApi.unselect() // clear date selection

        if (title) {
            calendarApi.addEvent({
            id: createEventId(),
            title,
            start: selectInfo.startStr,
            end: selectInfo.endStr,
            allDay: selectInfo.allDay,
            extendedProps: {
                description: description,
            },
            });

            document.getElementById('close').click();
            this.setState({eventInput:''});
            this.setState({dataObject:{}});
        }

        document.getElementById('close').click();
    }

    handleEventClick = (clickInfo) => {
        this.setState({eventDetailObject:clickInfo});
        this.setState({updateInput:clickInfo.event.title});
        this.setState({updatedescription:clickInfo.event.extendedProps.description});
        this.setState({editModel:true});

    }

    handleDeleteEvent = () =>{
        this.state.eventDetailObject.event.remove();
        this.setState({editModel:true});

    }

    handleEvents = (events) => {
        this.setState({
        currentEvents: events
        })
    }

}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
}

function renderSidebarEvent(event) {
  return (
    <li key={event.id}>
      <b>{formatDate(event.start, {year: 'numeric', month: 'short', day: 'numeric'})}</b>
      <i>{event.title}</i>
    </li>
  )
}