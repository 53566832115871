import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Voters.css';
import { Link } from 'react-router-dom';
import { getVoterData } from '../../services/Voters';
import { useParams } from "react-router-dom";
import { RotatingLines } from  'react-loader-spinner';
import RotatingLinesLoader from '../RotatingLineLoader';
import Error from '../error';
import { getGenderName, getRelationTypeName } from '../../services/Voters';

function VoterDetailsView() {
  const [voterData, setVoterData] = useState(null);
  const [error, setErr] = useState(null);
  const { voterid } = useParams();
  const { path } = useParams();
  const [loaderStatus, setLoaderStatus] = useState(false);

  useEffect(() => {
    setLoaderStatus(true);
    setTimeout(() => {
      getVoterData(voterid).then((voterData) => {
        setVoterData(voterData);
        setLoaderStatus(false);
      }).catch((error) => {
        setErr(error);
        setLoaderStatus(false);
      })
    }, 3000);
  }, []);

    return(
          <div className='card'>
            <div className='card-body'>
            { voterData != null ? 
              <table className='table'>
                <thead>
                  <tr className='text-center'>
                    <th colSpan={2}>Voter Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1 w-50'>Voter Id</td>
                    <td>
                      {voterData?.id_card_number ? voterData?.id_card_number : ''}
                    </td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>First Name</td>
                    <td>{voterData?.first_name ? voterData?.first_name : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Last Name</td>
                    <td>{voterData?.last_name ? voterData?.last_name : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Relation Type</td>
                    <td>{voterData?.relation_type ? getRelationTypeName(voterData?.relation_type) : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Relation First Name</td>
                    <td>{voterData?.relation_firstname ? voterData?.relation_firstname : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Relation Last Name</td>
                    <td>{voterData?.relation_lastname ? voterData?.relation_lastname : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>House No.</td>
                    <td>{voterData?.house ? voterData?.house?.house_no : ''}{voterData?.house ? `, ${voterData?.house?.society}` : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Serial Number</td>
                    <td>{voterData?.serial_number ? voterData?.serial_number : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Gender</td>
                    <td>{voterData?.gender ? getGenderName(voterData?.gender) : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Part Number</td>
                   <td>{voterData?.polling_station.part_number} ({voterData.polling_station?.name})</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Polling Station Building</td>
                    <td>{voterData?.polling_station.building ? voterData.polling_station?.building : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Polling Station Location</td>
                    <td>{voterData?.polling_station.location ? voterData.polling_station?.location : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Village</td>
                    <td>{voterData?.polling_station.village ? voterData.polling_station?.village.name : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Constituency</td>
                    <td>{voterData?.polling_station.constituency ? voterData.polling_station.constituency?.name : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Visited Count</td>
                    <td>{voterData?.visited_count ? voterData?.visited_count : '0'}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Date of birth</td>
                    <td>{voterData?.dob ? voterData?.dob : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Age</td>
                    <td>{voterData?.age ? voterData?.age : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Mobile Number</td>
                    <td>{voterData?.mobile_number ? voterData?.mobile_number : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Alternate Mobile Number</td>
                    <td>{voterData?.alternate_mobile_number ? voterData?.alternate_mobile_number : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Email Id</td>
                    <td>{voterData?.email_address ? voterData?.email_address : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Profession</td>
                    <td>{voterData?.profession ? voterData?.profession : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Educational Qualifications</td>
                    <td>{voterData?.educational_qualification ? voterData?.educational_qualification : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Dead</td>
                    <td>{voterData?.dead == true ? 'True' : 'False'}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>New Address</td>
                    <td>{voterData?.new_house?.house_no}{voterData?.new_house?.society ? `, ${voterData?.new_house?.society}` : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Caste</td>
                    <td>{voterData?.caste ? voterData?.caste.name : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Sub Caste</td>
                    <td>{voterData?.subcaste ? voterData.subcaste?.name : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Religion</td>
                    <td>{voterData?.religion ? voterData.religion?.name : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Opinion</td>
                   <td>{voterData?.opinion ? voterData.opinion?.name : ''}{voterData.opinion?.complete_name ? ` - (${voterData.opinion?.complete_name})` : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Complaints</td>
                    <td>{voterData?.complaints ? voterData?.complaints : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Demands</td>
                    <td>{voterData?.demands ? voterData?.demands : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Extra Info</td>
                    <td>{voterData?.extra_info ? voterData?.extra_info : ''}</td>
                  </tr>
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Influential Voter</td>
                    <td>{voterData?.influential_voter == true ? 'True' : 'False'}</td>
                  </tr>
                  {
                    voterData?.influential_voter == true ?
                    <tr>
                      <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Influential Positon</td>
                      <td>{voterData?.influential_position.map((position, index) => <span key={position.id}>{index > 0 ? ', ' : ''}{position.name}</span>)}</td>
                    </tr> : ''
                  }
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Government Beneficiary</td>
                    <td>{voterData?.government_beneficiary === true ? 'True' : 'False'}</td>
                  </tr>
                  {
                    voterData?.government_beneficiary === true ?
                    <tr>
                      <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Government Scheme</td>
                      <td>{voterData?.benefited_by.map((beneficiary, index) => <span key={beneficiary.id}>{index > 0 ? ', ' : ''}{beneficiary.name}</span>)}</td>
                    </tr> : ''
                  }
                  <tr>
                    <td className='table-form-label-display align-middle fw-bold dtr-control sorting_1'>Survey Completed</td>
                    <td>{voterData?.survey_completed == true ? 'True' : 'False'}</td>
                  </tr>
                  <tr>
                    <td className='text-right' colSpan={2}>
                      {voterData?.id_card_number && <Link to={`/update-voter-details/${path}/${voterData?.id_card_number}`} className='btn btn-warning'>
                        Update Voter Details
                      </Link>}
                    </td>
                  </tr>
                </tbody>
              </table> : loaderStatus == true ? 
              <RotatingLinesLoader /> : 
              error != null ? 
              <Error /> :
              <div className='text-center fw-600 fs-6 text-black-50'>
                  No data Found
              </div>
            }
            </div>
          </div>
     );
}

export default VoterDetailsView;