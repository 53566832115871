import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {React , useState , useEffect} from 'react';
import { Link } from "react-router-dom";
import '../assets/css/Sidebar-Style.css';
import  OnlyOne  from './OnlyOne'; 
import { faHandPointUp, faHouseChimneyUser, faVoteYea, faHouseUser, faCity, faClipboardList, faEnvelope, faCommentDots} from '@fortawesome/free-solid-svg-icons';


import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';


import { matchRoutes, useLocation } from "react-router-dom"


function Sidebar(props) {

  useEffect( () => {

      // var dropdown = document.getElementsByClassName("dropdown-btn");
      // var i;
      // for (i = 0; i < dropdown.length; i++) {
      //   dropdown[i].addEventListener("click", function() {
      //     // document.querySelectorAll('.dropdown-container').forEach(item => item.classList.remove('nav-active'));
      //     document.querySelectorAll('.dropdown-btn').forEach(item => item.classList.remove('active-link'));
      //     this.classList.toggle("active-link");
          
      //     var dropdownContent = this.nextElementSibling;
      //     dropdownContent.classList.toggle("nav-active");

      //     // document.querySelectorAll('.dropdown-container').forEach(item => item.classList.remove('nav-active'));

      //   });
      // }

  },[]);

    return(

       <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <a href="#" className="brand-link">
          <span className="brand-text font-weight-light">Voter</span>
        </a>
        <div className="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-transition os-host-overflow-x">
          <div className="os-resize-observer-host observed">
            <div className="os-resize-observer resize-observer-style">
            </div>
          </div>
          <div className="os-size-auto-observer observed size-auto-observer-style">
            <div className="os-resize-observer">
            </div>
          </div>
          <div className="os-content-glue content-glue-style">
          </div>
          <div className="os-padding">
            <div className="os-viewport os-viewport-native-scrollbars-invisible os-viewport-native-scrollbars-invisible-polls">
              <div className="os-content os-content-style">

              <div className="sidenav">
                  <OnlyOne menu={props.tabs} />
              </div>
              </div>
            </div>
          </div>
        <div className="os-scrollbar os-scrollbar-horizontal os-scrollbar-auto-hidden"><div className="os-scrollbar-track"><div className="os-scrollbar-handle os-scrollbar-handle-polls"></div></div></div><div className="os-scrollbar os-scrollbar-vertical os-scrollbar-auto-hidden"><div className="os-scrollbar-track"><div className="os-scrollbar-handle os-scrollbar-handle-polls-corner"></div></div></div><div className="os-scrollbar-corner"></div></div>
      </aside>
  );

}

export default Sidebar;
