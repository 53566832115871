import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Dashboard-Style.css';
import Constituencies from '../Constituencies';
import Blocks from '../Blocks';
import Villages from '../Villages';
import PollingStations from '../PollingStations';
import { filterFields, getStateConstituencies, getBlocks, getVillages, getPollingStations } from '../../services/Voters';


class DashboardFilter extends React.Component {
  constructor(props) {
	super(props);
	this.state = {
	  error: '',
	  clear: true,
	};
	this.onChangeConstituencies = this.onChangeConstituencies.bind(this);
	this.onChangeBlock = this.onChangeBlock.bind(this);
	this.onChangeVillage = this.onChangeVillage.bind(this);
	this.onChangePollingStation = this.onChangePollingStation.bind(this);
	this.handleReset = this.handleReset.bind(this);
  }

  handleReset() {
	let selectedConstituency = 86;
	getBlocks(selectedConstituency).then((blocks) => {
		this.props.onApplyFilter(selectedConstituency, null, null, null, this.props.constituencies, blocks, [], [], this.props.selectedConstituencyObj);
		this.setState({clear: !this.state.clear});
	  }).catch((error) => {
		this.setState({error});
	  })
  }
  
  onChangeConstituencies(selectedConstituency, selectedConstituencyObj) {
	getBlocks(selectedConstituency).then((blocks) => {
	  this.props.onApplyFilter(selectedConstituency, null, null, null, this.props.constituencies, (blocks != undefined ? blocks : []), [], [], selectedConstituencyObj);
	}).catch((error) => {
	  this.setState({error});
	})
  }

  onChangeBlock(selectedBlock) {
	getVillages(selectedBlock).then((villages) => {
	  let selectedConstituency = this.props.selectedConstituency;
	  this.props.onApplyFilter(selectedConstituency, selectedBlock, null, null, this.props.constituencies, this.props.blocks, villages, [], this.props.selectedConstituencyObj);
	}).catch((error) => {
	  this.setState({error});
	})
  }

  onChangeVillage(selectedVillage) {
	getPollingStations(selectedVillage).then((pollingStationList) => {
	  let selectedConstituency = this.props.selectedConstituency;
	  let selectedBlock = this.props.selectedBlock;
	  this.props.onApplyFilter(selectedConstituency, selectedBlock, selectedVillage, null, this.props.constituencies, this.props.blocks, this.props.villages, pollingStationList, this.props.selectedConstituencyObj);
  }).catch((error) => {
	  this.setState({error});
  })
  }

  onChangePollingStation(selectedPollingStation) {
	let selectedConstituency = this.props.selectedConstituency;
	  let selectedBlock = this.props.selectedBlock;
	  let selectedVillage = this.props.selectedVillage;
	  let pollingStations = this.props.pollingStationList;
	  this.props.onApplyFilter(selectedConstituency, selectedBlock, selectedVillage, selectedPollingStation, this.props.constituencies, this.props.blocks, this.props.villages, pollingStations, this.props.selectedConstituencyObj);
  }

  render() {
	return(
		<>
			<div className='row'>
				<Constituencies disabled={this.props.acDisabled} clear={this.state.clear} selectedConstituencyObj={this.props.selectedConstituencyObj} constituencyFields={filterFields} onChangeConstituencies={this.onChangeConstituencies} selectedConstituency={this.props.selectedConstituency} constituencies={this.props.constituencies} />
				<Blocks disabled={this.props.blockDisabled} clear={this.state.clear} blockFields={filterFields} blocks={this.props.blocks} selectedBlock={this.props.selectedBlock} onChangeBlock={this.onChangeBlock} selectedConstituency={this.props.selectedConstituency} />
				<Villages villageFields={filterFields} villages={this.props.villages} onChangeVillage={this.onChangeVillage} selectedVillage={this.props.selectedVillage} selectedBlock={this.props.selectedBlock} selectedConstituency={this.props.selectedConstituency} />
				<PollingStations pollingStations={this.props.pollingStationList} pollingStationFields={filterFields} onChangePollingStation={this.onChangePollingStation} selectedPollingStation={this.props.selectedPollingStation} selectedVillage={this.props.selectedVillage} selectedBlock={this.props.selectedBlock} selectedConstituency={this.props.selectedConstituency} />
			</div>
			<div className='row'>
				<div className='col-md-3 col-lg-3 col-12 my-2'>
					<button className='btn btn-danger ms-1 text-white' onClick={this.handleReset}>Clear</button>
				</div>
			</div>
		</>
	);
  }
}

export default DashboardFilter;