import React from "react";
import { getOpinion, getCasteList, getNameFromList } from '../../services/Voters';

class UpdatePoachableLeaders extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			poachableLeaders: [],
			opinionList: [],
			err: '',
			casteList: []
		}
		this.handleLeaderName = this.handleLeaderName.bind(this);
		this.handleParty = this.handleParty.bind(this);
		this.handleCaste = this.handleCaste.bind(this);
		this.handleReason = this.handleReason.bind(this);
	}

	componentDidMount() {
		getOpinion().then((opinionList) => {
			this.setState({opinionList});
		}).catch((err) => {
			this.setState({err});
		})

		getCasteList().then((casteList) => {
			this.setState({casteList});
		}).catch((err) => {
			this.setState({err});
		})
		this.setState({poachableLeaders: this.props.poachableLeaders});
	}

	handleLeaderName = (event, id) => {
		this.setState({
			poachableLeaders: this.state.poachableLeaders?.map(item => (item.id == id ? {...item, leaderName: event.target.value} : item))
		});
	}

	handleParty = (event, id) => {
		const party = {id: event.target.value, name: getNameFromList(this.state.opinionList, event.target.value)};
		this.setState({
			poachableLeaders: this.state.poachableLeaders?.map(item => (item.id == id ? {...item, party} : item))
		});
	}

	handleCaste = (event, id) => {
		const caste = {id: event.target.value, name: getNameFromList(this.state.casteList, event.target.value)};
		this.setState({
			poachableLeaders: this.state.poachableLeaders?.map(item => (item.id == id ? {...item, caste} : item))
		});
	}

	handleReason = (event, id) => {
		this.setState({
			poachableLeaders: this.state.poachableLeaders?.map(item => (item.id == id ? {...item, reason: event.target.value} : item))
		});
	}

	render() {
		return(
			<div className='overflow-x-auto'>
				<table className='table'>
					<thead>
						<tr className='bg-secondary bg-opacity-75'>
							<th className='text-center' colSpan={5}>Leaders who can be poached</th>
						</tr>
						<tr className='bg-secondary bg-opacity-25 text-dark'>
							<th className='text-center' colSpan={5}>Inactive / Dissatisfied Influential leaders</th>
						</tr>
						<tr>
							<th className='w-5 text-center'>Sl. No.</th>
							<th>Leader name</th>
							<th>Party</th>
							<th>Caste</th>
							<th>Reason for Inactiveness / dissatisfaction</th>
						</tr>
					</thead>
					<tbody>
						{
							this.state.poachableLeaders?.map((poachableLeader, index) => (
								<tr key={poachableLeader.id}>
									<td className='text-center'>{index + 1}</td>
									<td>
										<input type="text" name="leaderName" id="leaderName" className="form-control" defaultValue={poachableLeader.leaderName} onChange={(e) => this.handleLeaderName(e, poachableLeader.id)} />
									</td>
									<td>
										<select name="party" id="party" className="form-select" defaultValue={poachableLeader.party.id} onChange={(e) => this.handleParty(e, poachableLeader.id)} >
											{this.state.opinionList?.map(opinion => 
												<option value={opinion.id} key={opinion.id} >{opinion.name}</option>
											)}
										</select>
									</td>
									<td>
										<select name="caste" id="caste" className="form-select" defaultValue={poachableLeader.caste.id} onChange={(e) => this.handleCaste(e, poachableLeader.id)} >
											{this.state.casteList?.map(caste => 
												<option value={caste.id} key={caste.id} >{caste.name}</option>
											)}
										</select>
									</td>
									<td>
										<input type="text" name="reason" id="reason" className="form-control" defaultValue={poachableLeader.reason} onChange={(e) => this.handleReason(e, poachableLeader.id)} />
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		)
	}
}

export default UpdatePoachableLeaders;