import axios from "axios";
import { axiosInstance } from './Auth';
import { STATE } from '../config/defaults';
import { faCloudDownloadAlt, faWheatAwnCircleExclamation } from "@fortawesome/free-solid-svg-icons";

const baseURL = 'https://apitest.cultnerds.io/';


async function getGenderStats(constituency, block, village, pollingStation) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/stats/gender?${constituency != null ? `constituency=${constituency}` : ''}${block != null ? `&block=${block}` : ''}${village != null ? `&village=${village}` : ''}${pollingStation != null ? `&polling_station=${pollingStation}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}


async function getSurveyStats(constituencie, block, village, pollingStation) {
  try {
    const resp = await axios.get(`${baseURL}surveydata.json`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}


async function getOpinionStats(constituency, block, village, pollingStation) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/stats/opinion?${constituency != null ? `constituency=${constituency}` : ''}${block != null ? `&block=${block}` : ''}${village != null ? `&village=${village}` : ''}${pollingStation != null ? `&polling_station=${pollingStation}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

async function getAgeStats(constituency, block, village, pollingStation) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/stats/age?${constituency != null ? `constituency=${constituency}` : ''}${block != null ? `&block=${block}` : ''}${village != null ? `&village=${village}` : ''}${pollingStation != null ? `&polling_station=${pollingStation}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}


async function getReligionStats(constituency, block, village, pollingStation) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/stats/religion?${constituency != null ? `constituency=${constituency}` : ''}${block != null ? `&block=${block}` : ''}${village != null ? `&village=${village}` : ''}${pollingStation != null ? `&polling_station=${pollingStation}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

async function getCasteStats(constituency, block, village, pollingStation) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/stats/caste?${constituency != null ? `constituency=${constituency}` : ''}${block != null ? `&block=${block}` : ''}${village != null ? `&village=${village}` : ''}${pollingStation != null ? `&polling_station=${pollingStation}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}


async function getStateConstituencies(districtId) {
    try {
        const resp = await axiosInstance.get(`/state/${STATE.slug}/acs/`);
        return resp?.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

async function getConstituencies(districtId) {
  try {
      const resp = await axiosInstance.get(`/district/${districtId}/acs/`);
      return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getBlocks(selectedConstituency) {
    try {
      const resp = await axiosInstance.get(`/state/${STATE.slug}/blocks/?` + (selectedConstituency != null ? `constituency=${selectedConstituency}` : ''));
      return resp.data;
    } catch (err) {
        throw err;
    }
}

async function getVillages(selectedBlock) {

  try {
    const resp = await axiosInstance.get(`/state/telangana/villages/?` + (selectedBlock != null ? `block=${selectedBlock}` : ''));
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }

}

async function getPollingStationIncharges(constituency, block, village, pageNumber) {
  try {
    const resp = await axios.get( `${baseURL}PollingStationInchargesData${pageNumber}.json` );
    return resp.data;
  } catch ( err ) {
      console.error( err );
      throw err;
  }
}

async function getHouseVisitedCount(constituencie, block, village, pageNumber, pageSize) {
  try {
    const resp = await axios.get(`${baseURL}housevisitedcount${pageNumber}.json`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}


async function getCommentsOfVolunteer(constituencie, block, village, pageNumber) {
  try {
    const resp = await axios.get(`${baseURL}commentsofvolunteer${pageNumber}.json`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}


async function getImportantVoters(constituency, block, village, pollingStation, pageNumber, pageSize) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/voters?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${constituency ? `&constituency=${constituency}` : ''}${block ? `&block=${block}` : ''}${village ? `&village=${village}` : ''}${pollingStation ? `&polling_station=${pollingStation}` : ''}&influential_voter=true`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getVoterData(voterId) {
  try {
    const resp = await axiosInstance.get(`/voter/${voterId}/`);
    return resp?.data;
  } catch (err) {
      throw err;
  }
}


function getUpdateVoterData() {
  let allVoterData = 
    {
      "name": "Sai Ram",
      "fathername": "Ramulu",
      "gender": "Male",
      "address": "Masjid banda circle, Kondapur",
      "voterid": "YLK2096758",
      "pollingStationNo": "125",
      "pollingStationlocation": "Zphs, Kondapur",
      "serialno": "10",
      "age": "28",
      "houseno": "2-52"
    }

  return allVoterData;
}

async function getPollingStations(village) {
  try {
    const resp = await axiosInstance.get(`/state/telangana/polling-stations/?` + (village != null ? `village=${village}` : ''));
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

const filterFields = {
  'constituency':{
    label: 'Constituency',
    placeholder: 'All Constituencies',
    labelStatus: true,
    defaultValue: 86,
    colmd: 3,
    collg: 3,
    col: 12
  },
  'block':{
    label: 'Block',
    placeholder: 'All Blocks',
    labelStatus: true,
    defaultValue: '',
    colmd: 3,
    collg: 3,
    col: 12

  },
  'village':{
    label: 'Village',
    placeholder: 'All Village',
    labelStatus: true,
    defaultValue: '',
    colmd: 3,
    collg: 3,
    col: 12
  },
  'pollingStation':{
    label: 'Polling Station',
    placeholder: 'All Polling Station',
    labelStatus: true,
    defaultValue: '',
    colmd: 3,
    collg: 3,
    col: 12
  },
  'houseNo':{
    label: 'House No',
    placeholder: 'House No',
    labelStatus: true,
    defaultValue: '',
    col: 2
  },
  'incharge':{
    label: 'Incharge',
    placeholder: 'Incharge',
    labelStatus: true,
    defaultValue: '',
    col: 2
  },
  'designation':{
    label: 'Designation',
    placeholder: 'Designation',
    labelStatus: true,
    defaultValue: '',
    colmd: 3,
    collg: 3,
    col: 12
  }
}

const voterFilterFields = {
  'constituency':{
    label: 'Constituency',
    placeholder: 'All Constituencies',
    labelStatus: true,
    defaultValue: 1,
    col: 12
  },
  'block':{
    label: 'Block',
    placeholder: 'All Blocks',
    labelStatus: true,
    defaultValue: '',
    col: 12
  },
  'village':{
    label: 'Village',
    placeholder: 'All Villages',
    labelStatus: true,
    defaultValue: '',
    col: 12
  },
  'pollingStation':{
    label: 'Polling Station',
    placeholder: 'All Polling Stations',
    labelStatus: true,
    defaultValue: '',
    col: 12
  },
  'houseNo':{
    label: 'House No',
    placeholder: 'House No',
    labelStatus: true,
    defaultValue: '',
    col: 12
  },
  'incharge':{
    label: 'Incharge',
    placeholder: 'Incharge',
    labelStatus: true,
    defaultValue: '',
    col: 12
  },
  'designation':{
    label: 'Designation',
    placeholder: 'Designation',
    labelStatus: true,
    defaultValue: '',
    col: 12
  }
}


const psInchargeFields = {
  'constituency':{
    label: 'Constituency',
    placeholder: 'All Constituencies',
    labelStatus: false,
    defaultValue: '',
    col: 12
  },
  'block':{
    label: 'Block',
    placeholder: 'All Block',
    labelStatus: false,
    defaultValue: '',
    col: 12
  },
  'village':{
    label: 'Village',
    placeholder: 'All Villages',
    labelStatus: false,
    defaultValue: '',
    col: 12
  },
  'pollingStation':{
    label: 'Polling Station',
    placeholder: 'All Polling Station',
    labelStatus: false,
    defaultValue: '',
    col: 12
  }
}


async function getHouseIncharges(constituency, block, village, pollingStation, pageNumber) {
  try {
    const resp = await axios.get(`${baseURL}houseincharges${pageNumber ? pageNumber : ''}.json`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getDesignation() {
  try {
    const resp = await axios.get(`${baseURL}designation.json`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

const NewVolunteerFieldsProperties = {
  "constituencies": {
    label: "Assembly Constituency",
    placeholder: "Select",
    labelStatus: false,
    col: '',
    defaultValue: ''
  },
  "blocks": {
    lebal: "Block",
    placeholder: "Select",
    labelStatus: false,
    col: '',
    defaultValue: ''
  },
  "villages": {
    label: "Village",
    placeholder: "Select",
    labelStatus: false,
    col: '',
    defaultValue: ''
  },
  "pollingStations": {
    label: "PollingStation",
    placeholder: "Select",
    labelStatus: false,
    col: '',
    defaultValue: ''
  },
  "designation": {
    label: "Designation",
    placeholder: "Select",
    labelStatus: false,
    col: '',
    defaultValue: ''
  },
  "reportingto": {
    label: "Reporting To",
    placeholder: "Select",
    labelStatus: false,
    col: '',
    defaultValue: ''
  },
  "district": {
    label: "District",
    placeholder: "Select",
    labelStatus: false,
    col: '',
    defaultValue: ''
  },
  "town": {
    label: "Town",
    placeholder: "Select",
    labelStatus: false,
    col: '',
    defaultValue: ''
  }
}

async function getDistricts() {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/districts`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getTowns(district) {
  try {
    const resp = await axios.get(`${baseURL}towns.json`);
    return resp.data[district];
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getReportingTo() {
  try {
    const resp = await axios.get(`${baseURL}reportingto.json`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getIncharges() {
  try {
    const resp = await axios.get(`${baseURL}incharges.json`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getVolunteer() {
  try {
    const resp = await axios.get(`${baseURL}elections.json`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getAllVillages() {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/villages/`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

async function searchVillage(data) {
  try {
    const resp = await axiosInstance.get(`/search/village?q=${data}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getDemographicsData(village) {
  try {
    const resp = await axios.get(`${baseURL}demographics.json`);
    return resp.data[village];
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getVillageCategory() {
  try {
    const resp = await axios.get(`${baseURL}villagecategory.json`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}


async function getVoterDetails(pageNumber, pageSize, data) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/voters?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${data?.constituency ? `&constituency=${data?.constituency}` : ''}${data?.block ? `&block=${data.block}` : ''}${data?.village ? `&village=${data.village}` : ''}${data?.pollingStation ? `&polling_station=${data.pollingStation}` : ''}${data?.religion ? `&religion=${data.religion}` : ''}${data?.caste ? `&caste=${data.caste}` : ''}${data?.subCaste ? `&subcaste=${data.subCaste}` : ''}${data?.gender ? `&gender=${data.gender}` : ''}${data?.opinion ? `&opinion=${data.opinion}` : ''}${data?.minAge ? `&age_min=${data.minAge}` : ''}${data?.maxAge ? `&age_max=${data.maxAge}` : ''}${data?.influentialVoter ? `&influential_voter=${data.influentialVoter}` : ''}${data?.benefitedByGovernmentScheme ? `&government_beneficiary=${data.benefitedByGovernmentScheme}` : ''}${data?.surveyCompleted ? `&survey_completed=${data?.surveyCompleted}` : ''}`);
    return resp.data;
  } catch (voterDetailserr) {
      throw voterDetailserr;
  }
}

async function getVoterDetailsMobileWise(pageNumber, pageSize, data) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/voters-group?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${data?.constituency ? `&constituency=${data?.constituency}` : ''}${data?.block ? `&block=${data.block}` : ''}${data?.village ? `&village=${data.village}` : ''}${data?.pollingStation ? `&polling_station=${data.pollingStation}` : ''}${data?.religion ? `&religion=${data.religion}` : ''}${data?.caste ? `&caste=${data.caste}` : ''}${data?.subCaste ? `&subcaste=${data.subcaste}` : ''}${data?.gender ? `&gender=${data.gender}` : ''}${data?.opinion ? `&opinion=${data.opinion}` : ''}${data?.minAge ? `&age_min=${data.minAge}` : ''}${data?.maxAge ? `&age_max=${data.maxAge}` : ''}${data?.influentialVoter ? `&influential_voter=${data.influentialVoter}` : ''}${data?.benefitedByGovernmentScheme ? `&government_beneficiary=${data.benefitedByGovernmentScheme}` : ''}`);
    return resp.data;
  } catch (voterDetailserr) {
      throw voterDetailserr;
  }
}

async function getUniqueMobileNumbers(pageNumber, pageSize, data) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/voters-group?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${data?.constituency ? `&constituency=${data?.constituency}` : ''}${data?.block ? `&block=${data.block}` : ''}${data?.village ? `&village=${data.village}` : ''}${data?.pollingStation ? `&polling_station=${data.pollingStation}` : ''}${data?.religion ? `&religion=${data.religion}` : ''}${data?.caste ? `&caste=${data.caste}` : ''}${data?.subCaste ? `&subcaste=${data.subcaste}` : ''}${data?.gender ? `&gender=${data.gender}` : ''}${data?.opinion ? `&opinion=${data.opinion}` : ''}${data?.minAge ? `&age_min=${data.minAge}` : ''}${data?.maxAge ? `&age_max=${data.maxAge}` : ''}${data?.influentialVoter ? `&influential_voter=${data.influentialVoter}` : ''}${data?.benefitedByGovernmentScheme ? `&government_beneficiary=${data.benefitedByGovernmentScheme}` : ''}`);
    return resp.data;
  } catch (voterDetailserr) {
      throw voterDetailserr;
  }
}

async function getVoterSummary(fieldName, sortOrder, pageNumber) {
  try {
    const resp = await axios.get(`${baseURL}VoterSummary${fieldName}${sortOrder}${pageNumber}.json`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getCasteList() {
  try {
    const resp = await axiosInstance.get(`/caste/`);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

async function getSubCaste(casteId) {
  try {
    const resp = await axiosInstance.get(`/subcaste/${casteId ? `?caste=${casteId}` : ''}`);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

function getGenderName(id) {
  let data = {
    'M': 'Male',
    'F': 'Female',
    'O': 'Other'
  }

  return data[id]
}

async function getReligion() {
  try {
    const resp = await axiosInstance.get(`/religion/`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getOpinion() {
  try {
    const resp = await axiosInstance.get(`/political-party/`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function updateVoterData(data) {
  try {
    const resp = await axiosInstance.put(`/voter/${data.id_card_number}/`, data);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

function getRelationTypeName(id) {
  let data = {
    'F': 'Father',
    'M': 'Mother',
    'H': 'Husband',
    'W': 'Wife',
    'O': 'Other',
    '-': 'NA'
  }
  
  return data[id];
}

function getNameFromList(list, id) {
  for(let i=0;i<list.length;i++) {
      if(list[i].id == id) {
        let Name = list[i].name;
        return Name;
      }
  }
}

async function getVillageProfileList() {
  try {
    const resp = await axios.get(`${baseURL}VillageProfileList.json`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getVillageProfile(id = '') {
  try {
    const resp = await axios.get(`${baseURL}VillageProfile.json`);
    return resp.data[id];
  } catch(err) {
    throw err;
  }
}

// Voter Survey
async function addVoterSurvey(data) {
  try {
    const resp = await axiosInstance.post(`/survey/`, data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function getVoterSurveyList() {
  try {
    const resp = await axiosInstance.get(`/survey/`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getVoterSurveyQuestionsList(id) {
  try {
    const resp = await axiosInstance.get(`/survey/${id}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function postVoterSurveyResponse(surveyId, data) {
  try {
    const resp = await axiosInstance.post(`/survey/${surveyId}/response/`, data);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function putVoterSurveyActive(surveyId, data) {
  try {
    const resp = await axiosInstance.put(`/survey/${surveyId}/`, data);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getSearchVoter(voterNumber) {
  try {
    const resp = await axiosInstance.get(`/search/voter/?q=${voterNumber}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getInfluentialPosition() {
  try {
    const resp = await axiosInstance.get(`/influential-position/`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}


async function getGovernmentScheme() {
  try {
    const resp = await axiosInstance.get(`/government-scheme/`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function postVolunteerComment(data) {
  try {
    const resp = await axiosInstance.post(`/comment/`, data);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getVolunteerComments() {
  try {
    const resp = await axiosInstance.get(`/comment/`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}


async function getSearchUser(data) {
  try {
    const resp = await axiosInstance.get(`/search/user/?q=${data}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getUserProfile() {
  try{
    const resp = await axiosInstance.get(`/profile/`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getPollingStationHouses(pollingStation) {
  try{
    const resp = await axiosInstance.get(``);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

const formFilterFields = {
  'constituency':{
    placeholder: 'All Constituencies',
    labelStatus: false
  },
  'block':{
    placeholder: 'All Blocks',
    labelStatus: false
  },
  'village':{
    placeholder: 'All Villages',
    labelStatus: false
  },
  'pollingStation':{
    placeholder: 'All Polling Stations',
    labelStatus: false
  },
  'houseNo':{
    placeholder: 'House No',
    labelStatus: false
  }
}

async function getVillageSmsTemplate() {
  try{
    const resp = await axiosInstance.get(`/sms-templates/`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function addSmsTemplate(data) {
  try {
    const resp = await axiosInstance.post(`/sms-templates/`, data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function getSmsTemplate(data) {
  try {
    const resp = await axiosInstance.get(`/sms-templates/`);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function changePassword(data) {
  try {
    const resp = await axiosInstance.put(`/change-password/`, data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function getSettings(group) {
  try{
    const resp = await axiosInstance.get(`/app-settings/${group}/`);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function updateSettings(group, data) {
  try{
    const resp = await axiosInstance.put(`/app-settings/${group}/`, data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}


async function getSmsSpecificTemplate(data) {
  try {
    const resp = await axiosInstance.get(`/sms-template/${data}`);
    return resp?.data;
  } catch(err) { 
    throw err;
  }
}

async function updateSmsTemplate(id,data) {
  try {
    const resp = await axiosInstance.put(`/sms-template/${id}/`,data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function deleteSmsTemplate(id) {
  try {
    const resp = await axiosInstance.delete(`/sms-template/${id}/`);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}


async function getSubstitutionOptions() {
  try{
    const resp = await axiosInstance.get(`/substitution-options/`);
    return resp.data;
    } catch(err) {
      throw err;
    }
  }


async function createSendSMS(data) {
  try {
    const resp = await axiosInstance.post(`/send-sms/`, data);
    return resp.data;
  } catch(err) {
    throw err;
  }
}


async function emailSubmitPasswordReset(data){
  try{
    const resp = await axiosInstance.post(`/password-reset/`,data);
    return resp;
  } catch(err) {
    throw err;
  }
}

async function passwordReset(data){
  try{
    const resp = await axiosInstance.post(`/password-reset/confirm/`,data);
    return resp;
  } catch(err) {
    throw err;
  }
}

async function tokenValidationForReset(data){
  try{
    const resp = await axiosInstance.post(`/password-reset/validate_token/`,data);
    return resp;
  } catch(err) {
    throw err;
  }
}

async function getAssignedHouses(pageNumber, pageSize, search) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/houses/assigned/${pageNumber ? `?page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${search != null || search != '' ? `&q=${search}` : ''}`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getHouseDetails(houseId) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/houses/${houseId}`);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

async function getLevelOfInfluence() {
  const levelOfInfluence = [
    {id: 1, name: 'State Level'},
    {id: 2, name: 'Constituency Level'},
    {id: 3, name: 'Block Level'},
    {id: 4, name: 'Village Level'}
  ]

  return levelOfInfluence;
}

async function getUserRoles() {
  try {
    const resp = await axiosInstance.get(`/user-creation-roles/`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function postCreateUser(data) {
  try {
    const resp = await axiosInstance.post(`/app-user/`, data);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getUserDetails(id) {
  try {
    const resp = await axiosInstance.get(`/app-user-details/${id}/`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}


async function getPollingStationsForRoles(constituency, block, village, includeAssigned, pageNumber, pageSize) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/polling-stations/?include_assigned=${includeAssigned}${constituency ? `&constituency=${constituency}` : ''}${block ? `&block=${block}` : ''}${village ? `&village=${village}` : ''}${pageNumber ? `&page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getBlocksForRoles(constituency, block, village, includeAssigned, pageNumber, pageSize) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/blocks/?include_assigned=${includeAssigned}${constituency ? `&constituency=${constituency}` : ''}${block ? `&block=${block}` : ''}${village ? `&village=${village}` : ''}${pageNumber ? `&page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getAssemblyConstituenciesForRoles(includeAssigned, pageNumber, pageSize) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/acs/?include_assigned=${includeAssigned}${pageNumber ? `&page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getHousesForRoles(constituency, block, village, pollingStation, includeAssigned, pageNumber, pageSize) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/houses-lite/?include_assigned=${includeAssigned}${constituency ? `&constituency=${constituency}` : ''}${block ? `&block=${block}` : ''}${village ? `&village=${village}` : ''}${pollingStation ? `&polling_station=${pollingStation}` : ''}${pageNumber ? `&page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getVoterCount(constituency, block, village, pollingStation) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/stats/voter-count/?${constituency != null ? `constituency=${constituency}` : ''}${block ? `&block=${block}` : ''}${village ? `&village=${village}` : ''}${pollingStation ? `&polling_station=${pollingStation}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getHousesCount(constituency, block, village, pollingStation) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/stats/house-count/?${constituency != null ? `constituency=${constituency}` : ''}${block ? `&block=${block}` : ''}${village ? `&village=${village}` : ''}${pollingStation ? `&polling_station=${pollingStation}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getHousesList(constituency, block, village, pollingStation, pageNumber, pageSize) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/houses/${pageNumber ? `?page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${constituency ? `&constituency=${constituency}` : ''}${block ? `&block=${block}` : ''}${village ? `&village=${village}` : ''}${pollingStation ? `&${pollingStation}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getUsers(constituency, block, village, pollingStation, selectedRole) {
  try {
    const resp = await axiosInstance.get(`/app-user/${constituency ? `?constituency=${constituency}` : ''}${block ? `&block=${block}` : ''}${village ? `&village=${village}` : ''}${pollingStation ? `&polling_station=${pollingStation}` : ''}${selectedRole ? `&role=${selectedRole.value}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function putAssignRoles(id, data) {
  try {
    const resp = await axiosInstance.put(`/assigned-roles/${id}/`, data);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

export {getGenderStats, getSurveyStats, getOpinionStats, getAgeStats, getReligionStats, 
  getCasteStats, getStateConstituencies, getConstituencies, getBlocks, getVillages, 
  getPollingStationIncharges, getHouseVisitedCount, getCommentsOfVolunteer, getImportantVoters, 
  getVoterData, getUpdateVoterData, getPollingStations, filterFields, getHouseIncharges, 
  getDesignation, getDistricts, getTowns, NewVolunteerFieldsProperties, getReportingTo, 
  getIncharges, getVolunteer, getAllVillages, getDemographicsData, getVillageCategory, 
  getVoterDetails, getVoterSummary, voterFilterFields, getCasteList, getSubCaste, getGenderName, 
  getReligion, getOpinion, getRelationTypeName, updateVoterData, getNameFromList, getVillageProfileList, 
  getVillageProfile, addVoterSurvey, getVoterSurveyList, getVoterSurveyQuestionsList, postVoterSurveyResponse,
  putVoterSurveyActive, getSearchVoter, getInfluentialPosition, getGovernmentScheme, getUserProfile, postVolunteerComment,
  getSearchUser, getVolunteerComments, psInchargeFields, getPollingStationHouses, getVoterDetailsMobileWise, getUniqueMobileNumbers,
  formFilterFields, getVillageSmsTemplate, addSmsTemplate ,getSmsTemplate,changePassword, getSettings, getSmsSpecificTemplate,
  updateSmsTemplate, deleteSmsTemplate, updateSettings, getSubstitutionOptions, createSendSMS, searchVillage, getAssignedHouses, getHouseDetails, emailSubmitPasswordReset, passwordReset, tokenValidationForReset, getLevelOfInfluence, getUserRoles, getUserDetails, postCreateUser, getPollingStationsForRoles, getBlocksForRoles, getAssemblyConstituenciesForRoles, getHousesForRoles, getVoterCount, getHousesCount, getHousesList, getUsers, putAssignRoles};
