import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import Error from '../error';
import RotatingLineLoader from '../RotatingLineLoader';

class InchargesTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: '',
    }
  }

  render() {
    return(
        <div className="card">
          <div className="card-body overflow-x-auto">
             {
              this.props.incharges != null ? 
          <table className="table table-striped">
              <thead>
                <tr className="bg-primary">
                  <th className="text-center">S.No.</th>
                  <th>Name</th>
                  <th>Voter ID</th>
                  <th>PollingStation</th>
                  <th>Village</th>
                  <th>Block</th>
                  <th>Constituency</th>
                  <th>Comments</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.props.incharges?.map((incharge, index) => 
                  <tr key={ incharge.id }>
                    <td className="text-center">{ index + 1 }</td>
                    <td>{ incharge.name }</td>
                    <td>{ incharge.voterId }</td>
                    <td>{ incharge.pollingStation }</td>
                    <td>{ incharge.village }</td>
                    <td>{ incharge.block }</td>
                    <td>{ incharge.constituency }</td>
                    <td>{ incharge.comments }</td>
                    <td className="text-center"><Link to={ `/view-incharge` } ><FontAwesomeIcon icon={ faEye } /></Link></td>
                  </tr>
                  )
                }
              </tbody>
            </table> : this.props.inchargeTableLoader == true ? <RotatingLineLoader /> : this.state.error != '' ? 
              <Error /> :
              <div className="text-center fw-600 fs-6 text-black-50">No data found</div> }
          </div>
        </div>
    );
  }
}

export default InchargesTable;