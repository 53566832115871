import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Voters.css';
import { Link  } from 'react-router-dom';
import { getVoterData, getCasteList, getSubCaste, getGenderName, getReligion, getOpinion, getRelationTypeName, updateVoterData, getInfluentialPosition, getGovernmentScheme } from '../../services/Voters';
import { useParams, useNavigate } from "react-router-dom";
import { RotatingLines } from  'react-loader-spinner';
import Error from 'components/error';
import {mobileNumberValidation, emailValidation, dateValidation} from '../helper/Validations';
import {Operations} from '../toast/operations';
import Multiselect from "react-widgets/Multiselect";
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';

function UpdateVoterDetails() {
  const [voterData, setVoterData] = useState(null);
  const [voterError, setVoterError] = useState(null);
  const [casteError, setCasteError] = useState(null);
  const [subCasteError, setSubCasteError] = useState(null);
  const [mobileError, setMobileError] = useState(null);
  const [alternateMobileError, setAlternateMobileError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const { voterid } = useParams();
  const [casteList, setCasteList] = useState([]);
  const [subCasteList, setSubCasteList] = useState([]);
  const [religionList, setReligionList] = useState([]);
  const [religionError, setReligionError] = useState(null);
  const [opinionList, setOpinionList] = useState([]);
  const [opinionError, setOpinionError] = useState(null);
  const [influentialPositionList, setInfluentialPositionList] = useState([]);
  const [influentialPositionError, setInfluentialPositionError] = useState(null);
  const [selectedInfluentialPositionList, setSelectedInfluentialPositionList] = useState([]);
  const [governmentSchemeList, setGovernmentSchemeList] = useState(null);
  const [governmentBeneficiaryError, setGovernmentBeneficiaryError] = useState(null);
  const [governmentSchemeError, setGovernmentSchemeError] = useState(null);
  const [selectedGovernmentSchemeList, setSelectedGovernmentSchemeList] = useState([]);
  const [dobError, setDobError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      getVoterData(voterid).then((voterData) => {

          const influentialPosition = voterData.influential_position;
          let newInfluentialPositionArray = [];
          for(let i=0;i<influentialPosition?.length;i++) {
            newInfluentialPositionArray = [ ...newInfluentialPositionArray, influentialPosition[i].id ]
          }
          setSelectedInfluentialPositionList(newInfluentialPositionArray);
          let voter = {...voterData, influential_position: newInfluentialPositionArray};

          const schemeArray = voter.benefited_by;
          let newSchemeArray = [];
          for(let i=0;i<schemeArray?.length;i++) {
            newSchemeArray = [ ...newSchemeArray, schemeArray[i].id ]
          }
          setSelectedGovernmentSchemeList(newSchemeArray);
          setVoterData({...voter, benefited_by: newSchemeArray});

        getSubCaste(voterData?.caste.id).then((subCasteList) => {
          setSubCasteList(subCasteList);
        }).catch((err) => {
          throw err;
        })
      }).catch((voterError) => {
        setVoterError(voterError);
      })

      getCasteList().then((casteList) => {
        setCasteList(casteList);
      }).catch((casteError) => {
        setCasteError(casteError);
      })

      getReligion().then((religionList) => {
        setReligionList(religionList);
      }).catch((religionError) => {
        setReligionError(religionError);
      })

      getOpinion().then((opinionList) => {
        setOpinionList(opinionList);
      }).catch((opinionError) => {
        setOpinionError(opinionError);
      })

      getInfluentialPosition().then((influentialPositionList) => {
        setInfluentialPositionList(influentialPositionList);
      }).catch((err) => {
        setInfluentialPositionError(err);
      })

      getGovernmentScheme().then((governmentSchemeList) => {
        setGovernmentSchemeList(governmentSchemeList);
      }).catch((err) => {
        setGovernmentSchemeError(err);
      })
      
    }, 3000);
  }, []);

  const handleCaste = (event) => {
    getSubCaste(event.target.value).then((subCasteList) => {
      setSubCasteList(subCasteList);
    }).catch((subCasteError) => {
      setSubCasteError(subCasteError);
    })
    setVoterData({...voterData, caste: {id: event.target.value}, subcaste: null});
  }

  const handleMobileNumberValidation = (event) => {
    let fieldId = event.target.id;
    let mobileNumber = event.target.value;
    const result = mobileNumberValidation(mobileNumber);
    if(fieldId == 'mobileNumber') {
      setMobileError(result);
    }
    else if(fieldId == 'alternateMobileNumber') {
      setAlternateMobileError(result);
    }
  }

  const handleEmailValidation = (event) => {
    let fieldId = event.target.id;
    let email = event.target.value;
    const result = emailValidation(email);
    if(fieldId == 'emailId') {
      setEmailError(result);
    }
  }

  const handleDate = (event) => {
    const result = dateValidation(event.target.value);
    if(result == '') {
      setVoterData({...voterData, dob: event.target.value});
      setDobError(null);
    }
    else {
      setDobError(result);
    }
  }

  const handleMobileNumber = (event) => {
    setVoterData({...voterData, mobile_number: event.target.value });
  }

  const handleAlternateMobileNumber = (event) => {
    setVoterData({...voterData, alternate_mobile_number: event.target.value});
  }

  const handleEmail = (event) => {
    setVoterData({...voterData, email_address: event.target.value});
  }

  const handleDead = (event) => {
    setVoterData({...voterData, dead: event.target.checked});
  }

  const handleSubCaste = (event) => {
    setVoterData({...voterData, subcaste: {id: event.target.value}});
    setSubCasteError(null);
  }

  const handleReligion = (event) => {
    setVoterData({...voterData, religion: {id: event.target.value}});
  }

  const handleOpinion = (event) => {
    setVoterData({...voterData, opinion: {id: event.target.value}});
  }

  const handleProfession = (event) => {
    setVoterData({...voterData, profession: event.target.value});
  }

  const handleEducationalQualification = (event) => {
    setVoterData({...voterData, educational_qualification: event.target.value});
  }

  const handleNewHouseNo = (event) => {
    setVoterData({...voterData, new_house_no: event.target.value});
  }

  const handleNewSociety = (event) => {
    setVoterData({...voterData, new_society: event.target.value});
  }

  const handleComplaints = (event) => {
    setVoterData({...voterData, complaints: event.target.value});
  }

  const handleDemands = (event) => {
    setVoterData({...voterData, demands: event.target.value});
  }

  const handleExtraInfo =(event) => {
    setVoterData({...voterData, extra_info: event.target.value});
  }

  const handleInfluentialVoter = (event) => {
    setVoterData({...voterData, influential_voter: event.target.checked});
    setInfluentialPositionError(null);
  }

  const handleGovernmentBeneficiaryVoter = (event) => {
    setVoterData({...voterData, government_beneficiary: event.target.checked});
    setGovernmentSchemeError(null);
  }

  const handleFormSubmit = (event) => {
    let data = voterData;
    let caste = data.caste?.id;
    let subcaste = null;
    if(subCasteList.length !== 0) {
      subcaste = data.subcaste?.id;
    }
    if(data.influential_voter === false) {
      data = {...data, influential_position: []};
    }
    if(data.government_beneficiary === false) {
      data = {...data, benefited_by: []};
    }
    let religion = data.religion?.id;
    let opinion = data.opinion?.id;
    data = {...data, caste, subcaste, religion, opinion};
    if(voterData?.influential_voter === true && voterData?.influential_position.length === 0) {
      setInfluentialPositionError('Please select at least one influential position');
    }
    else if (voterData?.government_beneficiary === true && voterData?.benefited_by.length === 0) {
      setGovernmentSchemeError('Please select at least one government scheme');
    }
    else if(subCasteList?.length !== 0 && (subcaste === null || subcaste === undefined)) {
      setSubCasteError('Please select SubCaste.');
    }
    else {
      updateVoterData(data).then((data) => {
        navigate(`/voters?op=${Operations.UPDATE_VOTER}&status=success`);
      }).catch((err)=> {
        navigate(`/voters?op=${Operations.UPDATE_VOTER}&status=error`);
      });
    }
  }

  const handleInfluentialPosition = (event) => {
    let newArray = [];
    for(let i=0;i<event?.length;i++) {
      newArray = [ ...newArray, event[i].id ]
    }
    setSelectedInfluentialPositionList(newArray);
    setVoterData({...voterData, influential_position: newArray});
    setInfluentialPositionError(null);
  }

  const handleGovernmentScheme = (event) => {
    let newArray = [];
    for(let i=0;i<event?.length;i++) {
      newArray = [ ...newArray, event[i].id ]
    }
    setSelectedGovernmentSchemeList(newArray);
    setVoterData({...voterData, benefited_by: newArray});
    setGovernmentSchemeError(null);
  }
  if(voterData != null)
    return(
          <div className='card'>
            <div className='card-body'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th colSpan={2} className="text-center" >Update Voter Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Voter Id</td>
                      <td className='w-50'>
                        <label htmlFor="voterId" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Voter Id</label>
                        <input className='form-control' name='id_card_number' id='voterId' type="text" defaultValue={voterData?.id_card_number ? voterData?.id_card_number : ''} readOnly/>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>First Name</td>
                      <td>
                      <label htmlFor="first_name" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>First Name</label>
                        <input className='form-control' name='firstName' id='firstName' type="text" defaultValue={voterData?.first_name ? voterData?.first_name : ''} readOnly/>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Last Name</td>
                      <td>
                        <label htmlFor="last_name" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Last Name</label>
                        <input className='form-control' name='lastName' id='lastName' type="text" defaultValue={voterData?.last_name ? voterData?.last_name : ''} readOnly/>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Relation Type</td>
                      <td>
                        <label htmlFor="relation_type" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Relation Type</label>
                        <input className='form-control' name='relationType' id='relationType' value={voterData?.relation_type ? getRelationTypeName(voterData?.relation_type) : ''} readOnly/>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Relation First Name</td>
                      <td>
                        <label htmlFor="relation_first_name" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Relation First Name</label>
                        <input className='form-control' name='relationFirstName' id='relationFirstName' type="text" defaultValue={voterData?.relation_firstname ? voterData?.relation_firstname : ''} readOnly/>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Relation Last Name</td>
                      <td>
                        <label htmlFor="relation_last_name" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Relation Last Name</label>
                        <input className='form-control' name='relationLastName' id='relationLastName' type="text" defaultValue={voterData?.relation_lastname ? voterData?.relation_lastname : ''} readOnly/>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>House No.</td>
                      <td>
                        <label htmlFor="house_no" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>House No.</label>
                        <input className='form-control' name='houseNo' id='houseNo' type="text" defaultValue={voterData?.house_no ? voterData?.house_no : ''} readOnly/>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Serial Number</td>
                      <td>
                        <label htmlFor="serial_number" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Serial Number</label>
                        <input className='form-control' name='serialNumber' id='serialNumber' type="text" defaultValue={voterData?.serial_number ? voterData?.serial_number : ''} readOnly/>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Gender</td>
                      <td>
                        <label htmlFor="gender" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>gender</label>
                        <input type="text" value={voterData?.gender ? getGenderName(voterData?.gender) : ''} className='form-control' readOnly/>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Part Number</td>
                      <td>
                        <label htmlFor="part_number" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Part Number</label>
                        <input className='form-control' name='partNumber' id='partNumber' type="text" defaultValue={`${voterData?.polling_station.part_number ? voterData?.polling_station.part_number : ''} (${voterData?.polling_station.name ? voterData?.polling_station.name : ''})`} readOnly/>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Polling station Building</td>
                      <td>
                        <label htmlFor="polling_station_building" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Polling station Building</label>
                        <input type="text" name="pollingStationBuilding" id="pollingStationBuilding" className='form-control' defaultValue={voterData?.polling_station.building ? voterData?.polling_station.building : ''} readOnly/>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Polling station location</td>
                      <td>
                        <label htmlFor="location" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Polling station Location</label>
                        <input type="text" name="lcoation" id="location" defaultValue={voterData?.polling_station.location ? voterData?.polling_station.location : ''} className='form-control' readOnly/>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Village</td>
                      <td>
                        <label htmlFor="village" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Village</label>
                        <input type="text" name='village' id='village' className='form-control' defaultValue={voterData?.polling_station.village ? voterData?.polling_station.village : ''} readOnly/>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Constituency</td>
                      <td>
                        <label htmlFor="constituency" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Constituency</label>
                        <input type="text" name='constituency' id='constituency' className='form-control' defaultValue={voterData?.polling_station.constituency.name ? voterData?.polling_station.constituency.name : ''} readOnly/>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Visited Count</td>
                      <td>
                        <label htmlFor="visited_count" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Visited Count</label>
                        <input type="text" name="visitedCount" id="visitedCount" className='form-control' defaultValue={voterData?.visited_count ? voterData?.visited_count : ''} readOnly/>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Age</td>
                      <td>
                        <label htmlFor="age" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Age</label>
                        <input className='form-control' name='age' id='age' type="number" defaultValue={voterData?.age ? voterData?.age : ''} readOnly/>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Date of birth</td>
                      <td>
                        <label htmlFor="date_of_birth" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Date of birth</label>
                        <input className='form-control' name='dob' id='dateOfBirth' type="date" defaultValue={voterData?.dob ? voterData?.dob : ''} onChange={handleDate} />
                        <span className='text-danger'>{dobError && dobError}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Mobile Number</td>
                      <td>
                        <label htmlFor="mobile_number" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Mobile Number</label>
                        <input className='form-control' name='mobile_number' id='mobileNumber' type="tel" minLength='10' maxLength='10' placeholder='Provide Mobile Number' defaultValue={voterData?.mobile_number ? voterData?.mobile_number : ''} onBlur={handleMobileNumberValidation} onChange={handleMobileNumber} />
                        <span className='text-danger'>{mobileError && mobileError}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Alternate Mobile Number</td>
                      <td>
                        <label htmlFor="alternate_mobile_number" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Alternate Mobile Number</label>
                        <input className='form-control' name='alternate_mobile_number' id='alternateMobileNumber' type="tel" minLength='10' maxLength='10' placeholder=' Provide Alternate Mobile Number' defaultValue={voterData?.alternate_mobile_number ? voterData?.alternate_mobile_number : ''} onBlur={handleMobileNumberValidation} onChange={handleAlternateMobileNumber} />
                        <span className='text-danger'>{alternateMobileError && alternateMobileError}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Email Id</td>
                      <td>
                        <label htmlFor="email_id" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Email Id</label>
                        <input className='form-control' name='email_address' id='emailId' type="email" placeholder='Provide Email Id' defaultValue={voterData?.email_address ? voterData?.email_address : ''} onBlur={handleEmailValidation} onChange={handleEmail} />
                        <span className='text-danger'>{emailError && emailError}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Profession</td>
                      <td>
                        <label htmlFor="profession" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Profession</label>
                        <input type="text" className='form-control' name='profession' id='profession' placeholder='Provide Profession' defaultValue={voterData?.profession ? voterData?.profession : ''} onBlur={handleProfession} />
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Educational Qualifications</td>
                      <td>
                        <label htmlFor="educational_qualifications" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Educational Qualifications</label>
                        <input type="text" className='form-control' name='educational_qualification' id='educationalQualification' placeholder='Provide Education Qualification' defaultValue={voterData?.educational_qualification ? voterData?.educational_qualification : ''} onChange={handleEducationalQualification} />
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Dead</td>
                      <td>
                        <input type="checkbox" id='dead' name='dead' checked={voterData?.dead == true ? 'checked' : ''} onChange={handleDead} />
                        <label htmlFor="dead" className='d-block d-sm-none d-md-none d-lg-none fw-bold ps-3 d-inline-flex'>Dead</label>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>New Address</td>
                      <td>
                        <label htmlFor="new_address" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>New Address</label>
                        <input type="text" name='new_house_no' id="newAddressHouseNo" className='form-control' placeholder='Provide House No.' defaultValue={voterData?.new_house_no ? voterData?.new_house_no : ''} onChange={handleNewHouseNo} />
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold table-top-border-none'></td>
                      <td className='table-top-border-none'>
                        <input type="text" name='new_society' id="newAddressSociety" className='form-control' placeholder='Provide Society' defaultValue={voterData?.new_society ? voterData?.new_society : ''} onChange={handleNewSociety} />
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold' >Caste</td>
                      <td>
                        <label htmlFor="caste" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Caste</label>
                        <select className='form-select' id='caste' name='caste' onChange={handleCaste} value={voterData.caste?.id ? voterData?.caste.id : ''} >
                        <option value=''>Select Caste</option>
                          {casteList?.map(item => 
                            <option key={item.name} value={item.id}>{item.name ? item.name : ''}</option>
                          )}
                        </select>
                      </td>
                    </tr> 
                    {
                      subCasteList?.length !== 0 ?
                      <tr>
                        <td className='d-none d-sm-block fw-bold'>SubCaste</td>
                        <td>
                          <label htmlFor="sub_caste" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>SubCaste</label>
                          <select className='form-select' name='subcaste' id='subCaste' onChange={handleSubCaste} value={voterData.subcaste?.id ? voterData.subcaste.id : ''}>
                            <option value=''>Select Sub Caste</option>
                            {subCasteList?.map(item => 
                              <option key={item.id} value={item.id}>{item.name ? item.name : ''}</option>
                            )}
                          </select>
                          <span className='text-danger fw-600'>{subCasteError && subCasteError}</span>
                        </td>
                      </tr> : ''
                    }
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Religion</td>
                      <td>
                        <label htmlFor="religion" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Religion</label>
                        <select className='form-select' name='religion' id='religion' value={voterData.religion?.id ? voterData.religion?.id : ''} onChange={handleReligion}>
                          <option>Select Religion</option>
                          {religionList?.map(item => 
                          <option value={item.id} key={item.id}>{item.name ? item.name : ''}</option>
                          )}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Opinion</td>
                      <td>
                        <label htmlFor="opinion" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Opinion</label>
                        <select className='form-select' name='opinion' id='opinion' value={voterData.opinion?.id ? voterData.opinion?.id : ''} onChange={handleOpinion}>
                          <option>Select Opinion</option>
                          {opinionList?.map(item => 
                          <option value={item.id} key={item.id} >{item.name} - {item.complete_name ? ( item.complete_name ) : ''}</option>
                          )}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Complaints</td>
                      <td>
                        <label htmlFor="complaints" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Complaints</label>
                        <textarea name='complaints' id="complaints" className='form-control' placeholder='Provide Complaints' defaultValue={voterData?.complaints ? voterData?.complaints : ''} onChange={handleComplaints}></textarea>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Demands</td>
                      <td>
                        <label htmlFor="demands" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Demands</label>
                        <textarea name='demands' id="demands" className='form-control' placeholder='Provide Demands' defaultValue={voterData?.demands ? voterData?.demands : ''} onChange={handleDemands}></textarea>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Extra Info</td>
                      <td>
                        <label htmlFor="extra_info" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>Extra Info</label>
                        <textarea className='form-control' name='extra_info' id='extraInfo' placeholder='Provide Extra Info' defaultValue={voterData?.extra_info ? voterData?.extra_info : ''} onChange={handleExtraInfo}></textarea>
                      </td>
                    </tr>
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Influential Voter</td>
                      <td className=''>
                        <input type="checkbox" id='influentialVoter' name='influential_voter' checked={voterData?.influential_voter == true ? 'checked' : ''} onChange={handleInfluentialVoter} />
                        <label htmlFor="influential_voter" className='d-block d-sm-none d-md-none d-lg-none fw-bold ps-3 d-inline-flex'> Influential Voter</label>
                      </td>
                    </tr>
                    {
                    voterData?.influential_voter === true ? 
                    <tr>
                      <td className='fw-bold'>Influential Positon</td>
                      <td>
                        <Multiselect data={influentialPositionList ? influentialPositionList : []} defaultValue={ voterData?.influential_position } dataKey='id' textField="name" onChange={handleInfluentialPosition} />
                        <span className='text-danger fw-600'>{influentialPositionError && influentialPositionError}</span>
                      </td>
                    </tr> : ''
                  }
                    <tr>
                      <td className='d-none d-sm-block fw-bold'>Government Beneficiary</td>
                      <td className=''>
                        <input type="checkbox" id='governmentBeneficiary' name='government_beneficiary' checked={voterData?.government_beneficiary == true ? 'checked' : ''} onChange={handleGovernmentBeneficiaryVoter} />
                        <label htmlFor="influential_voter" className='d-block d-sm-none d-md-none d-lg-none fw-bold ps-3 d-inline-flex'> Government Beneficiary</label>
                      </td>
                    </tr>
                    {
                    voterData?.government_beneficiary === true ? 
                    <tr>
                      <td className='fw-bold'>Government Scheme</td>
                      <td>
                        <Multiselect data={governmentSchemeList ? governmentSchemeList : []} defaultValue={ voterData?.benefited_by } dataKey='id' textField="name" onChange={handleGovernmentScheme} />
                        <span className='text-danger fw-600'>{governmentSchemeError && governmentSchemeError}</span>
                      </td>
                    </tr> : ''
                  }
                    <tr>
                      <td className='border-0 d-none d-sm-block'></td>
                      <td className='border-0 text-right'>
                        <Link to={`/voter-details-view/${voterData?.id_card_number}`} className='btn btn-warning py-2 px-3 mx-2'>Cancel</Link>
                        <button className='btn btn-success py-2 px-3' onClick={handleFormSubmit}>Submit</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </div>
    );
  else if(voterError == null)
    return (
          <div className=''>
           <RotatingLines strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="96"
                  visible={true} />
          </div>);
  else
    return (
          <div className=''>
            <Error />
          </div>
    );
  }

export default UpdateVoterDetails;