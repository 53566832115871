import React from "react";
import Combobox from "react-widgets/Combobox";


class Designation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: ''
    };
    this.handleChangeRole = this.handleChangeRole.bind(this);
  }

  handleChangeRole(event) {
    this.props.onChangeRole(event);
  }
  render() {
    const fieldProperties = this.props.designationFields.designation;
    return(
      <div className={ `col-md-${fieldProperties?.colmd} col-lg-${fieldProperties?.collg} col-${fieldProperties?.col} col-sm-${fieldProperties?.collg}` }>
        <div className="form-group my-auto">
        { fieldProperties.labelStatus ? <label>{ fieldProperties.label }</label> : '' }
          <Combobox placeholder={ fieldProperties.placeholder } data={this.props.userRoles != null ? this.props.userRoles : []} options={this.props.userRoles != null ? this.props.userRoles : []} dataKey='value' textField='name' value={ this.props.selectedRole != null ? this.props.selectedRole : [] } onChange={this.handleChangeRole} />
        </div>
      </div>
    )
  }
}

export default Designation;