import React from "react";
import AddNewComment from "./AddNewComment";
import { getVolunteerComments } from '../../services/Voters';
import { RotatingLines } from  'react-loader-spinner';
import Error from '../error';

class VolunteerComments extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			commentsList: [],
			error: '',
			loaderStatus: false
		};
	}

	componentDidMount() {
		this.setState({loaderStatus: true});
		getVolunteerComments().then((commentsList) => {
			this.setState({commentsList, loaderStatus: false});
		}).catch((error) => {
			this.setState({error});
		})
	}

	render() {
		return(
			<>
				<AddNewComment />
				<div className="card">
					<div className="card-body">
						<div className="row">
							<div className="col-12 fw-700 fs-5 text-center p-3">Comments</div>
						</div>
							{ this.state.commentsList != '' ? 
						<table className="table table-striped">
							<thead>
								<tr className="bg-primary">
									<th className="w-6 text-center">Sl. No.</th>
									<th>Comments</th>
								</tr>
							</thead>
							<tbody>
							{
								this.state.commentsList?.map((comments, index) => 
									<tr key={comments.id}>
										<td className="text-center align-middle fw-600">{index + 1}</td>
										<td>
											<div>{comments.text}</div>
											<div>{comments.created_on}</div>
										</td>
									</tr>
								)
							}
							</tbody>
						</table> : this.state.loaderStatus == true ? 
						<div className='data-loader text-center'>
								<RotatingLines strokeColor="grey"
				                  strokeWidth="5"
				                  animationDuration="0.75"
				                  width="96"
				                  visible={true} />
							</div> :
						this.state.error != '' ? 
							<Error /> :
							<div className="text-center fw-500 fs-6 text-black-50">No comments found</div> 
				}
					</div>
				</div>
			</>
		)
	}
}

export default VolunteerComments;