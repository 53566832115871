import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Dashboard-Style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilterCircleXmark, faFilter } from '@fortawesome/free-solid-svg-icons';
import DashboardFilter from './DashboardFilter';
import DashboardCards from '../../components/dashboard/DashboardCards';
import DashboardChartVoters from '../../components/dashboard/DashboardChart';
import DashboardPollingStationInchargeTable from './DashboardPollingStationIncharges';
import DashboardHouseVisitedCount from '../../components/dashboard/DashboardHouseVisitedCount';
import DashboardVolunteerComments from '../../components/dashboard/DashboardVolunteerComments';
import DashboardImportantVoters from '../../components/dashboard/DashboardImportantVoters';
import { getPollingStationIncharges, getHouseVisitedCount, getCommentsOfVolunteer, getImportantVoters, getBlocks, getStateConstituencies, getHousesCount, getVoterCount, getVillages } from '../../services/Voters';
import {generateRandomDarkColor} from '../helper/Validations';
import ScaleLoader from "react-spinners/ScaleLoader";

class Dashboard extends React.Component {
  
  constructor(props) {
	super(props);
	this.state = {
	  value: '',
	  cards: [],
	  importantVotersData: [],
	  filterShowing: true,
	  pollingStationInchargesData: [],
	  houseVisitedCountData: null,
	  commentsOfVolunteerData: [],
	  selectedConstituency: 86,
	  selectedBlock: null,
	  selectedVillage: null,
	  selectedPollingStation: null,
	  constituencies: [],
	  blocks: [],
      villages: [],
      pollingStationList: [],
	  importantVoterError: null,
	  importantVotersLoaderStatus: false,
	  initialPage: '1',
	  selectedConstituencyObj: { "id": 86, "name": "Munugode" },
	  houseVisitedCountLoader: false,
	  houses: null,
	  houseLoaderStatus: false,
	  voterCount: null,
	  voterCountLoader: false,
	  acDisabled: false,
	  blockDisabled: false
	};

	this.handleChange = this.handleChange.bind(this);
	this.onApplyFilter = this.onApplyFilter.bind(this);
	this.onChangePollingStationInchargePagination = this.onChangePollingStationInchargePagination.bind(this);
	this.onChangeHouseVisitCountPagination = this.onChangeHouseVisitCountPagination.bind(this);
	this.onChangeVolunteerCommentsPagination = this.onChangeVolunteerCommentsPagination.bind( this );
	this.onChangeImportantVotersPagination = this.onChangeImportantVotersPagination.bind(this);
  }

  handleChange(event) {
	this.setState({value: event.target.value, filterShowing: !this.state.filterShowing});
  }

  onApplyFilter(selectedConstituency, selectedBlock, selectedVillage, selectedPollingStation, constituencies, blocks, villages, pollingStationList, selectedConstituencyObj) {

	let event = [];
	if(pollingStationList != undefined && pollingStationList.length != 0) {
	  event = pollingStationList;
	}
	else if(villages != undefined && villages.length != 0) {
	  event = villages;
	}
	else if (blocks != undefined && blocks.length != 0) {
	  event = blocks;
	}
	this.setState({
		cards: event.map((data) => ({...data, color: generateRandomDarkColor()})),
	 	selectedConstituency,
		selectedBlock,
		selectedVillage,
		selectedPollingStation,
		constituencies,
		blocks,
		villages,
		pollingStationList,
		selectedConstituencyObj,
		voterCountLoader: true,
		voterCount: null,
		houseLoaderStatus: true,
		houses: null,
		importantVotersLoaderStatus: true
	});

	getVoterCount(selectedConstituency, selectedBlock, selectedVillage, selectedPollingStation).then((voterCount) => {
	  this.setState({voterCount, voterCountLoader: false});
	}).catch((error) => {
	  this.setState({error, voterCountLoader: false});
	})

	getHousesCount(selectedConstituency, selectedBlock, selectedVillage, selectedPollingStation).then((houses) => {
	this.setState({houses, houseLoaderStatus: false});
	}).catch((error) => {
	this.setState({error, houseLoaderStatus: false});
	})

	  // PollingStation Incharges Data
	  getPollingStationIncharges(selectedConstituency, selectedBlock, selectedVillage, '').then((pollingStationInchargesData) => {
		this.setState({pollingStationInchargesData});
	  }).catch((err) => {
		console.log(err);
	  })

	  // House Visited Count
	  getHouseVisitedCount(selectedConstituency, selectedBlock, selectedVillage, '').then((houseVisitedCountData) => {
		this.setState({houseVisitedCountData});
	  }).catch((err) => {
		console.log(err);
	  })

	  // Comments of Volunteer
	  getCommentsOfVolunteer(selectedConstituency, selectedBlock, selectedVillage, '').then((commentsOfVolunteerData) => {
		this.setState({commentsOfVolunteerData});
	  }).catch((err) => {
		console.log(err);
	  })

	  // Important Voters
	  getImportantVoters(selectedConstituency, selectedBlock, selectedVillage, '').then((importantVotersData) => {
		this.setState({importantVotersData, importantVotersLoaderStatus: false});
	  }).catch((importantVoterError) => {
		this.setState({importantVoterError, importantVotersLoaderStatus: false});
	  })
  }

  onChangePollingStationInchargePagination(selectedConstituency, selectedBlock, village, event) {
	getPollingStationIncharges(selectedConstituency, selectedBlock, village, event).then((pollingStationInchargesData) => {
	  this.setState({pollingStationInchargesData});
	}).catch((err) => {
	  console.log(err);
	})
  }

  onChangeHouseVisitCountPagination(selectedConstituency, selectedBlock, village, pageNumber, pageSize) {
	this.setState({houseVisitedCountData: null, houseVisitedCountLoader: true})
	getHouseVisitedCount(selectedConstituency, selectedBlock, village, pageNumber, pageSize).then((houseVisitedCountData) => {
		this.setState({houseVisitedCountData, houseVisitedCountLoader: false});
	}).catch((err) => {
		this.setState({houseVisitedCountLoader: false});
	  console.log(err);
	})
  }

  onChangeVolunteerCommentsPagination( selectedConstituency, selectedBlock, village, pageNumber ) {
	getCommentsOfVolunteer( selectedConstituency, selectedBlock, village, pageNumber ).then(( commentsOfVolunteerData ) => {
	  this.setState({ commentsOfVolunteerData });
	}).catch(( err ) => {
	  console.log( err );
	})
  }

  onChangeImportantVotersPagination(selectedConstituency, selectedBlock, village, pollingStation, pageNumber, pageSize) {
	this.setState({importantVotersData: [], importantVotersLoaderStatus: true});
	getImportantVoters(selectedConstituency, selectedBlock, village, pollingStation, pageNumber, pageSize).then((importantVotersData) => {
	  this.setState({importantVotersData, importantVotersLoaderStatus: false});
	}).catch((importantVoterError) => {
	  this.setState({importantVoterError, importantVotersLoaderStatus: false});
	})
  }

  componentDidMount() {
	getStateConstituencies().then((constituencies) => {
	  let selectedConstituency = 86;

	  this.setState({voterCountLoader: true});
	  getVoterCount(selectedConstituency, null, null, null).then((voterCount) => {
		this.setState({voterCount, voterCountLoader: false});
	  }).catch((error) => {
		this.setState({error, voterCountLoader: false});
	  })

	  if(constituencies && constituencies.length == 0)
	  	this.setState({acDisabled: true});

	  getBlocks(selectedConstituency).then((blocks) => {

		let pollingStationList = [];
		let villages = [];
		let selectedPollingStation = null;
		let selectedVillage = null;
		let selectedBlock = null;

		if(blocks && blocks.length == 0) {
			getVillages(null).then((villages) => {
				this.setState({villages, blockDisabled: true});
			});
		}

		// PollingStation Incharges Data
		getPollingStationIncharges(selectedConstituency, selectedBlock, selectedVillage, '').then((pollingStationInchargesData) => {
			this.setState({pollingStationInchargesData});
		}).catch((err) => {
			console.log(err);
		})

		// House Visited Count
		getHouseVisitedCount(selectedConstituency, selectedBlock, selectedVillage, '').then((houseVisitedCountData) => {
			this.setState({houseVisitedCountData});
		}).catch((err) => {
			console.log(err);
		})

		// Comments of Volunteer
		getCommentsOfVolunteer(selectedConstituency, selectedBlock, selectedVillage, '').then((commentsOfVolunteerData) => {
			this.setState({commentsOfVolunteerData});
		}).catch((err) => {
			console.log(err);
		})

		// Important Voters
		this.setState({importantVotersLoaderStatus: true});
		getImportantVoters(selectedConstituency, selectedBlock, selectedVillage, selectedPollingStation, 1, 10).then((importantVotersData) => {
			this.setState({importantVotersData, importantVotersLoaderStatus: false});
		}).catch((importantVoterError) => {
			this.setState({importantVoterError, importantVotersLoaderStatus: false})
		})

		let event = [];
		if (blocks != undefined && blocks.length != 0) {
			event = blocks;
		}
		
		this.setState({cards: event.map((data) => ({...data, color: generateRandomDarkColor()})), selectedConstituency, selectedBlock, selectedVillage, selectedPollingStation, constituencies, blocks, villages, pollingStationList});
	  }).catch((error) => {
		this.setState({error});
	  })
	  this.setState({houseLoaderStatus: true, houses: null});
	  getHousesCount(selectedConstituency, null, null, null).then((houses) => {
		this.setState({houses, houseLoaderStatus: false});
	  }).catch((error) => {
		this.setState({error, houseLoaderStatus: false});
	  })
	}).catch((err) => {
	  // alert("Server error, please try again later.");
	});
  }

  render() {
	return(
		<>
			<div className='row'>
			  <div className='col-md-12 text-start p-3'>
			  Dashboard <span className='bg-success p-1 text-white rounded-3' onClick={this.handleChange}><FontAwesomeIcon icon={this.state.filterShowing ? faFilterCircleXmark: faFilter} /></span>
			  </div>
			</div>
			{this.state.filterShowing && <DashboardFilter acDisabled={this.state.acDisabled} blockDisabled={this.state.blockDisabled} onApplyFilter={this.onApplyFilter} selectedConstituency={this.state.selectedConstituency} selectedBlock={this.state.selectedBlock} selectedVillage={this.state.selectedVillage} selectedPollingStation={this.state.selectedPollingStation} constituencies={this.state.constituencies} blocks={this.state.blocks} villages={this.state.villages} pollingStationList={this.state.pollingStationList} selectedConstituencyObj={this.state.selectedConstituencyObj} />}
		  <div className='d-flex justify-content-end'>
			<div className='total-voter pe-5 mt-3'>Houses Count: {this.state.houses ? this.state.houses.count : this.state.houseLoaderStatus == true ? <span><ScaleLoader color="#3f4342" height="10px" margin="1px" /></span> : 0}</div>
			<div className='total-voter pe-5 mt-3'>Total Voter: {this.state.voterCount ? this.state.voterCount?.count : this.state.voterCountLoader == true ? <span><ScaleLoader color="#3f4342" height="10px" margin="1px" /></span> : 0 }</div>
		  </div>
		  <DashboardCards cards={this.state.cards} />
		  <DashboardChartVoters selectedConstituency={this.state.selectedConstituency} selectedBlock={this.state.selectedBlock} selectedVillage={this.state.selectedVillage} selectedPollingStation={this.state.selectedPollingStation} />
		  <div className='row'>
			{/* <DashboardPollingStationInchargeTable pollingStationInchargesData={this.state.pollingStationInchargesData} constituency={this.state.constituency} onChangePollingStationInchargePagination={ this.onChangePollingStationInchargePagination } block={ this.state.selectedBlock } village={ this.state.village } />
			<DashboardHouseVisitedCount houseVisitedCountData={this.state.houseVisitedCountData} constituency={this.state.constituency} block={ this.state.selectedBlock } village={ this.state.village } onChangeHouseVisitCountPagination={ this.onChangeHouseVisitCountPagination } houseVisitedCountLoader={this.state.houseVisitedCountLoader} /> */}
			<DashboardVolunteerComments commentsOfVolunteerData={this.state.commentsOfVolunteerData} constituency={ this.state.constituency } block={ this.state.selectedBlock } village={ this.state.village } onChangeVolunteerCommentsPagination={ this.onChangeVolunteerCommentsPagination } />
			<DashboardImportantVoters importantVotersData={this.state.importantVotersData} constituency={this.state.selectedConstituency} block={this.state.selectedBlock} village={this.state.selectedVillage} pollingStation={this.state.selectedPollingStation} onChangeImportantVotersPagination={this.onChangeImportantVotersPagination} importantVoterError={this.state.importantVoterError} loaderStatus={this.state.importantVotersLoaderStatus} initialPage={this.state.initialPage} />
		  </div>
		</>
	);
  }
}

export default Dashboard;