import {React, useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { RotatingLines } from  'react-loader-spinner';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Link , useNavigate} from "react-router-dom";
import { addSmsTemplate , getSmsTemplate,getSmsSpecificTemplate,updateSmsTemplate,deleteSmsTemplate } from '../../services/Voters';
import {Operations} from '../toast/operations';
import StatusToast from "../toast/StatusToast";
import Error from '../error';
import $ from "jquery";
import RotatingLinesLoader from '../RotatingLineLoader';



export default function SmsTemplate(props){

	const [templateName, setTemplateName] = useState('');
	const [templateContent, setTemplateContent] = useState('');
	const [error, setError] = useState(null);
	const [smsTemplateList, setSmsTemplateList] = useState(null);
	const [validationErr, setValidationErr] = useState();
	const [isUpdate, setIsUpdate] = useState(false); 
	const [editId , setEditId] = useState();
	const [isSubmit , setIsSubmit] = useState();
	const [deleteSmsTemplateId, setDeleteSmsTemplate] = useState();
	const [smsTemplateIdError, setSmsTemplateIdError] = useState(null);
	const [smsTemplateContentError, setSmsTemplateContentError] = useState(null);
	const [entityId, setEntityId] = useState('');
	const [entityIdError, setEntityIdError] = useState(null);
	const [loaderStatus, setLoaderStatus] = useState(false);

	let navigate = useNavigate();

	const handleSubmit = () =>{

		let SmsTemplateData = {'template_id': templateName, 'entity_id': entityId, 'message': templateContent};

		setEditId('');
		let obj = smsTemplateList.find(o => o.template_id === templateName);
		if(templateName == '') {
			setSmsTemplateIdError('Please fill the template id.');
		}
		else if(entityId == '') {
			setEntityIdError('Please fill the entity id.');
		}
		else if(templateContent == '') {
			setSmsTemplateContentError('Please fill the message.');
		}
		else if(obj?.template_id){
			setValidationErr('Template Id already exists');
		}
		else{
			document.getElementById('close').click();
			setTemplateName('');
			setEntityId('');
			setTemplateContent('');
			setLoaderStatus(true);
			addSmsTemplate(SmsTemplateData).then((postData) => {
				getSmsTemplate().then((smsTemplateList) => {
					navigate(`/sms-template?op=${Operations.ADD_SMS_TEMPLATE}&status=success`);
					setSmsTemplateList(smsTemplateList);
					setLoaderStatus(false);
				}).catch((error) => {
			 		setError(error);
					setLoaderStatus(false);
				});
			}).catch((err) => {
				navigate(`/sms-template?op=${Operations.ADD_SMS_TEMPLATE}&status=error`);
			})
		}		
	
	}

	const handleTemplateName = (event) =>{
		setValidationErr('');
		setSmsTemplateIdError(null);
		setTemplateName(event.target.value);

	}

	const handleTemplateContent = (event) =>{
		setSmsTemplateContentError(null);
		setTemplateContent(event.target.value);
	}

	useEffect(() =>{
		setLoaderStatus(true);
		getSmsTemplate().then((smsTemplateList) => {
			setSmsTemplateList(smsTemplateList);
			setLoaderStatus(false);
		}).catch((error) => {
			 setError(error);
			 setLoaderStatus(false);
		});

	},[setIsSubmit])

	const handleEditClick = (id) =>{
		setSmsTemplateIdError(null);
		setEntityIdError(null);
		setSmsTemplateContentError(null);
		getSmsSpecificTemplate(id).then((smsTemplateList) => {
			setIsUpdate(true);
			setEditId(id);
			setTemplateName(smsTemplateList.template_id);
			setEntityId(smsTemplateList.entity_id ? smsTemplateList.entity_id : '');
			setTemplateContent(smsTemplateList.message);
		}).catch((error) =>{
			setError(error);
		});
	}

	const handleUpdate = () =>{
		let SmsTemplateData = {'template_id': templateName, 'entity_id': entityId, 'message': templateContent};
			let obj = smsTemplateList.find(o => o.template_id === templateName);
			if(templateName == '') {
				setSmsTemplateIdError('Please fill the template id.');
			}
			else if(entityId == '') {
				setEntityIdError('Please fill the entity id.');
			}
			else if(templateContent == '') {
				setSmsTemplateContentError('Please fill the message.');
			}
			else {
				setTemplateName('');
				setEntityId('');
				setTemplateContent('');
				updateSmsTemplate(editId,SmsTemplateData).then((postData) => {
					navigate(`/sms-template?op=${Operations.UPDATE_SMS_TEMPLATE}&status=success`);
					getSmsTemplate().then((smsTemplateList) => {
						setSmsTemplateList(smsTemplateList);
					}).catch((error) => {
						 setError(error);
					});
				}).catch((err) => {
					navigate(`/sms-template?op=${Operations.UPDATE_SMS_TEMPLATE}&status=error`);
				})
				document.getElementById('close').click();
			}
		}

	const handleDeletetemplate = (id) => {
		setDeleteSmsTemplate(id);
	}

	const handleDelete = (id) =>{
		deleteSmsTemplate(id).then((smsTemplateList) => {
				getSmsTemplate().then((smsTemplateList) => {
					setSmsTemplateList(smsTemplateList);
				}).catch((error) => {
			 		setError(error);
				});
				document.getElementById('deleteclose').click();
				navigate(`/sms-template?op=${Operations.DELETE_SMS_TEMPLATE}&status=success`);
		}).catch((error) =>{
			setError(error);
			navigate(`/sms-template?op=${Operations.DELETE_SMS_TEMPLATE}&status=error`);

		});
	}

	const handleOpenModel = () =>{
		setIsUpdate(false);
		setTemplateName('');
		setEntityId('');
		setTemplateContent('');
		setEntityIdError(null);
		setSmsTemplateContentError(null);
		setSmsTemplateIdError(null);
	}

	const handleEntityId = (event) => {
		setEntityIdError(null);
		setEntityId(event.target.value);
	}

	return(
		<>
			<StatusToast status='Voter Survey' delayTime="3000"/>
			<div className="col-md-12 col-lg-12 col-sm-12">
				<h4 className='d-inline'>SMS Template</h4>
				<div className="float-right d-inline">
					<button type="button" className="btn btn-success" data-toggle="modal" onClick={handleOpenModel} data-target="#exampleModalCenter">
						Add SMS Template
					</button>
				</div>
				<div className='card mt-4'>
					<div className='card-body'>
					<div className='me-2'>
						<div className="modal fade bd-example-modal-lg" id="exampleModalCenter" tabIndex="-1" role="document" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
							<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title" id="exampleModalLongTitle">SMS Template</h5>
										<button type="button" className="close" data-dismiss="modal" aria-label="Close" id="close">
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div className="modal-body">
										<label htmlFor="">Template Name</label>
										<input type="text" name="templateName" id="templateName" className="form-control" placeholder="Name" onChange={handleTemplateName} value={templateName != '' ? templateName : ''}/>
										<span className="text-danger fw-600">{smsTemplateIdError && smsTemplateIdError}</span>
										<span className="text-danger fw-600">{validationErr && validationErr}</span>
										<div className="from-group mt-2">
											<label htmlFor="">Entity Id</label>
											<input type="text" name="entityId" id="entityId" className='form-control' placeholder='Entity Id' onChange={handleEntityId} value={entityId != '' ? entityId : ''} />
											<span className='text-danger fw-600'>{entityIdError && entityIdError}</span>
										</div>
										<div className="form-group mt-3">
											<label htmlFor="">Message</label>
											<textarea name="templateContent" id="templateContent" cols="10" rows="7" className="form-control" placeholder="SMS Content" onChange={handleTemplateContent} value={templateContent}></textarea>
											<span className='text-danger fw-600'>{smsTemplateContentError && smsTemplateContentError}</span>
										</div>
									</div>
									<div className="modal-footer text-center">
										{isUpdate == true ? <button type="button m-auto" className="btn btn-success" onClick={handleUpdate}>Update</button> :<button type="button m-auto" className="btn btn-success" onClick={handleSubmit}>Save</button>}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="modal fade bd-example-modal-lg" id="deleteTemplate" tabIndex="-1" role="document" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="exampleModalLongTitle">Delete SMS Template</h5>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close" id="deleteclose">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="modal-body">
										<div className="form-group mt-3">
											Would you like to delete sms template.
										</div>
									</div>
									<div className="modal-footer text-center">
										<button className="btn btn-danger align-bottom" data-dismiss="modal">No</button>
										<button className="btn btn-success" onClick={() => handleDelete(deleteSmsTemplateId)}>Yes</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='mx-4'>
						{ smsTemplateList != null && smsTemplateList?.length > 0 ? 
						<table className="table border-top table-responsive-sm table-bordered">
							<thead>
								<tr className='fw-600 bg-primary'>
									<td>Id</td>
									<td>Entity Id</td>
									<td>Template</td>
									<td className='text-center'>Action</td>
								</tr>
							</thead>
							<tbody>
								{ smsTemplateList?.map((template, index) => 
									<tr key={index}>
										<td>{template.template_id}</td>
										<td>{template.entity_id}</td>
										<td>{template.message}</td>
										<td className='text-center'>
											<button type="button" className="btn btn-success" data-toggle="modal" data-target="#exampleModalCenter" onClick={() => handleEditClick(template.id)}>Edit</button> 
											<button type="button" className="btn btn-danger ms-2" data-toggle="modal" onClick={() => handleDeletetemplate(template.id)} data-target="#deleteTemplate">
												Delete
											</button>
										</td>
									</tr>
								)}
							</tbody>
						</table> : loaderStatus == true ? <RotatingLinesLoader /> : 
						error != null ? <Error /> : <div className='text-center fw-600 fs-5 text-black-50 pb-5'>No data found</div> }
					</div>
				</div>
			</div>
		</>
	);
}