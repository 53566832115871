import React from "react";
import { getStateConstituencies, getBlocks, getUserDetails, getPollingStationsForRoles, getBlocksForRoles, getAssemblyConstituenciesForRoles, getHousesForRoles, putAssignRoles } from "../../services/Voters";
import EntityAssignmentSelection from "../EntityAssignmentSelection";
import UserAssignFilter from "./UserAssignFilter";
import withParams from "../GetParams";
import { Roles } from "../../config/defaults";
import StatusToast from "../toast/StatusToast";
import {Operations} from '../toast/operations';


class UserAssignEntity extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			constituencies: [],
			blocks: [],
			villages: [],
			pollingStationList: [],
			selectedConstituency : null,
			selectedBlock: null,
			selectedVillage: null,
			selectedPollingStation: null,
			selectedConstituencyObj: null,
			error: null,
			profile: null,
			data: null,
			id: null,
			loaderStatus: false,
			includeAssignedJob: false,
			pageSize: 20,
			pageNumber: 1,
			entitiesList: {}
		}
		this.onApplyFilter = this.onApplyFilter.bind(this);
		this.includeAssigned = this.includeAssigned.bind(this);
		this.onChangePageNumber = this.onChangePageNumber.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(id, assignedArray) {
		putAssignRoles(id, assignedArray).then((success) => {
			this.setState({})
			this.onApplyFilter(this.state.selectedConstituency, this.state.selectedBlock, this.state.selectedVillage, this.state.selectedPollingStation, this.state.constituencies, this.state.blocks, this.state.villages, this.state.pollingStationList, this.state.selectedConstituencyObj);
			this.props.navigate(`/user-assign/${id}/?op=${Operations.ASSIGNED_ROLES}&status=success`)
		}).catch((error) => {
			this.props.navigate(`/user-assign/${id}/?op=${Operations.ASSIGNED_ROLES}&status=error`);
		})
	}

	onChangePageNumber(pageNumber) {
		const selectedConstituency = this.state.selectedConstituency;
		const selectedBlock = this.state.selectedBlock;
		const selectedVillage = this.state.selectedVillage;
		const selectedPollingStation = this.state.selectedPollingStation; 
		this.setState({data: null, loaderStatus: true, pageNumber});
		if(this.state.profile?.role == Roles.POLLING_STATION_INCHARGE.value) {
			getPollingStationsForRoles(selectedConstituency, selectedBlock, selectedVillage, this.state.includeAssignedJob, pageNumber, this.state.pageSize).then((data) => {
				this.setState({data, loaderStatus: false});
			}).catch((error) => {
				this.setState({error, loaderStatus: false});
			})
		}
		else if(this.state.profile?.role == Roles.BLOCK_INCHARGE.value) {
			getBlocksForRoles(selectedConstituency, this.state.includeAssignedJob, pageNumber, this.state.pageSize).then((data) => {
				this.setState({data, loaderStatus: false});
			}).catch((error) => {
				this.setState({error, loaderStatus: false});
			})
		}
		else if(this.state.profile?.role == Roles.AC_INCHARGE.value) {
			getAssemblyConstituenciesForRoles(this.state.includeAssignedJob, pageNumber, this.state.pageSize).then((data) => {
				this.setState({data, loaderStatus: false});
			}).catch((error) => {
				this.setState({error, loaderStatus: false});
			})
		}
		else if(this.state.profile?.role == Roles.HOUSE_INCHARGE.value) {
			getHousesForRoles(selectedConstituency, selectedBlock, selectedVillage, selectedPollingStation, this.state.includeAssignedJob, pageNumber, this.state.pageSize).then((houses) => {
				this.setState({data: houses, loaderStatus: false});
			}).catch((error) => {
				this.setState({error, loaderStatus: false});
			})
		}
	}

	onApplyFilter(selectedConstituency, selectedBlock, selectedVillage, selectedPollingStation, constituencies, blocks, villages, pollingStationList, selectedConstituencyObj) {
		this.setState({loaderStatus: true, data: null});
		if(this.state.profile?.role == Roles.POLLING_STATION_INCHARGE.value) {
			getPollingStationsForRoles(selectedConstituency, selectedBlock, selectedVillage, this.state.includeAssignedJob, this.state.pageNumber, this.state.pageSize).then((data) => {
				this.setState({data, loaderStatus: false});
			}).catch((error) => {
				this.setState({error, loaderStatus: false});
			})
		}
		else if(this.state.profile?.role == Roles.BLOCK_INCHARGE.value) {
			getBlocksForRoles(selectedConstituency, this.state.includeAssignedJob, this.state.pageNumber, this.state.pageSize).then((data) => {
				this.setState({data, loaderStatus: false});
			}).catch((error) => {
				this.setState({error, loaderStatus: false});
			})
		}
		else if(this.state.profile?.role == Roles.AC_INCHARGE.value) {
			getAssemblyConstituenciesForRoles(this.state.includeAssignedJob, this.state.pageNumber, this.state.pageSize).then((data) => {
				this.setState({data, loaderStatus: false});
			}).catch((error) => {
				this.setState({error, loaderStatus: false});
			})
		}
		else if(this.state.profile?.role == Roles.HOUSE_INCHARGE.value) {
			getHousesForRoles(selectedConstituency, selectedBlock, selectedVillage, selectedPollingStation, this.state.includeAssignedJob, this.state.pageNumber, this.state.pageSize).then((houses) => {
				this.setState({data: houses, loaderStatus: false});
			}).catch((error) => {
				this.setState({error, loaderStatus: false});
			})
		}
		this.setState({selectedConstituency, selectedBlock, selectedVillage, selectedPollingStation, constituencies, blocks, villages, pollingStationList, selectedConstituencyObj, includeAssignedJob: this.state.includeAssignedJob});
	}

	includeAssigned(includeAssignedJob) {
		const selectedConstituency = this.state.selectedConstituency;
		const selectedBlock = this.state.selectedBlock;
		const selectedVillage = this.state.selectedVillage;
		const selectedPollingStation = this.state.selectedPollingStation;
		this.setState({data: null, loaderStatus: true});
		if(this.state.profile?.role == Roles.POLLING_STATION_INCHARGE.value) {
			getPollingStationsForRoles(selectedConstituency, selectedBlock, selectedVillage, includeAssignedJob, this.state.pageNumber, this.state.pageSize).then((data) => {
				this.setState({data, loaderStatus: false});
			}).catch((error) => {
				this.setState({error, loaderStatus: false});
			})
		}
		else if(this.state.profile?.role == Roles.BLOCK_INCHARGE.value) {
			getBlocksForRoles(selectedConstituency, includeAssignedJob, this.state.pageNumber, this.state.pageSize).then((data) => {
				this.setState({data, loaderStatus: false});
			}).catch((error) => {
				this.setState({error, loaderStatus: false});
			})
		}
		else if(this.state.profile?.role == Roles.AC_INCHARGE.value) {
			getAssemblyConstituenciesForRoles(includeAssignedJob, this.state.pageNumber, this.state.pageSize).then((data) => {
				this.setState({data, loaderStatus: false});
			}).catch((error) => {
				this.setState({error, loaderStatus: false});
			})
		}
		else if(this.state.profile?.role == Roles.HOUSE_INCHARGE.value) {
			getHousesForRoles(selectedConstituency, selectedBlock, selectedVillage, selectedPollingStation, includeAssignedJob, this.state.pageNumber, this.state.pageSize).then((houses) => {
				this.setState({data: houses, loaderStatus: false});
			}).catch((error) => {
				this.setState({error, loaderStatus: false});
			})
		}
		this.setState({selectedConstituency, selectedBlock, selectedVillage, selectedPollingStation, includeAssignedJob});
	}

	componentDidMount() {
		this.setState({loaderStatus: true});
		let selectedConstituency = 86;
		getStateConstituencies().then((constituencies) => {
			getBlocks(selectedConstituency).then((blocks) => {
				this.setState({blocks, selectedConstituency});
			}).catch((error) => {
				this.setState({error});
			})
			this.setState({constituencies});
		}).catch((error) => {
			this.setState({error});
		})

		const id = this.props.params.id;
		getUserDetails(id).then((profile) => {

			let entities = profile?.entities;
			let entitiesList = {};
			for(let i=0;i<entities?.length;i++) {
				entitiesList = {...entitiesList, [entities[i].id]: entities[i]};
			}
			this.setState({entitiesList});
			if(profile?.role == Roles.POLLING_STATION_INCHARGE.value) {
				getPollingStationsForRoles(selectedConstituency, null, null, null, false, this.state.pageNumber, this.state.pageSize).then((data) => {
					this.setState({data, loaderStatus: false});
				}).catch((error) => {
					this.setState({error, loaderStatus: false});
				})
			}
			else if(profile?.role == Roles.BLOCK_INCHARGE.value) {
				this.setState({loaderStatus: true});
				getBlocksForRoles(selectedConstituency, null, null, false, this.state.pageNumber, this.state.pageSize).then((data) => {
					this.setState({data, loaderStatus: false});
				}).catch((error) => {
					this.setState({error, loaderStatus: false});
				})
			}
			else if(profile?.role == Roles.AC_INCHARGE.value) {
				getAssemblyConstituenciesForRoles(false, this.state.pageNumber, this.state.pageSize).then((data) => {
					this.setState({data, loaderStatus: false});
				}).catch((error) => {
					this.setState({error, loaderStatus: false});
				})
			}
			else if(profile?.role == Roles.HOUSE_INCHARGE.value) {
				getHousesForRoles(selectedConstituency, null, null, null, false, this.state.pageNumber, this.state.pageSize).then((houses) => {
					this.setState({data: houses, loaderStatus: false});
				}).catch((error) => {
					this.setState({error, loaderStatus: false});
				})
			}
			this.setState({profile, id});
		}).catch((error) => {
			this.setState({error, loaderStatus: false});
		})
	}

	render() {
		return(
			<div>
				<div>
					{this.state.profile && (<div>
						<h4>Assign {Roles.get(this.state.profile?.role)?.name} <span className='text-success text-uppercase'>{` - ${this.state.profile?.name}`}</span></h4>
					</div>)}
					<StatusToast delayTime="3000"/>
					{!this.state.error && (<UserAssignFilter onApplyFilter={this.onApplyFilter} selectedConstituency={this.state.selectedConstituency} selectedBlock={this.state.selectedBlock} selectedVillage={this.state.selectedVillage} selectedPollingStation={this.state.selectedPollingStation} constituencies={this.state.constituencies} blocks={this.state.blocks} villages={this.state.villages} pollingStationList={this.state.pollingStationList} selectedConstituencyObj={this.state.selectedConstituencyObj} user={this.state.profile} Roles={Roles} />)}
				</div>
				{!this.state.error && (<div className="card">
					<div className="card-body">
						<EntityAssignmentSelection data={this.state.data} list={this.state.data} profile={this.state.profile} roles={Roles} loaderStatus={this.state.loaderStatus} includeAssigned={this.includeAssigned} onSubmit={this.onSubmit} includeAssignedJob={this.state.includeAssignedJob} onChangePageNumber={this.onChangePageNumber} entitiesList={this.state.entitiesList} />
					</div>
				</div>)}
				{
					this.state.error && <b>{this.state.error.message}</b>
				}
			</div>
		)
	}
}

export default withParams(UserAssignEntity);