import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Dashboard-Style.css';
import DashboardDoughnut from './DashboardDoughnut';
import { getGenderStats, getSurveyStats, getOpinionStats, getAgeStats, getReligionStats, getCasteStats} from '../../services/Voters';

class DashboardChartVoters extends React.Component {
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.state = {
      genderData: null,
      ageData: null,
      surveyData: null,
      opinionData: null,
      religionData: null,
      casteData: null,
      genderDataLoading: true,
      ageDataLoading: true,
      surveyDataLoading: true,
      opinionDataLoading: true,
      religionDataLoading: true,
      casteDataLoading: true,
      genderError: '',
      ageError: '',
      surveyError: '',
      opinionError: '',
      religionError: '',
      casteError: ''
    }
  }

  loadData() {
    this.setState({genderData: [], genderDataLoading: true, surveyData: [], surveyDataLoading: true, opinionData: [], opinionDataLoading: true, 
      ageData: [], ageDataLoading: true, religionData: [], religionDataLoading: true, casteData: [], casteDataLoading: true});
    
    getGenderStats(this.props.selectedConstituency, this.props.selectedBlock, this.props.selectedVillage, this.props.selectedPollingStation).then((chartData) => {
			let labels = chartData.map(item => `${item.name} (${item.count})`);
			let counts = chartData.map(item => item.count);
			let colors = chartData.map(item => item.color);
      let genderData = {
        name: 'Gender',
        labels,
        datasets: [
        {
          label: 'Voters By Gender',
          data: counts,
          backgroundColor: colors,
          borderColor: ['rgba(255, 255, 255, 1)','rgba(255, 255, 255, 1)'],
          borderWidth: 3
        }
        ]
      };
			this.setState({genderData, genderDataLoading: false});
		}).catch((err) => {
			this.setState({genderError: err, genderDataLoading: false});
		});
	
		getOpinionStats(this.props.selectedConstituency, this.props.selectedBlock, this.props.selectedVillage, this.props.selectedPollingStation).then((chartData) => {
      let labels = chartData.map(item => `${item.name} (${item.count})`);
      let counts = chartData.map(item => item.count);
      let colors = chartData.map(item => item.color);
      let opinionData = {
        name: 'Party',
        labels,
        datasets: [
          {
            label: 'Voters Opinion Summary',
            data: counts,
            backgroundColor: colors,
            borderColor: ['rgba(255, 255, 255, 1)','rgba(255, 255, 255, 1)','rgba(255, 255, 255, 1)','rgba(255, 255, 255, 1)','rgba(255, 255, 255, 1)','rgba(255, 255, 255, 1)'],
            borderWidth: 3
          }
        ]
      };
      this.setState({opinionData, opinionDataLoading: false});
		}).catch((err) => {
		this.setState({opinionError: err, opinionDataLoading: false});
		});

		getAgeStats(this.props.selectedConstituency, this.props.selectedBlock, this.props.selectedVillage, this.props.selectedPollingStation).then((chartData) => {
      let labels = chartData.map(item => `${item.name} (${item.count})`);
      let counts = chartData.map(item => item.count);
      let colors = chartData.map(item => item.color);
      let ageData = {
        name: 'Age',
        labels,
        datasets: [
          {
            label: 'Voters By Age Group',
            data: counts,
            backgroundColor: colors,
            borderColor: ['rgba(255, 255, 255, 1)','rgba(255, 255, 255, 1)','rgba(255, 255, 255, 1)','rgba(255, 255, 255, 1)','rgba(255, 255, 255, 1)'],
            borderWidth: 3
          }
        ]
      };
      this.setState({ageData, ageDataLoading: false});
		}).catch((err) => {
		this.setState({ageError: err, ageDataLoading: false});
		});

		getReligionStats(this.props.selectedConstituency, this.props.selectedBlock, this.props.selectedVillage, this.props.selectedPollingStation).then((chartData) => {
      let labels = chartData.map(item => `${item.name} (${item.count})`);
      let counts = chartData.map(item => item.count);
      let colors = chartData.map(item => item.color);
      let religionData = {
        name: 'Religion',
        labels,
        datasets: [
          {
            label: 'Religion Wise Voters',
            data: counts,
            backgroundColor: colors,
            borderColor: ['rgba(255, 255, 255, 1)','rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)'],
            borderWidth: 3
          }
        ]
      };
      this.setState({religionData, religionDataLoading: false});
		}).catch((err) => {
		this.setState({religionError: err, religionDataLoading: false});
		});

		getCasteStats(this.props.selectedConstituency, this.props.selectedBlock, this.props.selectedVillage, this.props.selectedPollingStation).then((chartData) => {
			let labels = chartData.map(item => `${item.name} (${item.count})`);
			let counts = chartData.map(item => item.count);
			let colors = chartData.map(item => item.color);
      let casteData = {
        name: 'Caste',
        labels,
        datasets: [
          {
            label: 'Caste Wise Voters',
            data: counts,
            backgroundColor: colors,
            borderColor: ['rgba(255, 255, 255, 1)','rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)'],
            borderWidth: 1
          }
        ]
      };
			this.setState({casteData, casteDataLoading: false});
		}).catch((err) => {
			this.setState({casteError: err, casteDataLoading: false});
		});

    // getSurveyData(this.state.selectedConstituency, this.state.selectedBlock, this.state.selectedVillage, this.state.selectedPollingStation).then((chartData) => {
    //   let labels = chartData.map(item => `${item.name} (${item.count})`);
    //   let counts = chartData.map(item => item.count);
    //   let colors = chartData.map(item => item.color);
    //   let surveyData = {
    //     name: 'Survey',
    //     labels,
    //     datasets: [
    //       {
    //         label: 'Voters Surveyed',
    //         data: counts,
    //         backgroundColor: colors,
    //         borderColor: ['rgba(255, 255, 255, 1)','rgba(255, 255, 255, 1)'],
    //         borderWidth: 3
    //       }
    //     ]
    //   };
    //   this.setState({surveyData, surveyDataLoading: false});
		// }).catch((err) => {
		// this.setState({surveyError: err, surveyDataLoading: false});
		// });
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.selectedConstituency !== prevProps.selectedConstituency || this.props.selectedBlock !== prevProps.selectedBlock 
      || this.props.selectedVillage !== prevProps.selectedVillage || this.props.selectedPollingStation !== prevProps.selectedPollingStation) {
        this.loadData();
      }
  }

  render() {
    return (
      <div className='row'>
        <DashboardDoughnut data={this.state.genderData} error={this.props.genderError} loading={this.state.genderDataLoading} />
        <DashboardDoughnut data={this.state.ageData} error={this.props.ageError} loading={this.state.ageDataLoading} />
        <DashboardDoughnut data={this.state.religionData} error={this.props.religionError} loading={this.state.religionDataLoading} />
        <DashboardDoughnut data={this.state.casteData} error={this.props.casteError} loading={this.state.casteDataLoading} />
        <DashboardDoughnut data={this.state.opinionData} error={this.props.opinionError} loading={this.state.opinionDataLoading} />
      </div>
    );
  }
}

export default DashboardChartVoters;
