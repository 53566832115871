import React from "react";
import { getOpinion, getNameFromList } from '../../services/Voters';

class UpdateLeaderPerceptionAnalysis extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			leaderPerceptionAnalysis: [],
			opinionList: [],
			err: ''
		}
		this.handleLeader = this.handleLeader.bind(this);
		this.handleParty = this.handleParty.bind(this);
		this.handlePerception = this.handlePerception.bind(this);
		this.handleStrengths = this.handleStrengths.bind(this);
		this.handleWeaknesses = this.handleWeaknesses.bind(this);
	}

	componentDidMount() {
		getOpinion().then((opinionList) => {
			this.setState({opinionList});
		}).catch((err) => {
			this.setState({err});
		})
		this.setState({leaderPerceptionAnalysis: this.props.leaderPerceptionAnalysis});
	}

	handleLeader = (event, id) => {
		this.setState({
			leaderPerceptionAnalysis: this.state.leaderPerceptionAnalysis?.map(item => (item.id == id ? {...item, leader: event.target.value} : item))
		});
	}

	handleParty = (event, id) => {
		const party = {id: event.target.value, name: getNameFromList(this.state.opinionList, event.target.value)};
		this.setState({
			leaderPerceptionAnalysis: this.state.leaderPerceptionAnalysis?.map(item => (item.id == id ? {...item, party} : item))
		});
	}

	handlePerception = (event, id) => {
		this.setState({
			leaderPerceptionAnalysis: this.state.leaderPerceptionAnalysis?.map(item => (item.id == id ? {...item, perception: event.target.value} : item))
		});
	}

	handleStrengths = (event, id) => {
		this.setState({
			leaderPerceptionAnalysis: this.state.leaderPerceptionAnalysis?.map(item => (item.id == id ? {...item, strengths: event.target.value} : item))
		});
	}

	handleWeaknesses = (event, id) => {
		this.setState({
			leaderPerceptionAnalysis: this.state.leaderPerceptionAnalysis?.map(item => (item.id == id ? {...item, weaknesses: event.target.value} : item))
		});
	}

	render() {
		return(
			<div className='overflow-x-auto'>
				<table className='table'>
					<thead>
						<tr className='bg-secondary bg-opacity-25 text-dark'>
							<th className='text-center' colSpan={6}>Leader Perception analysis</th>
						</tr>
						<tr>
							<th className='w-5 text-center'>Sl. No.</th>
							<th>Leader</th>
							<th>Party</th>
							<th>Perception</th>
							<th>Strengths</th>
							<th>Weaknesses</th>
						</tr>
					</thead>
					<tbody>
					{
						this.state.leaderPerceptionAnalysis?.map((leaderPerception, index) => (
							<tr key={leaderPerception.id}>
								<td className='text-center'>{index + 1}</td>
								<td>
									<input type="text" name="leader" id="leader" className="form-control" defaultValue={leaderPerception.leader} onChange={(e) => this.handleLeader(e, leaderPerception.id)} />
								</td>
								<td>
									<select name="party" id="party" className="form-select" onChange={(e) => this.handleParty(e, leaderPerception.id)}>
										{this.state.opinionList?.map(opinion => 
											<option value={opinion.id} key={opinion.id}>{opinion?.name}</option>
										)}
									</select>
								</td>
								<td>
									<input type="text" name="perception" id="perception" className="form-control" defaultValue={leaderPerception.perception} onChange={(e) => this.handlePerception(e, leaderPerception.id)} />
								</td>
								<td>
									<input type="text" name="strengths" id="strengths" className="form-control" defaultValue={leaderPerception.strengths} onChange={(e) => this.handleStrengths(e, leaderPerception.id)} />
								</td>
								<td>
									<input type="text" name="Weaknesses" id="Weaknesses" className="form-control" defaultValue={leaderPerception.weaknesses} onChange={(e) => this.handleWeaknesses(e, leaderPerception.id)} />
								</td>
							</tr>
						))
					}
					</tbody>
				</table>
			</div>
		)
	}
}

export default UpdateLeaderPerceptionAnalysis;