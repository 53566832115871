import { React,useState,useEffect } from 'react';
import Menu  from './Menu';
import CustomLink from './CustomLink';
import 'react-pro-sidebar/dist/css/styles.css';
import {matchRoutes, useLocation } from "react-router-dom";
import {  BrowserRouter as Router,  Routes,  Route} from "react-router-dom";

function OnlyOne(props){

var route = useLocation()?.pathname;

const [menuState ,setMenuState] = useState({});

const [activeRoute, setActiveRoute] = useState('/new-volunteer');

const [isActive, setIsActive] = useState('');


// useEffect( () =>{

// 		props.menu.map((item) =>{

// 			if(item.children){

// 				for(var i = 0; i < item.children.length; i++){

// 					console.log(route,'route');
// 					console.log(item.children[i].url ,'link');

// 					if(route == item.children[i].url){
// 						setActiveMenu(item.name);
// 						return ;
// 					}

// 				}

// 			}
			
// 		});
// },[]);




const toggleActive = (route) => {
	setActiveRoute(route);
}

const toggleMenu = (menu) =>{

}


	function checkIfActive(menu, route) {
		if(menu.children.length == 0)
			return menu.url == route;
		else {
			for(let i=0;i<menu.children.length;i++) {
				let active = checkIfActive(menu.children[i], route);
				if(active)
					return true;
			}
			return false;
		}
	}
	// else{
	// 	menuState[key] = false;
	// }
	// setMenuState(menuState);
	

	// var arr = Object.keys(menuState);

	// console.log(arr,'arr');

	// for(var j = 0; j < arr.length; j++)
	// {
	// 	menuState[arr[j]] = false;
	// 	setMenuState(menuState);
	// }

	// console.log(menuState ,'middel');

	// let lastElement = arr.slice(-1);

	// let myArray = lastElement.toString().split(".");

	// console.log(lastElement ,'lastElement');

	// for(var i = 0; i < myArray.length; i++)
	// {
	// 	menuState[myArray[i]] = true;
	// 	setMenuState(menuState);
	// }

	// console.log(menuState ,'end');

	let elems = [];
	var data = props.menu;
	for(let i=0; i<data.length; i++) {
  		elems.push(<Menu active={checkIfActive(data[i], route)} key={data[i].name} name={data[i].name} title={data[i].name} children={data[i].children} menus={data[i].children} onToggle={toggleActive} url={data[i].url} />);
	}
	return elems;
}
export default OnlyOne;