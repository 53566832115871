import React from "react";

class Step extends React.Component {
    constructor(props) {
        super(props);
        this.handleStep = this.handleStep.bind(this);
    }

    handleStep(event) {
        this.props.onChangeStep(event);
    }

    render() {
        return(
            <div className="d-flex justify-content-center my-3">
                {
                    this.props.steps?.map((step, index) => 
                        <div className={index != 0 ? `step-before d-flex` : `` } key={step.step}>
                            <button className={`rounded-circle border px-2 mx-1 ${step.color == false ? `bg-danger text-white border-dark` : step.color == true ? 'bg-success text-white border-dark' : '' } ${this.props.active == step.step ? `active-step-stepper border-dark` : `` }`} onClick={ () => {this.handleStep(step.step)} }>{step.step}</button>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default Step;