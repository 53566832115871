import React from "react";
import Constituencies from "../Constituencies";
import Blocks from "../Blocks";
import Villages from "../Villages";
import PollingStations from "../PollingStations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";
import { filterFields, getBlocks, getStateConstituencies, getVillages, getPollingStations } from "../../services/Voters";

class HouseFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedConstituency: [],
      selectedBlock: [],
      selectedVillage: [],
      blocks: [],
      block: [],
      village: [],
      villages: [],
      constituencies: [],
      clear: true
    };
    this.onChangeConstituencies = this.onChangeConstituencies.bind(this);
    this.onChangeBlock = this.onChangeBlock.bind(this);
    this.onChangeVillage = this.onChangeVillage.bind(this);
    this.onChangePollingStation = this.onChangePollingStation.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  handleReset() {
    let selectedConstituency = 86;
	getBlocks(selectedConstituency).then((blocks) => {
		this.props.onApplyHouseFilter(this.props.constituencies, blocks, [], [], selectedConstituency, null, null, null, this.props.selectedConstituencyObj);
		this.setState({clear: !this.state.clear});
	  }).catch((error) => {
		this.setState({error});
	  })
  }

  onChangeConstituencies(selectedConstituency, selectedConstituencyObj) {
    getBlocks(selectedConstituency).then((blocks) => {
      this.props.onApplyHouseFilter(this.props.constituencies, blocks, [], [], selectedConstituency, null, null, null, selectedConstituencyObj);
    }).catch((err) => {
      console.log(err);
    })
  }

  onChangeBlock(selectedBlock) {
    getVillages(selectedBlock).then((villages) => {
      this.props.onApplyHouseFilter(this.props.constituencies, this.props.blocks, villages, [], this.props.selectedConstituency, selectedBlock, null, null, this.props.selectedConstituencyObj);
    }).catch((err) => {
      console.log(err);
    })
  }

  onChangeVillage(selectedVillage) {
    getPollingStations(selectedVillage).then((pollingStations) => {
      this.props.onApplyHouseFilter(this.props.constituencies, this.props.blocks, this.props.villages, pollingStations, this.props.selectedConstituency, this.props.selectedBlock, selectedVillage, this.props.selectedPollingStation, this.props.selectedConstituencyObj);
    }).catch((err) => {
      console.log(err);
    })
  }

  onChangePollingStation(selectedPollingStation) {
    this.props.onApplyHouseFilter(this.props.constituencies, this.props.blocks, this.props.villages, this.props.pollingStations, this.props.selectedConstituency, this.props.selectedBlock, this.props.selectedVillage, selectedPollingStation, this.props.selectedConstituencyObj);
  }

  componentDidMount() {
    getStateConstituencies().then((constituencies) => {
      getBlocks(this.props.selectedConstituency).then((blocks) => {
        this.props.onApplyHouseFilter(constituencies, blocks, this.props.villages, this.props.pollingStations, this.props.selectedConstituency, this.props.selectedBlock, this.props.selectedVillage, this.props.selectedPollingStation, this.props.selectedConstituencyObj);
      }).catch((err) => {
        console.log(err);
      })
    }).catch((err) => {
      // alert("Server error, please try again later.");
    });
  }

  render() {
    return(
      <>
        <div className="row">
          <Constituencies clear={this.state.clear} constituencyFields={filterFields} selectedConstituencyObj={this.props.selectedConstituencyObj} onChangeConstituencies={this.onChangeConstituencies} constituencies={this.props.constituencies} selectedConstituency={this.props.selectedConstituency} />
          <Blocks clear={this.state.clear} blockFields={filterFields} blocks={this.props.blocks} selectedConstituency={this.props.selectedConstituency} selectedBlock={this.props.selectedBlock} onChangeBlock={this.onChangeBlock} />
          <Villages villageFields={filterFields} villages={this.props.villages} selectedConstituency={this.props.selectedConstituency} selectedBlock={this.props.selectedBlock} selectedVillage={this.props.selectedVillage} onChangeVillage={this.onChangeVillage} />
          <PollingStations pollingStationFields={filterFields} pollingStations={this.props.pollingStations} selectedConstituency={this.props.selectedConstituency} selectedBlock={this.props.selectedBlock} selectedVillage={this.props.selectedVillage} selectedPollingStation={this.props.selectedPollingStation} onChangePollingStation={this.onChangePollingStation} />
        </div>
        <div className="row">
          <div className="col-md-3 col-lg-3 col-12 my-2">
            <button className='btn btn-danger text-white' onClick={this.handleReset}>Clear</button>
          </div>
        </div>
      </>
    )
  }
}

export default HouseFilter;