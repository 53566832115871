import React from "react";
import { getCasteList, getVillageProfile } from '../../services/Voters';
import { getNameFromList } from '../../services/Voters';

class UpdateCasteAnalysis extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			casteList: [],
			err: '',
			casteAnalysis: []
		}
		this.handleCaste = this.handleCaste.bind(this);
		this.handleVoterPercentage = this.handleVoterPercentage.bind(this);
		this.handleTraditionalInclination = this.handleTraditionalInclination.bind(this);
		this.handlePresentInclination = this.handlePresentInclination.bind(this);
		this.handleTopLeaders = this.handleTopLeaders.bind(this);
	}

	componentDidMount() {
		getCasteList().then((casteList) => {
			this.setState({casteList});
		}).catch((err) => {
			this.setState({err});
		})
		this.setState({casteAnalysis: this.props.villageProfile.casteAnalysis});
	}

	handleCaste = (event, id) => {
		const caste = {id: event.target.value, name: getNameFromList(this.state.casteList, event.target.value)};
		this.setState({
			casteAnalysis: this.state.casteAnalysis?.map(item => (item.id == id ? {...item, caste} : item))
		});
	}

	// handleReligion = () =>{

	// 	const casteAnalysis = this.state.casteAnalysis;
	// 	for(let i=0;i<casteAnalysis.length;i++) {
	// 		if(id == casteAnalysis[i].id) {
	// 			const casteAnalysisObj = [{...casteAnalysis[i], religion: parseInt(event.target.value)}];
	// 			this.setState({casteAnalysis: casteAnalysisObj});
	// 		}
	// 	}

	// }

	handleVoterPercentage = (event, id) => {
		this.setState({
			casteAnalysis: this.state.casteAnalysis?.map(item => (item.id == id ? {...item, voterpercentage: event.target.value} : item))
		});
	}

	handleTraditionalInclination = (event, id) => {
		this.setState({
			casteAnalysis: this.state.casteAnalysis?.map(item => (item.id == id ? {...item, traditionalInclination: event.target.value} : item))
		});
	}

	handlePresentInclination = (event, id) => {
		this.setState({
			casteAnalysis: this.state.casteAnalysis?.map(item => (item.id == id ? {...item, presentInclination: event.target.value} : item))
		});
	}

	handleTopLeaders = (event, id) => {
		this.setState({
			casteAnalysis: this.state.casteAnalysis?.map(item => (item.id == id ? {...item, topLeaders: event.target.value} : item))
		});
	}

	render() {
		return(
			<div className='overflow-x-auto'>
				<table className='table '>
					<thead>
						<tr>
							<th className='text-center bg-secondary bg-opacity-75' colSpan={7}>Caste Analysis</th>
						</tr>
						<tr>
							<th className='w-5 text-center'>Sl. No.</th>
							<th>Religion</th>
            				<th>Caste</th>
							<th>% of voter</th>
							<th>Traditional Inclination</th>
							<th>Present Inclination</th>
							<th>Top Leaders</th>
						</tr>
					</thead>
					<tbody>
						{
							this.state.casteAnalysis?.map((casteAnalysis, index) => (
							<tr key={casteAnalysis.id}>
								<td className='text-center'>{index + 1}</td>
								<td>
            						<select name={`religionName-${index}-${this.state.casteAnalysis.length}`} id={`religionName-${index}-${this.state.casteAnalysis.length}`} ref={this.religion} className='form-select' onChange={this.handleReligion} defaultValue={casteAnalysis.religion.id} >
           								<option value=''>Select Religion</option>
            							{this.props.religionList?.map(religion => <option value={religion.id} key={religion.id}>{religion.name}</option>)}
            						</select>
            						<span className="text-danger fw-600">{this.state.religionError && this.state.religionError}</span>
            					</td>
 		           				<td>
            						<select name={`casteName-${index}-${this.state.casteAnalysis.length}`} id={`casteName-${index}-${this.state.casteAnalysis.length}`} ref={this.casteName} className='form-select' defaultValue={casteAnalysis.caste.id} onChange={this.handleCaste} >
            							<option value=''>Select Caste</option>
	            						{this.props.subCasteList?.map(caste => <option value={caste.id} key={caste.id}>{caste.name}</option>)}
            						</select>
            						<span className="text-danger fw-600">{this.state.casteNameError && this.state.casteNameError}</span>
            					</td>
								{/*<td>
									<select name={`casteName-${index}-${this.state.casteAnalysis.length}`} id={`casteName-${index}-${this.state.casteAnalysis.length}`} className="form-select" defaultValue={casteAnalysis.caste.id} onChange={(e) => this.handleCaste(e, casteAnalysis.id)} >
									{
										this.state.casteList?.map(caste => 
											<option value={caste.id} key={caste.id}>{caste.name}</option>
										)
									}
									</select>
								</td>*/}
								<td>
									<input className="form-control" type="number" name={`voterPercentage-${index}-${this.state.casteAnalysis.length}`} id={`voterPercentage-${index}-${this.state.casteAnalysis.length}`} defaultValue={casteAnalysis.voterpercentage} onBlur={(e) => this.handleVoterPercentage(e, casteAnalysis.id)} />
								</td>
								<td>
									<input className="form-control" type="text" name={`traditionalInclination-${index}-${this.state.casteAnalysis.length}`} id={`traditionalInclination-${index}-${this.state.casteAnalysis.length}`} defaultValue={casteAnalysis.traditionalInclination} onChange={(e) => this.handleTraditionalInclination(e, casteAnalysis.id)} />
								</td>
								<td>
									<input type="text" name={`presentInclination-${index}-${this.state.casteAnalysis.length}`} id={`presentInclination-${index}-${this.state.casteAnalysis.length}`} className="form-control" defaultValue={casteAnalysis.presentInclination} onChange={(e) => this.handlePresentInclination(e, casteAnalysis.id)} />
								</td>
								<td>
									<input type="text" name={`topLeaders-${index}-${this.state.casteAnalysis.length}`} id={`topLeaders-${index}-${this.state.casteAnalysis.length}`} className="form-control" defaultValue={casteAnalysis.topLeaders} onChange={(e) => this.handleTopLeaders(e, casteAnalysis.id)} />
								</td>
							</tr>
							))
						}
					</tbody>
				</table>
			</div>
		)
	}
}

export default UpdateCasteAnalysis;