import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Dashboard-Style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import Error from '../error';
import RotatingLinesLoader from '../RotatingLineLoader';


class DashboardHouseVisitedCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      constituency: this.props.constituency,
      block: this.props.block,
      village: this.props.village,
      error:'',
    }
    this.onChangePage = this.onChangePage.bind(this);
  }

  onChangePage( event ) {
    this.props.onChangeHouseVisitCountPagination( this.state.constituency, this.state.block, this.state.village, event, 10 );
  }

  render() {
    return(
        <div className='col-md-6 col-sm-6 col-12'>
          <div className='card shadow mt-3'>
            <div className='py-2 mx-1'>
              <div className='header-style ps-1 d-inline align-middle'>
                <span>House Visited Count </span>
                <span className='text-primary'>
                <FontAwesomeIcon icon={ faDownload } />
                </span>
              </div>
              <div className='d-inline'>
                <Link to={ `/house-visited-count` } className='btn btn-success float-end me-1 header-style' >View All</Link>
              </div>
            </div>
            <div className='mx-1'>
            { this.props.houseVisitedCountData != null ? 
              <table className="table table-responsive-sm">
                <thead className='table-header-style'>
                  <tr>
                    <td className='table-header-font'>PollingStation No</td>
                    <td className='table-header-font text-start'>Assigned Incharges</td>
                    <td className='table-header-font'>H. No.</td>
                    <td className='table-header-font text-center'>Visited Count</td>
                  </tr>
                </thead>
                <tbody>
                  { this.props.houseVisitedCountData.result && this.props.houseVisitedCountData.result.map( ( visitedCount, index ) => 
                    <tr key={ index }>
                      <td className='normal-font'>{ visitedCount.pollingStationNo }</td>
                      <td className='normal-font text-start'>{ visitedCount.assignedIncharges }</td>
                      <td className='normal-font'>{ visitedCount.houseNo }</td>
                      <td className='normal-font text-center'>{ visitedCount.visitedCount }</td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan={ 4 } className='text-center'>
                      <Pagination pageCount={ this.props.houseVisitedCountData?.count } currentPage={this.props.houseVisitedCountData?.current_page ? this.props.houseVisitedCountData?.current_page - 1 : 1} pageSize={10} onChangePage={ this.onChangePage } />
                    </td>
                  </tr>
                </tbody>
              </table>: this.props.houseVisitedCountLoader != false ? <RotatingLinesLoader /> : this.state.error != '' ? 
              <Error /> :
              <div className='text-center fw-600 fs-6 text-black-50'>NO data found</div>
            }
            </div>
          </div>
        </div>
    );
  }
}

export default DashboardHouseVisitedCount;