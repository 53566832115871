import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { getCasteList, getNameFromList } from '../../services/Voters';

class UpdateNeutralInfluencers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			neutralInfluencers: [],
			casteList: [],
			err: ''
		};
		this.handleName = this.handleName.bind(this);
		this.handlePhoneNumber = this.handlePhoneNumber.bind(this);
		this.handleCaste = this.handleCaste.bind(this);
		this.handleProfession = this.handleProfession.bind(this);
		this.handleLevelOfInfluence = this.handleLevelOfInfluence.bind(this);
	}

	componentDidMount() {
		getCasteList().then((casteList) => {
			this.setState({casteList});
		}).catch((err) => {
			this.setState({err});
		})
		this.setState({neutralInfluencers: this.props.neutralInfluencers});
	}

	handleName = (event, id) => {
		this.setState({
			neutralInfluencers: this.state.neutralInfluencers?.map(item => (item.id == id ? {...item, name: event.target.value} : item))
		});
	}

	handlePhoneNumber = (event, id) => {
		this.setState({
			neutralInfluencers: this.state.neutralInfluencers?.map(item => (item.id == id ? {...item, phoneNumber: event.target.value} : item))
		});
	}

	handleCaste = (event, id) => {
		const caste = {id: event.target.value, name: getNameFromList(this.state.casteList, event.target.value)};
		this.setState({
			neutralInfluencers: this.state.neutralInfluencers?.map(item => (item.id == id ? {...item, caste} : item))
		});
	}

	handleProfession = (event, id) => {
		this.setState({
			neutralInfluencers: this.state.neutralInfluencers?.map(item => (item.id == id ? {...item, profession: event.target.value} : item))
		});
	}

	handleLevelOfInfluence = (event, id) => {
		this.setState({
			neutralInfluencers: this.state.neutralInfluencers?.map(item => (item.id == id ? {...item, levelOfInfluence: event.target.value} : item))
		});
	}

	render() {
		return(
			<div className='overflow-x-auto'>
				<table className="table">
					<thead>
						<tr className="bg-secondary bg-opacity-75">
							<th className='text-center' colSpan={6}>Neutral Influencers</th>
						</tr>
						<tr>
							<th className='w-5 text-center'>Sl. No.</th>
							<th>Name</th>
							<th>Phone number</th>
							<th>Caste</th>
							<th>Profession</th>
							<th>Level of Influence</th>
						</tr>
					</thead>
					<tbody>
						{
							this.state.neutralInfluencers?.map((neutralInfluencers, index) => (
								<tr key={neutralInfluencers.id}>
									<td className='text-center'>{index + 1}</td>
									<td>
										<input type="text" name="name" id="name" className="form-control" defaultValue={neutralInfluencers.name} onChange={(e) => this.handleName(e, neutralInfluencers.id)} />
									</td>
									<td>
										<input type="number" name="phoneNumber" id="phoneNumber" className="form-control" defaultValue={neutralInfluencers.phoneNumber} maxLength={10} minLength={10} onChange={(e) => this.handlePhoneNumber(e, neutralInfluencers.id)} />
									</td>
									<td>
										<select name="caste" id="caste" className="form-select" defaultValue={neutralInfluencers.caste.name} onChange={(e) => this.handleCaste(e, neutralInfluencers.id)} >
											{this.state.casteList?.map(caste => 
												<option value={caste.id} key={caste.id} >{caste.name}</option>
											)}
										</select>
									</td>
									<td>
										<input type="text" name="profession" id="profession" className="form-control" defaultValue={neutralInfluencers.profession} onChange={(e) => this.handleProfession(e, neutralInfluencers.id)} />
									</td>
									<td>
										<input type="text" name="levelOfInfluence" id="levelOfInfluence" className="form-control" defaultValue={neutralInfluencers.levelOfInfluence} onChange={(e) => this.handleLevelOfInfluence(e, neutralInfluencers.id)} />
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		)
	}
}

export default UpdateNeutralInfluencers;