import React from 'react';
import { getVillageProfile } from '../../services/Voters';
import { Link } from 'react-router-dom';
import withParams from '../GetParams';


class VillageProfileView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			villageProfile: [],
			villageProfileError: [],
			id: ''
		}
		this.handleRefresh = this.handleRefresh.bind(this);
	}

	componentDidMount() {
		let { id } = this.props.params;
		getVillageProfile(id).then((villageProfile) => {
			this.setState({villageProfile, id});
		}).catch((villageProfileError) => {
			this.setState({villageProfileError});
		})
	}

	handleRefresh() {
		getVillageProfile(this.state.id).then((villageProfile) => {
			this.setState({villageProfile});
		}).catch((villageProfileError) => {
			this.setState({villageProfileError});
		})
	}

	render() {
		const villageProfile = this.state.villageProfile;
		return (
					<div className="card">
						<div className="card-body">
							<div className='text-center fw-700 fs-4'>Village Profile</div>
							<div className='overflow-x-auto'>
								<table className="table">
									<thead>
										<tr>
											<th className='text-center bg-secondary bg-opacity-75' colSpan={2}>Demographics</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Village</td>
											<td>{villageProfile.demographics?.village.name}</td>
										</tr>
										<tr>
											<td>Block</td>
											<td>{villageProfile.demographics?.block.name}</td>
										</tr>
										<tr>
											<td>Population</td>
											<td>{villageProfile.demographics?.population}</td>
										</tr>
										<tr>
											<td>Voter</td>
											<td>{villageProfile.demographics?.voters}</td>
										</tr>
										<tr>
											<td>Category</td>
											<td>{villageProfile.demographics?.category.name}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className='overflow-x-auto'>
								<table className='table '>
									<thead>
										<tr>
											<th className='text-center bg-secondary bg-opacity-75' colSpan={6}>Caste Analysis</th>
										</tr>
										<tr>
											<th className='w-5 text-center'>Sl. No.</th>
											<th>Caste Name</th>
											<th>% of voter</th>
											<th>Traditional Inclination</th>
											<th>Present Inclination</th>
											<th>Top Leaders</th>
										</tr>
									</thead>
									<tbody>
										{
											villageProfile.casteAnalysis?.map((casteAnalysis, index) => (
											<tr key={casteAnalysis.id}>
												<td className='text-center'>{index + 1}</td>
												<td>{casteAnalysis.caste.name}</td>
												<td>{casteAnalysis.voterpercentage}</td>
												<td>{casteAnalysis.traditionalInclination}</td>
												<td>{casteAnalysis.presentInclination}</td>
												<td>{casteAnalysis.topLeaders}</td>
											</tr>
											))
										}
									</tbody>
								</table>
							</div>
							<div className='overflow-x-auto'>
								<table className='table'>
									<thead>
										<tr>
											<th className='text-center bg-secondary bg-opacity-75' colSpan={2}>Perception Analysis</th>
										</tr>
										<tr className='bg-secondary bg-opacity-25 text-dark'>
											<th className='w-5 text-center'>Sl. No.</th>
											<th className='text-center'>Traditional Voting Trend in the village</th>
										</tr>
									</thead>
									<tbody>
									{
										villageProfile.perceptionAnalysis?.traditionalVotingTrend.map((traditionalVoting, index) => (
											<tr key={traditionalVoting.id}>
												<td className='text-center'>{index + 1}</td>
												<td>{traditionalVoting.traditionalVotingTrend}</td>
											</tr>
										))
									}
									</tbody>
								</table>
							</div>
							<div className='overflow-x-auto'>
								<table className="table">
									<thead>
										<tr className='bg-secondary bg-opacity-25 text-dark'>
											<th className='w-5 text-center'>Sl. No.</th>
											<th className="text-center">Present Voting Trend in the village</th>
										</tr>
									</thead>
									<tbody>
										{
											villageProfile.perceptionAnalysis?.presentVotingTrend.map((presentVoting, index) => (
												<tr key={presentVoting.id}>
													<td className='text-center'>{index + 1}</td>
													<td>{presentVoting.presentVotingTrend}</td>
												</tr>
											))
										}
									</tbody>
								</table>
							</div>
							<div className='overflow-x-auto'>
								<table className="table">
									<thead>
										<tr className="bg-secondary bg-opacity-25 text-dark">
											<th className='text-center' colSpan={5}>Party perception analysis</th>
										</tr>
										<tr>
											<th className='w-5 text-center'>Sl. No.</th>
											<th>Party</th>
											<th>Perception</th>
											<th>Strengths</th>
											<th>Weaknesses</th>
										</tr>
									</thead>
									<tbody>
									{
										villageProfile.perceptionAnalysis?.partyPerceptionAnalysis.map((partyPerceptionAnalysis, index) => (
											<tr key={partyPerceptionAnalysis.id}>
												<td className='text-center'>{index + 1}</td>
												<td>{partyPerceptionAnalysis.party.name}</td>
												<td>{partyPerceptionAnalysis.perception}</td>
												<td>{partyPerceptionAnalysis.strengths}</td>
												<td></td>
											</tr>
										))
									}
									</tbody>
								</table>
							</div>
							<div className='overflow-x-auto'>
								<table className='table'>
									<thead>
										<tr className='bg-secondary bg-opacity-25 text-dark'>
											<th className='text-center' colSpan={6}>Leader Perception analysis</th>
										</tr>
										<tr>
											<th className='w-5 text-center'>Sl. No.</th>
											<th>Leader</th>
											<th>Party</th>
											<th>Perception</th>
											<th>Strengths</th>
											<th>Weaknesses</th>
										</tr>
									</thead>
									<tbody>
									{
										villageProfile.perceptionAnalysis?.leaderPerceptionAnalysis.map((leaderPerception, index) => (
											<tr key={leaderPerception.id}>
												<td className='text-center'>{index + 1}</td>
												<td>{leaderPerception.leader}</td>
												<td>{leaderPerception.party.name}</td>
												<td>{leaderPerception.perception}</td>
												<td>{leaderPerception.strengths}</td>
												<td>{leaderPerception.weaknesses}</td>
											</tr>
										))
									}
									</tbody>
								</table>
							</div>
							<div className='overflow-x-auto'>
								<table className="table">
									<thead>
										<tr className="bg-secondary bg-opacity-75">
											<th className='text-center' colSpan={5}>Issues - Actionables</th>
										</tr>
										<tr>
											<th className='w-5 text-center'>Sl. No.</th>
											<th>Issue</th>
											<th>Affected population</th>
											<th>Communities affected</th>
											<th>Suggested Actionable</th>
										</tr>
									</thead>
									<tbody>
										{
											villageProfile.issuesActionables?.map((issuesActionable, index) => (
												<tr key={issuesActionable.id}>
													<td className='text-center'>{index + 1}</td>
													<td>{issuesActionable.issue}</td>
													<td>{issuesActionable.affectedPopulation}</td>
													<td>{issuesActionable.communitiesAffected}</td>
													<td>{issuesActionable.suggestedActionable}</td>
												</tr>
											))
										}
									</tbody>
								</table>
							</div>
							<div className='overflow-x-auto'>
								<table className='table'>
									<thead>
										<tr className='bg-secondary bg-opacity-75'>
											<th className='text-center' colSpan={5}>Leaders who can be poached</th>
										</tr>
										<tr className='bg-secondary bg-opacity-25 text-dark'>
											<th className='text-center' colSpan={5}>Inactive / Dissatisfied Influential leaders</th>
										</tr>
										<tr>
											<th className='w-5 text-center'>Sl. No.</th>
											<th>Leader name</th>
											<th>Party</th>
											<th>Caste</th>
											<th>Reason for Inactiveness / dissatisfaction</th>
										</tr>
									</thead>
									<tbody>
										{
											villageProfile.poachableLeaders?.map((poachableLeader, index) => (
												<tr key={poachableLeader.id}>
													<td className='text-center'>{index + 1}</td>
													<td>{poachableLeader.leaderName}</td>
													<td>{poachableLeader.party.name}</td>
													<td>{poachableLeader.caste.name}</td>
													<td>{poachableLeader.reason}</td>
												</tr>
											))
										}
									</tbody>
								</table>
							</div>
							<div className='overflow-x-auto'>
								<table className="table">
									<thead>
										<tr className="bg-secondary bg-opacity-75">
											<th className='text-center' colSpan={6}>Neutral Influencers</th>
										</tr>
										<tr>
											<th className='w-5 text-center'>Sl. No.</th>
											<th>Name</th>
											<th>Phone number</th>
											<th>Caste</th>
											<th>Profession</th>
											<th>Level of Influence</th>
										</tr>
									</thead>
									<tbody>
										{
											villageProfile.neutralInfluencers?.map((neutralInfluencers, index) => (
												<tr key={neutralInfluencers.id}>
													<td className='text-center'>{index + 1}</td>
													<td>{neutralInfluencers.name}</td>
													<td>{neutralInfluencers.phoneNumber}</td>
													<td>{neutralInfluencers.caste.name}</td>
													<td>{neutralInfluencers.profession}</td>
													<td>{neutralInfluencers.levelOfInfluence}</td>
												</tr>
											))
										}
									</tbody>
								</table>
							</div>
							<div className='float-right'>
								<button type="reset" className='btn btn-info text-white me-2' onClick={this.handleRefresh}>Refresh</button>
								<Link to={`/village-profile-update/${this.state.id}`} className='btn btn-success'>Update</Link>
							</div>
						</div>
					</div>
		)
	}
}

export default withParams(VillageProfileView);