import React from "react";

class UpdateIssuesActionables extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			issuesActionables: []
		}
		this.handleIssue = this.handleIssue.bind(this);
		this.handleAffectedPopulation = this.handleAffectedPopulation.bind(this);
		this.handleCommunitiesAffected = this.handleCommunitiesAffected.bind(this);
		this.handleSuggestedActionable = this.handleSuggestedActionable.bind(this);
	}

	componentDidMount() {
		this.setState({issuesActionables: this.props.issuesActionables});
	}

	handleIssue = (event, id) => {
		this.setState({
			issuesActionables: this.state.issuesActionables?.map(item => (item.id == id ? {...item, issue: event.target.value} : item))
		});
	}

	handleAffectedPopulation = (event, id) => {
		this.setState({
			issuesActionables: this.state.issuesActionables?.map(item => (item.id == id ? {...item, affectedPopulation: event.target.value} : item))
		});
	}

	handleCommunitiesAffected = (event, id) => {
		this.setState({
			issuesActionables: this.state.issuesActionables?.map(item => (item.id == id ? {...item, communitiesAffected: event.target.value} : item))
		});
	}

	handleSuggestedActionable = (event, id) => {
		this.setState({
			issuesActionables: this.state.issuesActionables?.map(item => (item.id == id ? {...item, suggestedActionable: event.target.value} : item))
		});
	}

	render() {
		return(
			<div className='overflow-x-auto'>
				<table className="table">
					<thead>
						<tr className="bg-secondary bg-opacity-75">
							<th className='text-center' colSpan={5}>Issues - Actionables</th>
						</tr>
						<tr>
							<th className='w-5 text-center'>Sl. No.</th>
							<th>Issue</th>
							<th>Affected population</th>
							<th>Communities affected</th>
							<th>Suggested Actionable</th>
						</tr>
					</thead>
					<tbody>
						{
							this.state.issuesActionables?.map((issuesActionable, index) => (
								<tr key={issuesActionable.id}>
									<td className='text-center'>{index + 1}</td>
									<td>
										<input type="text" name="issue" id="issue" className="form-control" defaultValue={issuesActionable.issue} onChange={(e) => this.handleIssue(e, issuesActionable.id)} />
									</td>
									<td>
										<input type="text" name="affectedPopulation" id="affectedPopulation" className="form-control" defaultValue={issuesActionable.affectedPopulation} onChange={(e) => this.handleAffectedPopulation(e, issuesActionable.id)} />
									</td>
									<td>
										<input type="text" name="communitiesAffected" id="communitiesAffected" className="form-control" defaultValue={issuesActionable.communitiesAffected} onChange={(e) => this.handleCommunitiesAffected(e, issuesActionable.id)} />
									</td>
									<td>
										<input type="text" name="suggestedActionable" id="suggestedActionable" className="form-control" defaultValue={issuesActionable.suggestedActionable} onChange={(e) => this.handleSuggestedActionable(e, issuesActionable.id)} />
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		)
	}
}

export default UpdateIssuesActionables;