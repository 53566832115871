import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Voters.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import VoterSummaryTable from './VoterSummaryTable';

class VoterSummary extends React.Component {
  render() {
	return(
		<div className='text-start px-3 pt-3'>
			<div className='p-2 header-font fw-600'>
			  Voter Summary
			</div>
			<div className='card shadow p-2'>
				<div className='row'>
					<div className='col-md-6 align-self-center'>
					<div className='text-start header-font fw-600'>Assembly Name</div>
					</div>
					<div className='col-md-6'>
					<div className='text-end normal-font fw-600'> 0 pollingStations. <span className='text-primary'><FontAwesomeIcon icon={faDownload} /></span></div>
					</div>
				</div>
				<VoterSummaryTable />
			</div>
		</div>
	);
  }
}

export default VoterSummary;