import React, { createRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { addVoterSurvey } from '../../services/Voters';
import withParams from "../GetParams";
import {Operations} from '../toast/operations';

const CHAR_CODE_A = 97;

class VoterSurvey extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			voterSurveyQuestionList: [],
			surveyQuestionOptionList: [],
			name: '',
			postData: '',
			nameError: '',
			questionError : '',
			answerError : ''
		}
		this.handleSurveyQuestions = this.handleSurveyQuestions.bind(this);
		this.handleOptions = this.handleOptions.bind(this);
		this.handleName = this.handleName.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.surveyQuestion = React.createRef();
		this.surveyQuestionOption = React.createRef();
		this.name = React.createRef();
		this.handlePwdKeyUp = this.handlePwdKeyUp.bind(this);
	}

	handlePwdKeyUp(event){
		if (event.key === 'Enter') {
			event.preventDefault();
			this.handleOptions();
		}
	}

	handleSurveyQuestions() {
		this.setState({nameError:''});
		this.setState({questionError:''});
		this.setState({answerError:''});

		if(this.state.name == ''){
			this.setState({nameError: 'Please add Name'});
		}
		else if(this.surveyQuestion.current.value == '') {
			this.setState({questionError: 'Please add Question.'});
		}
		else if(this.state.surveyQuestionOptionList.length == 0) {
			this.setState({answerError: 'Please Write Something'});
		}
		else {
			let surveyQuestion = this.surveyQuestion.current.value;
			let voterSurveyQuestionListObj = {'text': surveyQuestion, 'options': this.state.surveyQuestionOptionList};
			let voterSurveyQuestionList = this.state.voterSurveyQuestionList;
			voterSurveyQuestionList = [...voterSurveyQuestionList, voterSurveyQuestionListObj];
			this.surveyQuestion.current.value = '';
			this.surveyQuestionOption.current.value = '';
			this.setState({voterSurveyQuestionList, surveyQuestionOptionList: []});
		}
	}

	handleOptions() {
		this.setState({answerError:''});
		if(this.surveyQuestionOption.current.value == '') {
			this.setState({answerError: 'Please Write Something'});
		}
		else {
			let surveyQuestionOption = this.surveyQuestionOption.current.value;
			let surveyQuestionOptionList = this.state.surveyQuestionOptionList;
			let surveyQuestionOptionObj = {'text': surveyQuestionOption};
			surveyQuestionOptionList = [...surveyQuestionOptionList, surveyQuestionOptionObj];
			this.surveyQuestionOption.current.value = '';
			this.setState({surveyQuestionOptionList});
		}
	}

	handleRemoveOptions(event) {
		let surveyQuestionOptionList = this.state.surveyQuestionOptionList;
		let newSurveyQuestionOptionList = [];
		for(let i=0;i<surveyQuestionOptionList.length;i++) {
			if(i !== event.index) {
				newSurveyQuestionOptionList = [ ...newSurveyQuestionOptionList, surveyQuestionOptionList[i] ];
			}
		}
		this.setState({surveyQuestionOptionList: newSurveyQuestionOptionList});
	}

	handleRemoveQuestion(event) {
		let voterSurveyQuestionList = this.state.voterSurveyQuestionList;
		let newVoterSurveyQuestionList = [];
		for(let i=0;i<voterSurveyQuestionList.length;i++) {
			if(i !== event.index) {
				newVoterSurveyQuestionList = [ ...newVoterSurveyQuestionList, voterSurveyQuestionList[i] ];
			}
		}
		this.setState({voterSurveyQuestionList: newVoterSurveyQuestionList});
	}

	handleName() {
		let name = this.name.current.value;
		this.setState({name});
	}

	handleSubmit() {
			let name = this.name.current.value;
			let questions = this.state.voterSurveyQuestionList;
			let voterSurvey = {'name': name, 'questions': questions}
			addVoterSurvey(voterSurvey).then((postData) => {
				this.props.navigate(`/voter-survey-list?op=${Operations.ADD_SURVEY}&status=success`);
			}).catch((err) => {
				this.props.navigate(`/voter-survey-list?op=${Operations.ADD_SURVEY}&status=error`);
			})
	}

	render() {
		return(
			<>
				<div className="row">
					<div className="col-md-10">
						<div className="card">
							<div className="card-body">
								<div className="px-2 py-2">
									<div className="text-center fs-5 fw-700 pb-3">Voter Survey</div>
										<div className="row">
											<div className="col-md-6">
												<label htmlFor="">Name</label>
												<input type="text" name="name" id="name" className="form-control" placeholder="Name" defaultValue={this.state.name} onChange={this.handleName} ref={ this.name } />
												<span className="text-danger fw-600">{this.state.nameError && this.state.nameError}</span>
											</div>
										</div>
										<hr />
										<div className="row">
											<div className="col-12">
												<label htmlFor="">Add Question</label>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<textarea name="surveyQuestion" id="surveyQuestion" cols="10" rows="7" className="form-control" ref={ this.surveyQuestion }></textarea>
												<span className="text-danger fw-600">{this.state.questionError && this.state.questionError}</span>
												</div>
											</div>
											<div className="col-md-6">
												<div className="d-flex flex-column">
													<div className="form-group d-flex flex-row">
														<input type="text" name="surveyQuestionOption" id="surveyQuestionOption" className="form-control" ref={ this.surveyQuestionOption } onKeyUp={this.handlePwdKeyUp} />
														<div className="btn btn-success w-25 mx-2" onClick={this.handleOptions}>+</div>
													</div>
														<span className="text-danger fw-600">{this.state.answerError && this.state.answerError}</span>
												{this.state.surveyQuestionOptionList?.map((options, index) => 
													<div className="d-flex flex-row my-1" key={index}>
														<div className="align-self-center fw-500 pe-1">{String.fromCharCode(CHAR_CODE_A + index)}. </div>
														<div className="input-group">
															<input type="text" name={`surveyQuestionOption-${index}`} id={`surveyQuestionOption-${index}-${this.state.surveyQuestionOptionList.length}`} className="form-control" key={`surveyQuestionOption-${index}-${this.state.surveyQuestionOptionList.length}`} defaultValue={options.text} readOnly/>
															<div className="input-group-btn">
																<div className="btn btn-default text-danger border border-danger" onClick={() => this.handleRemoveOptions({index})}><FontAwesomeIcon icon={faRemove} /></div>
															</div>
														</div>
													</div>
												)}
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12 text-end">
												<div className="form-group">
													<div className='btn btn-success align-items-center' onClick={this.handleSurveyQuestions}>Add Question</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{this.state.voterSurveyQuestionList != '' ? 
					<div className="card">
						<div className="card-body">
							<table className="table">
								<thead>
									<tr>
										<th className="w-5 text-center">Sl. No.</th>
										<th colSpan={2}>Question</th>
									</tr>
								</thead>
								<tbody>
									{this.state.voterSurveyQuestionList?.map((question, index) => 
										<tr key={index}>
											<td className="text-center">{index + 1}</td>
											<td>
												<span className="fw-500">{question.text}</span><br />
												{question.options?.map((options, index) => 
													<div key={index}>
														<div>{String.fromCharCode(CHAR_CODE_A + index)}. {options.text}</div>
													</div>
												)}
											</td>
											<td className="w-5"><FontAwesomeIcon icon={faRemove} className='btn btn-danger' onClick={() => this.handleRemoveQuestion({index})} /></td>
										</tr>
									)}
								</tbody>
							</table>
							<div className="row">
								<div className="col-12 text-center">
									<button type="submit" className="btn btn-success" onClick={this.handleSubmit}>Add Voter Survey</button>
								</div>
							</div>
						</div>
					</div> : ''}
				</>
		)
	}
}

export default withParams(VoterSurvey);