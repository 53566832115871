import React from "react";
import Step from './Step';

class Stepper extends React.Component {
    constructor(props) {
        super(props);
        this.onChangeStep = this.onChangeStep.bind(this);
    }

    onChangeStep(step) {
        this.props.onChangeStepperStep(step);
    }
    
    render() {
        return(
            <>
                <Step onChangeStep={this.onChangeStep} steps={this.props.steps} active={this.props.active} />
            </>
        )
    }
}

export default Stepper;