import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Dashboard-Style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Pagination from '../Pagination';
import { RotatingLines } from  'react-loader-spinner';
import Error from '../error';

class DashboardVolunteerComments extends React.Component {
  constructor( props ) {
    super( props );
    this.state = {
      constituency: this.props.constituency,
      block: this.props.block,
      village: this.props.village,
      error:'',
    };
    this.onChangePage = this.onChangePage.bind( this );
  }

  onChangePage( event ) {
    this.props.onChangeVolunteerCommentsPagination( this.state.constituency, this.state.block, this.state.village, event );
  }

  render() {
    return(
        <div className='col-md-6 col-sm-6 col-12'>
          <div className='card shadow mt-3'>
            <div className='py-2 mx-1'>
              <div className='ps-1 header-style d-inline'>
                <span>Comments of Volunteer </span>
                <span className='text-primary'>
                <FontAwesomeIcon icon={ faDownload } />
                </span>
              </div>
              <div className='d-inline'>
                <Link to={ `/comments-of-volunteer` } className='btn btn-success me-1 float-end header-style'>View All</Link>
              </div>
            </div>
            <div className='mx-1'>
            { this.props.commentsOfVolunteerData != '' ? 
            <table className="table table-responsive-sm">
              <thead className='table-header-style'>
                <tr>
                  <td className='table-header-font'>Date</td>
                  <td className='table-header-font'>Village</td>
                  <td className='table-header-font'>Volunteer</td>
                  <td className='table-header-font'>Comment</td>
                </tr>
              </thead>
              <tbody>
                {
                  this.props.commentsOfVolunteerData.result && this.props.commentsOfVolunteerData.result.map(( comments, index ) => 
                    <tr key={ index }>
                    <td className='normal-font'>{ comments.date }</td>
                    <td className='normal-font'>{ comments.village }</td>
                    <td className='normal-font'>{ comments.volunteer }</td>
                    <td className='normal-font'>{ comments.comment }</td>
                  </tr>
                    )
                }
                <tr>
                  <td colSpan={ 4 } className='text-center align-middle' >
                    <Pagination pageCount={ this.props.commentsOfVolunteerData.count } onChangePage={ this.onChangePage } />
                  </td>
                </tr>
              </tbody>
            </table> : this.state.error != '' ? 
              <Error /> :
              <div className='data-loader text-center'>
                <RotatingLines strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="96"
                          visible={true} />
              </div>
            }
            </div>
          </div>
        </div>
    );
  }
}

export default DashboardVolunteerComments;