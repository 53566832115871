import React from "react";
import ElectionFilter from './ElectionFilter';
import VolunteerTable from './VolunteerTable';
import { Link } from "react-router-dom";
import { getUsers, getUserRoles, getStateConstituencies, getBlocks } from '../../services/Voters';
import { CONSTITUENCY } from '../../config/defaults';


class Elections extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: null,
      userRoles: null,
      selectedRole: null,
      constituencies: null,
      blocks: null,
      villages: null,
      pollingStations: null,
      selectedConstituency: null,
      selectedBlock: null,
      selectedVillage: null,
      selectedPolligStation: null,
    };
    this.onApplyFilter = this.onApplyFilter.bind(this);
  }

  onApplyFilter(selectedRole, selectedConstituency, selectedBlock, selectedVillage, selectedPolligStation, constituencies, blocks, villages, pollingStations) {
    getUsers(selectedConstituency, selectedBlock, selectedVillage, selectedPolligStation, selectedRole).then((users) => {
      this.setState({ users, userLoader: false });
    }).catch((error) => {
      this.setState({userLoader: false, error});
    })
    this.setState({selectedRole, selectedConstituency, selectedBlock, selectedVillage, selectedPolligStation, constituencies, blocks, villages, pollingStations});
  }

  componentDidMount() {
    this.setState({userLoader: true});
    getUsers(CONSTITUENCY?.id, null, null, null).then((users) => {
      this.setState({ users, userLoader: false });
    }).catch((error) => {
      this.setState({userLoader: false, error});
    })

    getUserRoles().then((userRoles) => {
      this.setState({userRoles});
    }).catch((error) => {
      this.setState({error});
    })

    getStateConstituencies().then((constituencies) => {
      let selectedConstituency = CONSTITUENCY?.id;
      getBlocks(selectedConstituency).then((blocks) => {
        this.setState({selectedConstituency, blocks, constituencies});
      }).catch((err) => {
        console.log(err);
      })

    }).catch((err) => {
      // alert("Server error, please try again later.");
    });

  }

  render() {
    return(
      <>
          <div className="row">
            <div className="col-md-6">
              <h3>Elections</h3>
            </div>
            <div className="col-md-6">
              <Link to={ `/new-volunteer` } className="btn btn-success float-right">Add User</Link>
            </div>
          </div>
          <ElectionFilter userRoles={this.state.userRoles} onApplyFilter={this.onApplyFilter} selectedRole={this.state.selectedRole} selectedConstituency={this.state.selectedConstituency} selectedBlock={this.state.selectedBlock} selectedVillage={this.state.selectedVillage} selectedPolligStation={this.state.selectedPolligStation} blocks={this.state.blocks} constituencies={this.state.constituencies} pollingStations={this.state.pollingStations} />
          <VolunteerTable users={ this.state.users } userLoader={this.state.userLoader} userRoles={this.state.userRoles} />
        </>
    )
  }
}

export default Elections;