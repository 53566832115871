import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Voters.css';
import { faFilterCircleXmark, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VoterFilters from '../../components/voter/VoterFilter';
import VoterTable from './VoterTable';
import { getVoterDetails, getStateConstituencies, getBlocks } from '../../services/Voters';
import withParams from '../GetParams';
import {getToastConfig, getToast} from '../toast/toast';
import Constituencies from 'components/Constituencies';
import Blocks from 'components/Blocks';

class Voters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterShowing: false,
      voterTableDetails: [],
      voterDefault: true,
      voterDetailserr: [],
      toastConfig: getToastConfig(this.props, true),
      pageNumber: 1,
      pageSize: 50,
      constituencies: [],
      blocks: [],
      villages: [],
      pollingStations: [],
      selectedConstituency: 86,
      selectedBlock: null,
      selectedVillage: null,
      selectedPollingStation: null,
      selectedReligion: null,
      selectedCaste: null,
      selectedSubCaste: null,
      selectedOpinion: null,
      selectedGender: null,
      minAge: 18,
      maxAge: 120,
      governmentScheme: false,
      influentialVoter: false,
      subCasteList: [],
      err: '',
      filterData: '',
      filterInput:'',
      initialPage:'1',
      voterTableLoaderStatus: false,
      surveyCompleted: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.onApplyFilter = this.onApplyFilter.bind(this);
    this.onChangePagenation = this.onChangePagenation.bind(this);
    this.onChangePageSize = this.onChangePageSize.bind(this);
    this.onApplyConstituency = this.onApplyConstituency.bind(this);
    this.onApplyBlock = this.onApplyBlock.bind(this);
    this.onApplyVillage = this.onApplyVillage.bind(this);
    this.onApplyPollingStation =this.onApplyPollingStation.bind(this);
    this.onApplyReligion = this.onApplyReligion.bind(this);
    this.onApplyCaste = this.onApplyCaste.bind(this);
    this.onApplySubCaste = this.onApplySubCaste.bind(this);
    this.onApplyGender = this.onApplyGender.bind(this);
    this.onApplyOpinion = this.onApplyOpinion.bind(this);
    this.onApplyMinAge = this.onApplyMinAge.bind(this);
    this.onApplyMaxAge = this.onApplyMaxAge.bind(this);
    this.onApplyGovernmentScheme = this.onApplyGovernmentScheme.bind(this);
    this.onApplyInfluentialVoter = this.onApplyInfluentialVoter.bind(this);
    this.onApplySurveyCompleted = this.onApplySurveyCompleted.bind(this);
    this.onChangePageCounter = this.onChangePageCounter.bind(this);
  }

  handleChange() {
    this.setState({filterShowing: !this.state.filterShowing});
  }

  onChangePageCounter(pageCount){
    this.setState({ initialPage : pageCount});
    this.setState({ voterTableDetails: [], voterTableLoaderStatus: true});
    getVoterDetails(pageCount, this.state.pageSize).then((voterTableDetails) => {
      this.setState({ voterTableDetails, pageNumber: pageCount, voterTableLoaderStatus: false});
    }).catch((voterDetailserr) => {
      this.setState({voterDetailserr});
    });
  }

  onApplyFilter(filterData, villages, pollingStations) {
    this.setState({voterTableDetails: [], voterTableLoaderStatus: true});
    getVoterDetails(this.state.pageNumber, this.state.pageSize, filterData).then((voterTableDetails) => {
      this.setState({voterTableDetails, filterData, villages, pollingStations, voterTableLoaderStatus: false});
    }).catch((err) => {
      this.setState({err});
    });
  }

  onApplyConstituency(blocks, selectedConstituency) {
    this.setState({blocks, selectedConstituency});
  }

  onApplyBlock(selectedBlock, villages) {
    this.setState({selectedBlock, villages});
  }

  onApplyVillage(selectedVillage, pollingStations) {
    this.setState({selectedVillage, pollingStations});
  }

  onApplyPollingStation(selectedPollingStation) {
    this.setState({selectedPollingStation});
  }

  onApplyReligion(selectedReligion) {
    this.setState({selectedReligion});
  }

  onApplyCaste(selectedCaste, subCasteList) {
    this.setState({selectedCaste, subCasteList});
  }

  onApplySubCaste(selectedSubCaste) {
    this.setState({selectedSubCaste});
  }

  onApplyGender(selectedGender) {
    this.setState({selectedGender});
  }

  onApplyOpinion(selectedOpinion) {
    this.setState({selectedOpinion});
  }

  onApplyMinAge(minAge) {
    this.setState({minAge});
  }

  onApplyMaxAge(maxAge) {
    this.setState({maxAge});
  }

  onApplyGovernmentScheme(governmentScheme) {
    this.setState({governmentScheme});
  }

  onApplyInfluentialVoter(influentialVoter) {
    this.setState({influentialVoter});
  }

  onApplySurveyCompleted(surveyCompleted) {
    this.setState({surveyCompleted});
  }

  onChangePagenation(event) {
    this.setState({ voterTableDetails: [], voterTableLoaderStatus: true});
    getVoterDetails(event, this.state.pageSize).then((voterTableDetails) => {
      this.setState({ voterTableDetails, pageNumber: event, voterTableLoaderStatus: false });
    }).catch((voterDetailserr) => {
      this.setState({voterDetailserr});
    })
  }

  onChangePageSize(pageSize) {
    this.setState({ voterTableDetails: [], voterTableLoaderStatus: true});
    getVoterDetails(this.state.pageNumber, pageSize).then((voterTableDetails) => {
      this.setState({ voterTableDetails, pageSize, voterTableLoaderStatus: false });
    }).catch((voterDetailserr) => {
      this.setState({voterDetailserr});
    })
  }

  componentDidMount() {
    let pageNumber = 1;
    this.setState({voterTableLoaderStatus: true});
    getVoterDetails(pageNumber, this.state.pageSize).then((voterTableDetails) => {
      this.setState({ voterTableDetails, pageNumber, voterTableLoaderStatus: false });
    }).catch((voterDetailserr) => {
      this.setState({voterDetailserr, voterTableLoaderStatus: false});
    })
    setTimeout(() => {
			this.setState({toastConfig: getToastConfig(this.props, false)});
		}, 3000);

    getStateConstituencies().then((constituencies) => {
      getBlocks(this.state.selectedConstituency).then((blocks) => {
        this.setState({constituencies, blocks});
      }).catch((err) => {
        this.setState({err});
      })
    }).catch((err) => {
      this.setState({err});
    })

  }

  render() {
    return(
      <>
          {getToast(this.state.toastConfig)}
          <div className='text-start pt-3'>
            <div className='d-inline ps-2'>
                Voters 
            </div>
            <div className='d-inline ps-2' onClick={this.handleChange}>
              <span className='bg-success p-1 text-white rounded-3'><FontAwesomeIcon icon={this.state.filterShowing ? faFilterCircleXmark: faFilter} /></span>
            </div>
          </div>
          <div className='row'>
            {this.state.filterShowing && <VoterFilters onApplyFilter={this.onApplyFilter} onApplyConstituency={this.onApplyConstituency} onApplyBlock={this.onApplyBlock} onApplyVillage={this.onApplyVillage} onApplyPollingStation={this.onApplyPollingStation} onApplyReligion={this.onApplyReligion} onApplyCaste={this.onApplyCaste} onApplySubCaste={this.onApplySubCaste} onApplyGender={this.onApplyGender} onApplyOpinion={this.onApplyOpinion} onApplyMinAge={this.onApplyMinAge} onApplyMaxAge={this.onApplyMaxAge} onApplyGovernmentScheme={this.onApplyGovernmentScheme} onApplyInfluentialVoter={this.onApplyInfluentialVoter} onApplySurveyCompleted={this.onApplySurveyCompleted} 
            filterData={this.state.filterData} 
            villages={this.state.villages} 
            blocks={this.state.blocks} 
            pollingStations={this.state.pollingStations} 
            constituencies={this.state.constituencies} 
            selectedConstituency={this.state.selectedConstituency} 
            selectedBlock={this.state.selectedBlock} 
            selectedVillage={this.state.selectedVillage} 
            selectedPollingStation={this.state.selectedPollingStation}
            selectedReligion={this.state.selectedReligion}
            selectedCaste={this.state.selectedCaste}
            selectedSubCaste={this.state.selectedSubCaste}
            selectedOpinion={this.state.selectedOpinion}
            selectedGender={this.state.selectedGender} 
            minAge={this.state.minAge} 
            maxAge={this.state.maxAge} 
            governmentScheme={this.state.governmentScheme} 
            influentialVoter={this.state.influentialVoter}
            surveyCompleted={this.state.surveyCompleted}
            subCasteList={this.state.subCasteList}
             />}
            <VoterTable voterTableDetails={this.state.voterTableDetails ? this.state.voterTableDetails: this.state.voterTableDetails} voterDetailserr={this.state.voterDetailserr} onChangePagenation={ this.onChangePagenation } filterShowing={ this.state.filterShowing } onChangePageSize={this.onChangePageSize} onChangePageCounter={this.onChangePageCounter} initialPage={this.state.initialPage} voterTableLoaderStatus={this.state.voterTableLoaderStatus}/>
          </div>
        </>
    );
  }
}

export default withParams(Voters);