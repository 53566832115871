import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { getAssignedHouses } from '../../services/Voters';
import RotatingLineLoader from '../RotatingLineLoader';
import Error from '../error';
import Pagination from '../Pagination';
import { Link } from "react-router-dom";
import StatusToast from "../toast/StatusToast";

class HousesListTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pageNumber: 1,
			pageSize: 10,
			houses: null,
			loaderStatus: false,
			error: null,
			searchValue: null
		};
		this.onChangePage = this.onChangePage.bind(this);
		this.handleSearchInput = this.handleSearchInput.bind(this);
		// this.handleSearch = this.handleSearch.bind(this);
	}

	// handleSearch() {
	// 	console.log(this.state.searchValue);
	// }

	handleSearchInput(event) {
		this.setState({loaderStatus: true, houses: null});
		getAssignedHouses(this.state.pageNumber, this.state.pageSize, event.target.value).then((houses) => {
			this.setState({houses, loaderStatus: false, pageNumber: event});
		}).catch((error) => {
			this.setState({error, loaderStatus: false});
		})
	}

	onChangePage(event) {
		this.setState({loaderStatus: true, houses: null});
		getAssignedHouses(event, this.state.pageSize, null).then((houses) => {
			this.setState({houses, loaderStatus: false, pageNumber: event});
		}).catch((error) => {
			this.setState({error, loaderStatus: false});
		})
	}

	componentDidMount() {
		this.setState({loaderStatus: true});
		getAssignedHouses(this.state.pageNumber, this.state.pageSize, null).then((houses) => {
			this.setState({houses, loaderStatus: false});
		}).catch((error) => {
			this.setState({error, loaderStatus: false});
		})
	}

	render() {
		return(
			<div>
			<StatusToast delayTime="3000"/>
				<div className="card">
					<div className="card-body">
						<div className="d-flex">
							<div className="form-group ms-auto">
								<input type="text" className="form-control" placeholder="Please search houses." onChange={this.handleSearchInput} />
							</div>
							{/* <div className="form-group ms-2">
								<button className="btn btn-success" onClick={this.handleSearch}>Search</button>
							</div> */}
						</div>
						{
							this.state.houses != null ? 
							<div className="overflow-x">
								<table className="table table-striped">
									<thead>
										<tr>
											<th>House No.</th>
											<th>Polling Station</th>
											<th>Village</th>
											<th>Voter Count</th>
											<th className="text-center">Action</th>
										</tr>
									</thead>
									<tbody>
									{
										this.state.houses?.data?.map(record => 
											<tr key={record?.house?.house_no}>
												<td>{record?.house?.house_no}</td>
												<td>{record?.house?.polling_station?.part_number}{record?.house?.polling_station?.name ? ` - ${record?.house?.polling_station?.name}` : ''}</td>
												<td>{record?.house?.village}</td>
												<td>{record?.voters?.length}</td>
												<td className="text-center"><Link to={`/house-view/houses-list-table/${record?.house?.id}`} ><FontAwesomeIcon icon={ faEye } /></Link></td>
											</tr>
										)
									}
									</tbody>
								</table> 
								<div className="text-center align-middle">
									<Pagination pageCount={this.state.houses?.count} currentPage={this.state.houses?.current_page - 1} pageSize={this.state.pageSize} onChangePage={this.onChangePage} />
								</div>
							</div>
							: this.state.loaderStatus == true ? <RotatingLineLoader /> 
							: this.state.error != null ? <Error />
							: <div className="text-center fw-600 fs-6 text-black-50">No data found</div>
						}
					</div>
				</div>
			</div>
		)
	}
}

export default HousesListTable;