import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Dashboard-Style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Pagination from '../Pagination';
import { RotatingLines } from  'react-loader-spinner';
import Error from '../error';


class DashboardPollingStationInchargeTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      constituency: this.props.constituency,
      block: this.props.block,
      village: this.props.village,
      error:'',
    };
    this.onChangePage = this.onChangePage.bind(this);
  }

  onChangePage(event) {
    this.props.onChangePollingStationInchargePagination(this.state.constituency, this.state.block, this.state.village, event);
  }

  render() {
    return(
        <div className='col-md-6 col-sm-6 col-12'>
          <div className='card shadow mt-3'>
            <div className='py-2 mx-1'>
              <div className='ps-1 header-style d-inline'>
                <span>PollingStation Incharges </span>
                <span className='text-primary'>
                <FontAwesomeIcon icon={faDownload} />
                </span>
              </div>
              <div className='d-inline'>
                <Link to={`/pollingStation-incharges`} className='btn btn-success float-end me-1 header-style'>View All</Link>
              </div>
            </div>
            <div className='mx-1'>
            { this.props.pollingStationInchargesData != '' ? 
            <table className="table table-responsive-sm">
              <thead className='table-header-style'>
                <tr>
                  <td className='table-header-font'>PollingStation No</td>
                  <td className='table-header-font'>Name</td>
                  <td className='table-header-font'>Mobile</td>
                  <td className='table-header-font text-center'>Assigned Count</td>
                  <td className='table-header-font text-center'>Surveyed Count</td>
                  <td className='table-header-font text-center'>Completed %</td>
                </tr>
              </thead>
              <tbody>
                {this.props.pollingStationInchargesData.result && this.props.pollingStationInchargesData.result.map((pollingStation, index) => 
                  <tr key={index}>
                    <td className='normal-font'>{ pollingStation.pollingStationNo }</td>
                    <td className='normal-font'>{ pollingStation.name }</td>
                    <td className='normal-font'>{ pollingStation.mobile }</td>
                    <td className='normal-font text-center'>{ pollingStation.assignedCount }</td>
                    <td className='normal-font text-center'>{ pollingStation.surveyedCount }</td>
                    <td className='normal-font text-center'>{ pollingStation.completed }</td>
                  </tr>
                )}
                <tr className='text-center'>
                  <td colSpan={15} className='border border-0'>
                  <Pagination pageCount={this.props.pollingStationInchargesData.count} onChangePage={ this.onChangePage } />
                  </td>
                </tr>
              </tbody>
            </table> : this.state.error != '' ? 
              <Error /> :
              <div className='data-loader text-center'>
                <RotatingLines strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="96"
                          visible={true} />
              </div>
            }
            </div>
          </div>
        </div>
    );
  }
}

export default DashboardPollingStationInchargeTable;