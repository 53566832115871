import React from "react";
import { getCommentsOfVolunteer } from '../../services/Voters';
import Pagination from '../Pagination';
import { RotatingLines } from  'react-loader-spinner';
import Error from '../error';

class CommentsOfVolunteer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentsOfVolunteer: [],
      error: '',
    };
    this.onChangePage = this.onChangePage.bind(this);
  }

  componentDidMount() {
    getCommentsOfVolunteer('', '', '', '').then((commentsOfVolunteer) => {
      this.setState({commentsOfVolunteer});
    }).catch((error) => {
        this.setState({error});
    })
  }

  onChangePage(event) {
    getCommentsOfVolunteer('', '', '', event).then((commentsOfVolunteer) => {
      this.setState({commentsOfVolunteer});
    }).catch((error) => {
      this.setState({error});
    })
  }

  render() {
    return(
      <>
        <div>
          <h3>Comments of Volunteer</h3>
        </div>
        <div className="card">
          <div className="card-body">
            { this.state.commentsOfVolunteer != '' ? 
            <div>
            <table className="table table-striped">
              <thead className="bg-primary">
                <tr>
                  <th className="align-middle">Date</th>
                  <th className="align-middle">Village</th>
                  <th className="align-middle">Volunteer</th>
                  <th className="align-middle">Comments</th>
                </tr>
              </thead>
              <tbody>
                { this.state.commentsOfVolunteer.result && this.state.commentsOfVolunteer.result.map(comments => 
                  <tr key={ comments.volunteer }>
                    <td className="align-middle">{ comments.date }</td>
                    <td className="align-middle">{ comments.village }</td>
                    <td className="align-middle">{ comments.volunteer }</td>
                    <td className="align-middle">{ comments.comment }</td>
                  </tr>
                  )}
              </tbody>
            </table>
            <div className="text-center align-middle">
              <Pagination pageCount={this.state.commentsOfVolunteer.count} onChangePage={this.onChangePage} />
            </div>
          </div>
            : this.state.error != '' ? 
              <Error /> :
              <div className='data-loader text-center'>
                <RotatingLines strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="96"
                          visible={true} />
              </div>
            }
          </div>
        </div>
      </>
    )
  }
}

export default CommentsOfVolunteer;