import React from "react";
import { Combobox } from "react-widgets";
import { getSearchUser, getStateConstituencies, psInchargeFields, getBlocks, getVillages, getPollingStations, getPollingStationHouses } from '../../services/Voters';
import Constituencies from "../Constituencies";
import Blocks from "../Blocks";
import Villages from "../Villages";
import PollingStations from "../PollingStations";


class AddNewHouseIncharge extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            userList: [],
            constituencies: [],
            blocks: [],
            villages: [],
            pollingStations: [],
            selectedUser: null,
            user: null,
            userError: '',
            constituencyError: '',
            blockError: '',
            villageError: '',
            pollingStationError: '',
			houseList: [],
			houseError: '',
			selectedHouse: null,
            selectedConstituency: 86,
            selectedPollingStation: null
        };
        this.handleUser = this.handleUser.bind(this);
        this.onChangeConstituencies = this.onChangeConstituencies.bind(this);
        this.onChangeVillage = this.onChangeVillage.bind(this);
        this.onChangeBlock = this.onChangeBlock.bind(this);
        this.onChangePollingStation = this.onChangePollingStation.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectedUser = this.handleSelectedUser.bind(this);
		this.handleHouse = this.handleHouse.bind(this);
    }

    handleUser(event) {
        getSearchUser(event).then((userList) => {
            this.setState({userList, userError: ''});
        }).catch((error) => {
            this.setState({error});
        })
    }

    onChangeConstituencies(selectedConstituency) {
        getBlocks(selectedConstituency).then((blocks) => {
            this.setState({selectedConstituency, blocks, constituencyError: ''});
          }).catch((error) => {
            this.setState({error});
          })
    }

    onChangeBlock(selectedBlock) {
        getVillages(selectedBlock).then((villages) => {
            this.setState({selectedBlock, villages, blockError: ''});
        }).catch((error) => {
            this.setState({error});
        })
    }

    onChangeVillage(selectedVillage) {
        getPollingStations(selectedVillage).then((pollingStations) => {
            this.setState({selectedVillage, pollingStations, villageError: ''});
        }).catch((error) => {
            this.setState({error});
        })
    }

    onChangePollingStation(selectedPollingStation) {
        this.setState({selectedPollingStation});
		getPollingStationHouses(selectedPollingStation).then((houseList) => {
			this.setState({pollingStationError: '', houseList});
		}).catch((houseError) => {
			this.setState({houseError});
		})
    }

    handleSelectedUser(event) {
        this.setState({user: event});
    }

	handleHouse(selectedHouse) {
		this.setState({selectedHouse, houseError: ''});
	}

    handleSubmit() {
        let userId = this.state.user?.user.id;
        let constituencyId = this.state.selectedConstituency;
        let blockId = this.state.selectedBlock;
        let villageId = this.state.selectedVillage;
        let pollingStationId = this.state.selectedPollingStation;
		let houseId = this.state.selectedHouse;
        if(userId == '' || userId == undefined) {
            this.setState({userError: 'Please select the user.'});
        }
        
        if(constituencyId == '' || constituencyId == undefined) {
            this.setState({constituencyError: 'Please select the constituency.'});
        }
        else if(blockId == '' || blockId == undefined) {
            this.setState({blockError: 'Please select the block.'});
        }
        else if(villageId == '' || villageId == undefined) {
            this.setState({villageError: 'Please select the village.'});
        }
        else if(pollingStationId == '' || pollingStationId == undefined) {
            this.setState({pollingStationError: 'Please select the pollingStation.'});
        }
		else if(houseId == '' || houseId == undefined) {
			this.setState({houseError: 'Please select the house.'});
		}

        let data = {user: userId, pollingStation: pollingStationId};
    }

    componentDidMount() {
        getStateConstituencies().then((constituencies) => {
            getBlocks(86).then((blocks) => {
                this.setState({constituencies, blocks});
            }).catch((error) => {
                this.setState({error});
            })
        }).catch((error) => {
            this.setState({error});
        })
    }

    render() {
        return(
            <div className="card mx-5">
                <div className="card-body">
                    <div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-center" colSpan={2}>
                                        <span className="fw-700 fs-5">Add House Incharge</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="table-form-label-display align-middle fw-bold dtr-control sorting_1 w-50">User </td>
                                    <td>
                                        <label className='d-block d-sm-none d-md-none d-lg-none'>User</label>
                                        <div className="col-12">
                                            <div className='form-group text-start my-auto'>
                                                <Combobox data={this.state.userList} options={this.state.userList} textField={(item) =>typeof item === 'string' ? item : item?.user?.username}  onChange={this.handleUser} onSelect={this.handleSelectedUser} placeholder={`Please search user.`} />
                                                <span className={`text-danger fw-600 ${this.state.userError && `ps-2`}`}>{this.state.userError && this.state.userError}</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-form-label-display align-middle fw-bold dtr-control sorting_1">Constituency</td>
                                    <td>
                                        <label className='d-block d-sm-none d-md-none d-lg-none'>Constituency</label>
                                        <Constituencies constituencyFields={psInchargeFields} constituencies={this.state.constituencies} onChangeConstituencies={this.onChangeConstituencies} selectedConstituency={this.state.selectedConstituency} />
                                        <span className={`text-danger fw-600 ${this.state.constituencyError && `ps-2`}`}>{this.state.constituencyError && this.state.constituencyError}</span>
                                    </td>
                                </tr>
                                {
                                    this.state.blocks?.length !== 0 ?
                                    <tr>
                                        <td className="table-form-label-display align-middle fw-bold dtr-control sorting_1">Block</td>
                                        <td>
                                            <label className='d-block d-sm-none d-md-none d-lg-none'>Block</label>
                                            <Blocks blockFields={psInchargeFields} blocks={this.state.blocks} onChangeBlock={this.onChangeBlock} />
                                            <span className={`text-danger fw-600 ${this.state.blockError && `ps-2`}`}>{this.state.blockError && this.state.blockError}</span>
                                        </td>
                                    </tr> : ''
                                }
                                {
                                    this.state.villages.length !== 0 ? 
                                    <tr>
                                        <td className="table-form-label-display align-middle fw-bold dtr-control sorting_1">Village</td>
                                        <td>
                                            <label className='d-block d-sm-none d-md-none d-lg-none'>Village</label>
                                            <Villages villageFields={psInchargeFields} villages={this.state.villages} onChangeVillage={this.onChangeVillage} />
                                            <span className={`text-danger fw-600 ${this.state.villageError && `ps-2`}`}>{this.state.villageError && this.state.villageError}</span>
                                        </td>
                                    </tr> : ''
                                }
                                {
                                    this.state.pollingStations.length !== 0 ? 
                                    <tr>
                                        <td className="table-form-label-display align-middle fw-bold dtr-control sorting_1">Polling Station </td>
                                        <td>
                                            <label className='d-block d-sm-none d-md-none d-lg-none'>Polling Station</label>
                                            <PollingStations pollingStations={this.state.pollingStations} pollingStationFields={psInchargeFields} onChangePollingStation={this.onChangePollingStation} />
                                            <span className={`text-danger fw-600 ${this.state.pollingStationError && `ps-2`}`}>{this.state.pollingStationError && this.state.pollingStationError}</span>
                                        </td>
                                    </tr> : ''
                                }
								{
									this.state.selectedPollingStation != null ? 
									<tr>
										<td className="table-form-label-display align-middle fw-bold dtr-control sorting_1">House</td>
										<td>
                                            <label className='d-block d-sm-none d-md-none d-lg-none'>House</label>
											<Combobox data={this.state.houseList} dataKey="id" options={this.state.houseList} onSelect={this.handleHouse} />
											<span className={`text-danger fw-600 ${this.state.houseError && `ps-2`}`}>{this.state.houseError && this.state.houseError}</span>
										</td>
									</tr> : ''
								}
                                <tr>
                                    <td colSpan={2} className='text-center'>
                                        <button className="btn btn-success" onClick={this.handleSubmit}>Add House Incharge</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddNewHouseIncharge;