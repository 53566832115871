import React from "react";

class CacheSettings extends React.Component {
	render() {
		return(
			<>
				<h4 className="mb-3">Cache Settings</h4>
				<div className="card">
					<div className="card-body">
						<table className="table table-striped">
							<thead className="bg-primary">
								<tr>
									<th>Title</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Voter Cache</td>
									<td>
										<button className="btn btn-warning">Clear</button>
									</td>
								</tr>
								<tr>
									<td>Stats Cache</td>
									<td>
										<button className="btn btn-warning">Clear</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</>
		)
	}
}

export default CacheSettings;