import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";
import Constituencies from "../Constituencies";
import Blocks from "../Blocks";
import Villages from "../Villages";
import PollingStations from "../PollingStations";
import { filterFields, getStateConstituencies, getBlocks, getVillages, getPollingStations } from '../../services/Voters';





function getInchargesDetails(selectedConstituency, selectedBlock = '', selectedVillage = '', selectedPollingStation = '') {

  if(selectedConstituency != '' && selectedBlock == '' && selectedVillage == '' && selectedPollingStation == '') {
    let allinchargesDetails = {
      jangaon: [{id: 1, name: 'voter', relationName: 'Relation', mobile: 9876543210, voterId: 'yq1234uz', age: 20, gender: 'Male', houseNumber: '1-25', ward: 'Ward', pollingStation: 'PollingStation', village: 'Village', block: 'Block', assembly: 'Assembly', opininon: 'Opinion', survey: 'Survey'},
      {id: 2, name: 'voter', relationName: 'Relation', mobile: 9876543210, voterId: 'yq1234uz', age: 20, gender: 'Male', houseNumber: '1-25', ward: 'Ward', pollingStation: 'PollingStation', village: 'Village', block: 'Block', assembly: 'Assembly', opininon: 'Opinion', survey: 'Survey'},
      {id: 3, name: 'voter', relationName: 'Relation', mobile: 9876543210, voterId: 'yq1234uz', age: 20, gender: 'Male', houseNumber: '1-25', ward: 'Ward', pollingStation: 'PollingStation', village: 'Village', block: 'Block', assembly: 'Assembly', opininon: 'Opinion', survey: 'Survey'},
      {id: 4, name: 'voter', relationName: 'Relation', mobile: 9876543210, voterId: 'yq1234uz', age: 20, gender: 'Male', houseNumber: '1-25', ward: 'Ward', pollingStation: 'PollingStation', village: 'Village', block: 'Block', assembly: 'Assembly', opininon: 'Opinion', survey: 'Survey'}]
    }
    return allinchargesDetails[selectedConstituency];
  }
  else if(selectedConstituency != '' && selectedBlock != '' && selectedVillage == '' && selectedPollingStation == '') {
    let allinchargesDetails = {
      Block1: [{id: 1, name: 'voter', relationName: 'Relation', mobile: 9876543210, voterId: 'yq1234uz', age: 20, gender: 'Male', houseNumber: '1-25', ward: 'Ward', pollingStation: 'PollingStation', village: 'Village', block: 'Block', assembly: 'Assembly', opininon: 'Opinion', survey: 'Survey'},
      {id: 2, name: 'voter', relationName: 'Relation', mobile: 9876543210, voterId: 'yq1234uz', age: 20, gender: 'Male', houseNumber: '1-25', ward: 'Ward', pollingStation: 'PollingStation', village: 'Village', block: 'Block', assembly: 'Assembly', opininon: 'Opinion', survey: 'Survey'},
      {id: 3, name: 'voter', relationName: 'Relation', mobile: 9876543210, voterId: 'yq1234uz', age: 20, gender: 'Male', houseNumber: '1-25', ward: 'Ward', pollingStation: 'PollingStation', village: 'Village', block: 'Block', assembly: 'Assembly', opininon: 'Opinion', survey: 'Survey'}]
    }
    return allinchargesDetails[selectedBlock];
  }
  else if(selectedConstituency != '' && selectedBlock != '' && selectedVillage != '' && selectedPollingStation == '') {
    let allinchargesDetails = {
      Village1: [{id: 1, name: 'voter', relationName: 'Relation', mobile: 9876543210, voterId: 'yq1234uz', age: 20, gender: 'Male', houseNumber: '1-25', ward: 'Ward', pollingStation: 'PollingStation', village: 'Village', block: 'Block', assembly: 'Assembly', opininon: 'Opinion', survey: 'Survey'},
      {id: 2, name: 'voter', relationName: 'Relation', mobile: 9876543210, voterId: 'yq1234uz', age: 20, gender: 'Male', houseNumber: '1-25', ward: 'Ward', pollingStation: 'PollingStation', village: 'Village', block: 'Block', assembly: 'Assembly', opininon: 'Opinion', survey: 'Survey'}]
    }
    return allinchargesDetails[selectedVillage];
  }
  else if(selectedConstituency != '' && selectedBlock != '' && selectedVillage != '' && selectedPollingStation != '') {
    let allinchargesDetails = {
      PollingStation1: [{id: 1, name: 'voter', relationName: 'Relation', mobile: 9876543210, voterId: 'yq1234uz', age: 20, gender: 'Male', houseNumber: '1-25', ward: 'Ward', pollingStation: 'PollingStation', village: 'Village', block: 'Block', assembly: 'Assembly', opininon: 'Opinion', survey: 'Survey'}]
    }
    return allinchargesDetails[selectedVillage];
  }
  else
  {
    let allinchargesDetails = {
      jangaon: [{id: 1, name: 'voter', relationName: 'Relation', mobile: 9876543210, voterId: 'yq1234uz', age: 20, gender: 'Male', houseNumber: '1-25', ward: 'Ward', pollingStation: 'PollingStation', village: 'Village', block: 'Block', assembly: 'Assembly', opininon: 'Opinion', survey: 'Survey'},
      {id: 2, name: 'voter', relationName: 'Relation', mobile: 9876543210, voterId: 'yq1234uz', age: 20, gender: 'Male', houseNumber: '1-25', ward: 'Ward', pollingStation: 'PollingStation', village: 'Village', block: 'Block', assembly: 'Assembly', opininon: 'Opinion', survey: 'Survey'},
      {id: 3, name: 'voter', relationName: 'Relation', mobile: 9876543210, voterId: 'yq1234uz', age: 20, gender: 'Male', houseNumber: '1-25', ward: 'Ward', pollingStation: 'PollingStation', village: 'Village', block: 'Block', assembly: 'Assembly', opininon: 'Opinion', survey: 'Survey'},
      {id: 4, name: 'voter', relationName: 'Relation', mobile: 9876543210, voterId: 'yq1234uz', age: 20, gender: 'Male', houseNumber: '1-25', ward: 'Ward', pollingStation: 'PollingStation', village: 'Village', block: 'Block', assembly: 'Assembly', opininon: 'Opinion', survey: 'Survey'},
      {id: 5, name: 'voter', relationName: 'Relation', mobile: 9876543210, voterId: 'yq1234uz', age: 20, gender: 'Male', houseNumber: '1-25', ward: 'Ward', pollingStation: 'PollingStation', village: 'Village', block: 'Block', assembly: 'Assembly', opininon: 'Opinion', survey: 'Survey'}]
    }
    return allinchargesDetails[selectedConstituency];
  }
}


class InchargesFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      constituencies: [],
      selectedConstituency: 86,
      selectedBlock: null,
      selectedVillage: null,
      selectedPollingStation: null,
      blocks: [],
      pollingStations: [],
      blockValue: '',
      clear: true
    };
    this.onChangeConstituencies = this.onChangeConstituencies.bind(this);
    this.onChangeBlock = this.onChangeBlock.bind(this);
    this.onChangeVillage = this.onChangeVillage.bind(this);
    this.onChangePollingStation = this.onChangePollingStation.bind(this);
    this.onClickClearFilter = this.onClickClearFilter.bind(this);
  }

  onClickClearFilter() {
    getStateConstituencies().then((constituencies) => {
      let selectedConstituency = 86;
      getBlocks(selectedConstituency).then((blocks) => {
        this.props.onApplyFilter(selectedConstituency, null, null, null, constituencies, blocks, [], [], this.props.selectedConstituencyObj);
        this.setState({clear: !this.state.clear});
      }).catch((err) => {
        console.log(err);
      })
    }).catch((err) => {
      // alert("Server error, please try again later.");
    });
  }
  
  onChangeConstituencies(selectedConstituency) {
    getBlocks(selectedConstituency).then((blocks) => {
      this.props.onApplyFilter(selectedConstituency, null, null, null, this.props.constituencies, blocks, [], [], this.props.selectedConstituencyObj);
      this.setState({selectedConstituency, blocks});
    }).catch((err) => {
      console.log(err);
    })
  }

  onChangeBlock(selectedBlock) {
    getVillages(selectedBlock).then((villages) => {
      this.props.onApplyFilter(this.props.selectedConstituency, selectedBlock, null, null, this.props.constituencies, this.props.blocks, villages, [], this.props.selectedConstituencyObj);
    }).catch((err) => {
      console.log(err);
    })
  }

  onChangeVillage(selectedVillage) {
    getPollingStations(selectedVillage).then((pollingStations) => {
      this.props.onApplyFilter(this.props.selectedConstituency, this.props.selectedBlock, selectedVillage, null, this.props.constituencies, this.props.blocks, this.props.villages, pollingStations, this.props.selectedConstituencyObj);
    })
  }

  onChangePollingStation(selectedPollingStation) {
    this.props.onApplyFilter(this.props.selectedConstituency, this.props.selectedBlock, this.props.selectedVillage, selectedPollingStation, this.props.constituencies, this.props.blocks, this.props.villages, this.props.pollingStations, this.props.selectedConstituencyObj);
  }

  componentDidMount() {
    getStateConstituencies().then((constituencies) => {
      let selectedConstituency = this.props.selectedConstituency;
      getBlocks(selectedConstituency).then((blocks) => {
        this.props.onApplyFilter(selectedConstituency, this.props.selectedBlock, this.props.selectedVillage, this.props.selectedPollingStation, constituencies, blocks, this.props.villages, this.props.pollingStations, this.props.selectedConstituencyObj);
        this.setState({selectedConstituency, blocks, constituencies});
      }).catch((err) => {
        console.log(err);
      })
    }).catch((err) => {
      // alert("Server error, please try again later.");
    });
  }


  render() {
    return(
      <>
        <div className='row'>
          <Constituencies clear={this.state.clear} constituencyFields={filterFields} onChangeConstituencies={this.onChangeConstituencies} constituencies={this.props.constituencies} selectedConstituency={this.props.selectedConstituency} selectedConstituencyObj={this.props.selectedConstituencyObj} />
          <Blocks clear={this.state.clear} blockFields={filterFields} blocks={this.props.blocks} selectedBlock={this.props.selectedBlock} onChangeBlock={this.onChangeBlock} selectedConstituency={this.props.selectedConstituency} />
          <Villages villageFields={filterFields} villages={this.props.villages} selectedVillage={this.props.selectedVillage} onChangeVillage={this.onChangeVillage} selectedBlock={this.props.selectedBlock} selectedConstituency={this.props?.selectedConstituency} />
          <PollingStations pollingStationFields={filterFields} pollingStations={this.props.pollingStations} onChangePollingStation={this.onChangePollingStation} selectedPollingStation={this.props.selectedPollingStation} selectedVillage={this.props.selectedVillage} selectedBlock={this.props.selectedBlock} selectedConstituency={this.props.selectedConstituency} />
        </div>
        <div className="row">
          <div className='col-md-3 col-lg-3 col-12 text-start align-self-center my-2'>
            <button className='btn btn-danger ms-1 text-white' onClick={this.onClickClearFilter} >Clear</button>
          </div>
        </div>
      </>
    );
  }
}

export default InchargesFilter;