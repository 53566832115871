import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { getNameFromList } from '../../services/Voters';

class LeaderPerceptionAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leaderPerceptionArray: [],
      leaderError: '',
      partyError: '',
      perceptionError: '',
      strengthsError: '',
      weaknessesError: ''
    };
    this.leader = React.createRef();
    this.party = React.createRef();
    this.perception = React.createRef();
    this.strengths = React.createRef();
    this.weaknesses = React.createRef();
    this.onClickLeaderPerception = this.onClickLeaderPerception.bind(this);
    this.removeLeaderPerception = this.removeLeaderPerception.bind(this);
    this.handleLeader = this.handleLeader.bind(this);
    this.handleParty = this.handleParty.bind(this);
    this.handlePerception = this.handlePerception.bind(this);
    this.handleStrengths = this.handleStrengths.bind(this);
    this.handleWeaknesses = this.handleWeaknesses.bind(this);
  }

  handleLeader() {
    this.setState({leaderError: ''});
  }

  handleParty() {
    this.setState({partyError: ''});
  }

  handlePerception() {
    this.setState({perceptionError: ''});
  }

  handleStrengths() {
    this.setState({strengthsError: ''});
  }

  handleWeaknesses() {
    this.setState({weaknessesError: ''});
  }

  onClickLeaderPerception() {
    let party = this.party.current.value;
    let leader = this.leader.current.value;
    let perception = this.perception.current.value;
    let strengths = this.strengths.current.value;
    let weaknesses = this.weaknesses.current.value;
    if(leader == '') {
      this.setState({leaderError: 'Please enter leader.'});
    }
    else if(party == '') {
      this.setState({partyError: 'Please select party'});
    }
    else if(perception == '') {
      this.setState({perceptionError: 'Please enter perception.'});
    }
    else if(strengths == '') {
      this.setState({strengthsError: 'Please enter strengths.'});
    }
    else if(weaknesses == '') {
      this.setState({weaknessesError: 'Please enter weaknesses.'});
    }
    else {
      let leaderPerceptionObject = { 'leader': leader, 'party': party, 'perception': perception, 'strengths': strengths, 'weaknesses': weaknesses };
      let leaderPerceptionArray = this.state.leaderPerceptionArray;
      leaderPerceptionArray = [ ...leaderPerceptionArray, leaderPerceptionObject ];
      this.leader.current.value = '';
      this.party.current.value = '';
      this.perception.current.value = '';
      this.strengths.current.value = '';
      this.weaknesses.current.value = '';
      this.props.leaderPerception(leaderPerceptionArray);
      this.setState({ leaderPerceptionArray });
    }
  }

  removeLeaderPerception(event) {
    const leaderPerceptionArray = this.state.leaderPerceptionArray;
    let newLeaderPerceptionArray = [];
    for(let i=0;i<leaderPerceptionArray.length;i++) {
      if(i !== event.index) {
        newLeaderPerceptionArray = [ ...newLeaderPerceptionArray, leaderPerceptionArray[i] ];
      }
    }
    this.props.leaderPerception(newLeaderPerceptionArray);
    this.setState({ leaderPerceptionArray: newLeaderPerceptionArray });
  }

  render() {
    return(
      <table className="table custom-table">
        <thead>
          <tr>
            <th colSpan={7} className="text-center align-middle bg-dark bg-opacity-10 text-dark custom-table-th-desktop">Leader perception analysis</th>
            <th className="text-center align-middle bg-dark bg-opacity-10 text-dark custom-table-th-mobile">Leader perception analysis</th>
          </tr>
          <tr className="align-middle custom-table-tr-desktop">
            <th className="text-center w-5" scope="col">Sl. No.</th>
            <th scope="col">Leader</th>
            <th scope="col">Party</th>
            <th scope="col">Perception</th>
            <th scope="col">Strengths</th>
            <th scope="col">Weaknesses</th>
            <th className="text-center" scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" data-label=""></td>
            <td data-label="Leader">
              <input className="form-control" type="text" id="leader" name="leader" placeholder="Leader" ref={ this.leader } onChange={this.handleLeader} />
              <span className="text-danger fw-600">{this.state.leaderError && this.state.leaderError}</span>
            </td>
            <td data-label="Party">
              <select name="party" id="party" className="form-select" ref={ this.party } onChange={this.handleParty}>
                <option value="">Select Party</option>
                { this.props.opinionList?.map(opinion => <option value={opinion.id} key={opinion.id}>{opinion.name}</option>)}
              </select>
              <span className="text-danger fw-600">{this.state.partyError && this.state.partyError}</span>
              {/* <input className="form-control" type="text" id="party" name="party" placeholder="Party"  /> */}
            </td>
            <td data-label="Perception">
              <input className="form-control" type="text" id="perception" name="perception" placeholder="Perception" ref={ this.perception } onChange={this.handlePerception} />
              <span className="text-danger fw-600">{this.state.perceptionError && this.state.perceptionError}</span>
            </td>
            <td data-label="Strengths">
              <input className="form-control" type="text" id="strengths" name="strengths" placeholder="Strengths" ref={ this.strengths } onChange={this.handleStrengths} />
              <span className="text-danger fw-600">{this.state.strengthsError && this.state.strengthsError}</span>
            </td>
            <td data-label="Weaknesses">
              <input className="form-control" type="text" id="weaknesses" name="weaknesses" placeholder="Weaknesses" ref={ this.weaknesses } onChange={this.handleWeaknesses} />
              <span className="text-danger fw-600">{this.state.weaknessesError && this.state.weaknessesError}</span>
            </td>
            <td className="text-center align-middle" data-label="Action"><FontAwesomeIcon icon={ faPlus } className="btn btn-success" onClick={ this.onClickLeaderPerception } /></td>
          </tr>
          {
            this.props.leaderPerceptionList?.map((leaderPerception, index) => 
              <tr key={ index }>
                <td scope="row" data-label="Sl. No.">{ index +1 }</td>
                <td data-label="Leader">
                  <input className="form-control" type="text" id={`leader-${index}-${this.state.leaderPerceptionArray.length}`} key={`leader-${index}-${this.state.leaderPerceptionArray.length}`} name="leader" placeholder="Leader" defaultValue={ leaderPerception.leader } readOnly/>
                </td>
                <td data-label="Party">
                  <input className="form-control" type="text" id={`party-${index}-${this.state.leaderPerceptionArray.length}`} key={`party-${index}-${this.state.leaderPerceptionArray.length}`} name="party" placeholder="Party" defaultValue={ getNameFromList(this.props.opinionList, leaderPerception.party) } readOnly/>
                </td>
                <td data-label="Perception">
                  <input className="form-control" type="text" id={`perception-${index}-${this.state.leaderPerceptionArray.length}`} key={`perception-${index}-${this.state.leaderPerceptionArray.length}`} name="perception" placeholder="Perception" defaultValue={ leaderPerception.perception } readOnly/>
                </td>
                <td data-label="Strengths">
                  <input className="form-control" type="text" id={`strengths-${index}-${this.state.leaderPerceptionArray.length}`} key={`strengths-${index}-${this.state.leaderPerceptionArray.length}`} name="strengths" placeholder="Strengths" defaultValue={ leaderPerception.strengths } readOnly/>
                </td>
                <td data-label="Weaknesses">
                  <input className="form-control" type="text" id={`weaknesses-${index}-${this.state.leaderPerceptionArray.length}`} key={`weaknesses-${index}-${this.state.leaderPerceptionArray.length}`} name="weaknesses" placeholder="Weaknesses" defaultValue={ leaderPerception.weaknesses } readOnly/>
                </td>
                <td className="text-center align-middle" data-label="Action"><FontAwesomeIcon icon={ faMinus } className="btn btn-danger" onClick={ () => this.removeLeaderPerception({ index }) } /></td>
            </tr>
            )
          }
        </tbody>
      </table>
    )
  }
}

export default LeaderPerceptionAnalysis;