import {useState, useEffect} from 'react';
import {getUserProfile} from '../../services/Voters';
import userImage from '../../assets/img/usericon.png';
import { logout } from '../../services/Auth';
import { Link } from 'react-router-dom';

function UserProfile(props){
const [voterInfo,setVoterInfo] = useState({
  name:'',
  lastName:'',
	pollingStationName:'',
	partNumber:'',
	constituency:'',
	mandle:'',
	village:'',
	idCardNumber:'',
	mobileNumber:'',
	emailAddress:''
});
const getUserAllInfo = () =>{

	getUserProfile().then((UserProfile) =>{
  
    setVoterInfo((preValue) => {
			return {
        name: UserProfile.user?.name || UserProfile.user?.username,
        lastName : UserProfile.user?.last_name || '',
				pollingStationName : UserProfile.voter?.polling_station?.name || 'N/A',
				partNumber : UserProfile.voter?.polling_station?.id || 'N/A',
				constituency : UserProfile.voter?.polling_station?.village?.block?.constituency?.name  || 'N/A',
				mandle : UserProfile.voter?.polling_station?.village?.block?.name || 'N/A',
				village : UserProfile.voter?.polling_station?.village?.name || 'N/A',
				idCardNumber : UserProfile.voter?.id_card_number || 'N/A',
				mobileNumber : UserProfile.user?.mobile_number || 'N/A',
				emailAddress : UserProfile.user?.email || 'N/A'
			}

		});

	}).catch((error) => {
		console.log(error);
	});
}
useEffect( () =>{
	getUserAllInfo();
},[])

const handleLogout = () => {
    logout();
    props.onLogout();
  }

return(
<>
<section className="vh-100" style={{backgroundColor: '#f4f5f7'}}>
  <div className="container py-5 h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col col-lg-10 mb-4 mb-lg-0">
        <div className="card mb-3" style={{borderRadius: '.5rem'}}>
          <div className="row g-0">
            <div className="col-md-4 gradient-custom text-center text-white"
              style={{borderTopLeftRadius: '.5rem',borderBottomLeftRadius: '.5rem'}}>
              <img src={userImage}
                alt="Avatar" className="img-fluid my-5" width={80} />
              <h5>{voterInfo.name} {voterInfo.lastName}</h5>
              <p>{voterInfo.emailAddress}</p>
              <p>{voterInfo.mobileNumber}</p>
            </div>
            <div className="col-md-8">
              <div className="card-body p-4">
                <h6>Information</h6>
                <hr className="mt-0 mb-4" />
                <div className="row pt-1">
                  <div className="col-6 mb-3">
                    <h6>Constituency</h6>
                    <p className="text-muted">{voterInfo.constituency}</p>
                  </div>
                  <div className="col-6 mb-3">
                    <h6>Block</h6>
                    <p className="text-muted">{voterInfo.mandle}</p>
                  </div>
                </div>
                <div className="row pt-1">
                  <div className="col-6 mb-3">
                    <h6>Village</h6>
                    <p className="text-muted">{voterInfo.village}</p>
                  </div>
                </div>
                <hr className="mt-0 mb-4" />
                <div className="row pt-1">
                  <div className="col-6 mb-3">
                    <h6>Id Card</h6>
                    <p className="text-muted">{voterInfo.idCardNumber}</p>
                  </div>
                </div>
                <hr className="mt-0 mb-4" />
                <Link as={Link} className="no-underline btn btn-primary" to="/change-password">Change Password</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</>
);
}

export default UserProfile;