
import { Link } from "react-router-dom";


function CustomLink(props){
	
	return(
		<>

			<Link to={props.link} className={props.classes}>{props.text}</Link>

		</>


	);	


}


export default CustomLink;