import React from "react";

class updatePresentVotingTrend extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			presentVotingTrend: []
		}
		this.handlepresentVotingTrend = this.handlepresentVotingTrend.bind(this);
	}

	componentDidMount() {
		this.setState({presentVotingTrend: this.props.presentVotingTrend});
	}

	handlepresentVotingTrend = (event, id) => {
		this.setState({
			presentVotingTrend: this.state.presentVotingTrend?.map(item => (item.id == id ? {...item, presentVotingTrend: event.target.value} : item))
		});
	}

	render() {
		return(
			<div className='overflow-x-auto'>
				<table className="table">
					<thead>
						<tr className='bg-secondary bg-opacity-25 text-dark'>
							<th className='w-5 text-center'>Sl. No.</th>
							<th className="text-center">Present Voting Trend in the village</th>
						</tr>
					</thead>
					<tbody>
						{
							this.state.presentVotingTrend?.map((presentVoting, index) => (
								<tr key={presentVoting.id}>
									<td className='text-center'>{index + 1}</td>
									<td>
										<textarea className="form-control" name="presentVotingTrend" id="presentVotingTrend" onChange={(e) => this.handlepresentVotingTrend(e, presentVoting.id)} defaultValue={presentVoting.presentVotingTrend} ></textarea>
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		)
	}
}

export default updatePresentVotingTrend;