import React from "react";
import { getVoterSurveyQuestionsList } from '../../services/Voters';
import withParams from '../GetParams';
import { RotatingLines } from  'react-loader-spinner';
import Error from '../error';

const CHAR_CODE_A = 97;
class VoterSurveyView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			voterSurvey: [],
			error: ''
		};
	}

	componentDidMount() {
		let { id } = this.props.params;
		getVoterSurveyQuestionsList(id).then((voterSurvey) => {
			this.setState({voterSurvey});
		}).catch((error) => {
			this.setState({error});
		})
	}

	render() {
		return(
			<div className="card">
				<div className="card-body">
					{
						this.state.voterSurvey != '' ?
						<div>
							<div className="text-center fw-700 fs-5 py-3">Voter Survey</div>
							<table className="table">
								<tbody>
									<tr>
										<th className="w-50">Name</th>
										<td>{this.state.voterSurvey?.survey?.name}</td>
									</tr>
									<tr>
										<th>Created on</th>
										<td>{this.state.voterSurvey?.survey?.created_on}</td>
									</tr>
									<tr>
										<th>Created by</th>
										<td>{this.state.voterSurvey?.survey?.created_by?.name}</td>
									</tr>
								</tbody>
							</table>
							<div className="container-qa mt-sm-5 my-1">
								<div className="question ml-sm-5 pl-sm-5 pt-2">
									{
										this.state.voterSurvey.survey?.questions.map((question, index) =>
											<div key={question.id}>
												<div>
													<div className="py-2 h5"><b>Q {index + 1}. {question.text}</b></div>
													<div className="ml-sm-3  pt-sm-0 pt-3" id="options">
														{
															question.options.map((option, optionIndex) =>
																<label className="options" key={option.id} >
																	({String.fromCharCode(CHAR_CODE_A + index)})
																	{option.text}
																</label>
															)
														}
													</div>
												</div>
											</div>
										)
									}
								</div>
							</div>
						</div> : this.state.error != '' ? 
						<Error /> :
						<div className='data-loader text-center m-5'>
							<RotatingLines strokeColor="grey"
								strokeWidth="5"
								animationDuration="0.75"
								width="96"
								visible={true} />
						</div>
					}
				</div>
			</div>
		)
	}
}

export default withParams(VoterSurveyView);