import React from "react";
import { RotatingLines } from  'react-loader-spinner';

class RotatingLinesLoader extends React.Component {
    render() {
        return(
            <div className='data-loader text-center m-5'>
                <RotatingLines strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true} />
            </div>
        )
    }
}

export default RotatingLinesLoader;