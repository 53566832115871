import React from "react";
import Constituencies from "../Constituencies";
import Blocks from "../Blocks";
import Villages from "../Villages";
import Designation from '../Designation';
import PollingStations from '../PollingStations';
import { filterFields, getStateConstituencies, getBlocks, getVillages, getPollingStations } from '../../services/Voters';
import { Roles, CONSTITUENCY } from '../../config/defaults';

class ElectionFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      constituencies: [],
      blocks: []
    };
    this.onChangeConstituencies = this.onChangeConstituencies.bind(this);
    this.onChangeBlock = this.onChangeBlock.bind(this);
    this.onChangeVillage = this.onChangeVillage.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);
    this.onChangePollingStation = this.onChangePollingStation.bind(this);
    this.onClickClearFilter = this.onClickClearFilter.bind(this);
  }

  onChangeRole(selectedRole) {
    this.props.onApplyFilter(selectedRole, this.props.selectedConstituency, null, null, null, this.props.constituencies, this.props.blocks, this.props.villages, this.props.pollingStations);
  }

  onClickClearFilter() {
    getStateConstituencies().then((constituencies) => {
      let selectedConstituency = CONSTITUENCY.id;
      getBlocks(selectedConstituency).then((blocks) => {
        this.props.onApplyFilter(null, selectedConstituency, null, null, null, this.props.constituencies, blocks, null, null);
		this.setState({clear: !this.state.clear});
      }).catch((err) => {
        console.log(err);
      })
    }).catch((err) => {
      // alert("Server error, please try again later.");
    });
  }
  
  onChangeConstituencies(selectedConstituency) {
    getBlocks(selectedConstituency).then((blocks) => {
      this.setState({selectedConstituency, blocks});
      this.props.onApplyFilter(this.props.selectedRole, selectedConstituency, null, null, null, this.props.constituencies, blocks, null, null);
    }).catch((err) => {
      console.log(err);
    })
  }

  onChangeBlock(selectedBlock) {
    getVillages(selectedBlock).then((villages) => {
      let selectedConstituency = this.state.selectedConstituency;
      this.setState({selectedConstituency, selectedBlock, villages});
      this.props.onApplyFilter(this.props.selectedRole, this.props.selectedConstituency, selectedBlock, null, null, this.props.constituencies, this.props.blocks, villages, null);
    }).catch((err) => {
      console.log(err);
    })
  }

  onChangeVillage(selectedVillage) {
    getPollingStations(selectedVillage).then((pollingStations) => {
      let selectedConstituency = this.state.selectedConstituency;
      let selectedBlock = this.state.selectedBlock;
      this.setState({selectedVillage, selectedBlock, selectedConstituency, pollingStations});
      this.props.onApplyFilter(this.props.selectedRole, this.props.selectedConstituency, this.props.selectedBlock, selectedVillage, null, this.props.constituencies, this.props.blocks, this.props.villages, pollingStations);
    })
  }

  onChangePollingStation(selectedPollingStation) {
    this.setState({selectedPollingStation});
    this.props.onApplyFilter(this.props.selectedRole, this.props.selectedConstituency, this.props.selectedBlock, this.props.selectedVillage, selectedPollingStation, this.props.constituencies, this.props.blocks, this.props.villages, this.props.pollingStations);
  }

  render() {
    return(
      <div className="row">
        <Designation designationFields={filterFields} userRoles={this.props.userRoles} selectedRole={this.props.selectedRole} onChangeRole={this.onChangeRole} />
        {
          this.props.selectedRole != null ? 
          <>
            {
              this.props.selectedRole.value == Roles.HOUSE_INCHARGE.value || this.props.selectedRole.value == Roles.POLLING_STATION_INCHARGE.value || this.props.selectedRole.value == Roles.BLOCK_INCHARGE.value ? 
                <Constituencies clear={this.state.clear} constituencyFields={filterFields} selectedConstituencyObj={CONSTITUENCY} onChangeConstituencies={this.onChangeConstituencies} constituencies={this.props.constituencies} selectedConstituency={this.props.selectedConstituency} /> : ''
            }
            {
              this.props.selectedRole.value == Roles.BLOCK_INCHARGE.value || this.props.selectedRole.value == Roles.POLLING_STATION_INCHARGE.value || this.props.selectedRole.value == Roles.HOUSE_INCHARGE.value ? 
                <Blocks blockFields={filterFields} blocks={this.props.blocks} selectedConstituency={this.props.selectedConstituency} selectedBlock={this.props.selectedBlock} onChangeBlock={this.onChangeBlock} blockValue={this.state.blockValue} /> : ''
            }
            {
              this.props.selectedRole.value == Roles.POLLING_STATION_INCHARGE.value || this.props.selectedRole.value == Roles.HOUSE_INCHARGE.value ? 
                <Villages villageFields={filterFields} selectedConstituency={this.props.selectedConstituency} selectedBlock={this.props.selectedBlock} selectedVillage={this.props.selectedVillage} villages={this.state.villages} onChangeVillage={this.onChangeVillage} /> : ''
            }
            {
              this.props.selectedRole.value == Roles.HOUSE_INCHARGE.value ? 
                <PollingStations pollingStationFields={filterFields} selectedConstituency={this.props.selectedConstituency} selectedBlock={this.props.selectedBlock} selectedVillage={this.props.selectedVillage} selectedPollingStation={this.props.selectedPollingStation} pollingStations={this.props.pollingStations != null ? this.props.pollingStations : []} onChangePollingStation={this.onChangePollingStation} /> : ''
            }
          </> : ''
        }
        <div className="col-md-2 d-flex align-items-end mt-2">
          <button className="btn btn-danger" onClick={this.onClickClearFilter}>Clear</button>
        </div>
      </div>
    )
  }
}

export default ElectionFilter;