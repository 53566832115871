import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

class Error extends React.Component {
  render() {
    return(
      <div className='text-center text-danger align-middle'>
        <FontAwesomeIcon icon={faTriangleExclamation} className='error-icon' />
        <h3 className='align-item-center error'>Something went wrong. Try again.</h3>
      </div>
    )
  }
}

export default Error;