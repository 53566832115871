import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Header-Style.css';
import { NavLink,Link } from 'react-router-dom';
import { logout } from '../services/Auth';
import {getUserProfile} from '../services/Voters';
import userImage from '../assets/img/usericon.png';
import Popup from 'reactjs-popup';


const Header = (props) => {

  const [user,setUser] = useState();

  const [userInfo,setUserInfo] = useState(null);

const [colleps,setColleps] = useState(true);

const checkUserisLoggedin = () =>{

  getUserProfile().then((UserProfile) =>{

    setUserInfo({UserProfile});
  }).catch((error) => {
    console.log(error);
  });
}

  useEffect(() =>{
    checkUserisLoggedin();
  },[]);

  const handleLogout = () => {
    logout();
    props.onLogout();
  }



function getWindowDimensions() {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const ratio = (width / height) * 100;
  return { width, height, ratio };
}

const { width } = getWindowDimensions();

 const onBtnClick = () =>{
    setColleps(prevColleps => !prevColleps);
    if (width < 768) {
      props.onCollepaseClick(false);
    } else {
      props.onCollepaseClick(false);
    }
  }

  return(
    <>
  <nav className="main-header navbar navbar-expand navbar-white navbar-light sticky-top">

    <ul className="navbar-nav d-flex">
      <li className="nav-item">
         <a className="nav-link" data-widget="pushmenu" href="#" role="button" onClick={onBtnClick}><i className="fas fa-bars"></i></a>
      </li>
    </ul>
      <ul className="navbar-nav d-flex  px-3 btn btn-primary bg-primary ms-auto">
        <Popup trigger={<li className="nav-item d-sm-inline-block px-2">
          <img src={userImage} className='rounded mx-auto d-block' alt="" width={30} height={30} />
      </li>} modal>


            <div className="container d-flex justify-content-center align-items-center">
             
             <div className="card model-card">

              <div className="upper">

                <img src="https://i.imgur.com/Qtrsrk5.jpg" className="img-fluid" />
                
              </div>

              <div className="user text-center">

                <div className="profile">

                  <img src={userImage} className="rounded-circle" width={80} />
                  
                </div>
              </div>


              <div className="mt-5 text-center">
                <h4 className="mb-0"><Link as={Link} className="text-secondary no-underline" to="/user-profile">{userInfo?.UserProfile?.user?.name ? `${userInfo?.UserProfile?.user?.name}` : `${userInfo?.UserProfile?.user?.mobile_number}`} </Link></h4>
                <button className='btn btn-primary btn-sm follow mt-3' onClick={handleLogout}>Logout</button>
                <div className="d-flex justify-content-between align-items-center mt-4 px-4">
                </div>
              </div>
            </div>
           </div>
      </Popup>
      </ul>

  </nav>


</>

    );
}

export default Header;