import {React, useState,useEffect } from 'react';
import Combobox from 'react-widgets/Combobox';
import { getSmsSpecificTemplate, getVillageSmsTemplate, getSubstitutionOptions, createSendSMS } from '../../services/Voters';
import {Operations} from '../toast/operations';
import StatusToast from "../toast/StatusToast";
import { getSettings, updateSettings } from '../../services/Voters';




function ViewBirthdayAlert(props){

	const [templateId, setTemplateId] = useState();
	const [smsTemplate, setSmsTemplate] = useState();
	const [smsTemplates,setSmsTemplates] = useState();
	const [smsTemplateId, setSmsTemplateId] = useState();
	const [smsVar , setSmsVar] = useState([]);
	const [substitutionOptions ,setSubstitutionOptions] = useState([]);
  	const [variables, setVariables] = useState([]);
  	const [newSettings, setNewSettings] = useState({});
  	const [defaultSettings, setDefaultSettings] = useState({});
  	const [updateStatus, setUpdateStatus] = useState();
	const [preloadSmsTemplate, setPreloadSmsTemplate] = useState([]);


	useEffect(() =>{

		getVillageSmsTemplate().then((smsTemplate) => {
			setSmsTemplates(smsTemplate);
    	}).catch((smsError) => {
      		console.log(smsError);
    	})


    	getSubstitutionOptions().then((substitutionOptions) => {
      		setSubstitutionOptions(substitutionOptions);
    	}).catch((err) => {
      		console.log(err);
    	})


    	getSettings(props.group).then((settings) => {

			getSmsSpecificTemplate(settings.settings[4].value).then((preloadSmsTemplate) => {
				setPreloadSmsTemplate(preloadSmsTemplate);

				let str = preloadSmsTemplate.message;
				let regex = /\{\#[a-zA-Z0-9]+\#\}/g;
				let result = str.match(regex);				
				setSmsVar(result);

				
				var val =  settings.settings[5].value.split(",");


				for(var i = 0; i < val.length; i++){

					if (i % 2 != 0) {
						var cut = val[i].split(":");
						console.log(cut,'cut');
					}
				}




				}).catch((err) => {
				console.log(err);
			})

            }).catch((error) => {
                console.log(error,'error');
      	})

	},[]);

	const handleSmsTemplate = (event) => {
	    let str = event.message;
	    let regex = /\{\#[a-zA-Z0-9]+\#\}/g;
	    let result = str.match(regex);
	    setTemplateId(event.template_id);
	    setSmsTemplate(event);
	    setSmsVar(result);
  	}

  	const handleChangeSmsTemplate = (event) => {
  		 setSmsTemplateId(event);
  	}

  	const handleVaribleValue = (obj, variableId) => {
    	let varia = variables;
   		let varib = {'name': variableId, 'field': obj.id};
    	varia = [...varia, varib];
    	setVariables(varia);
  	}

  	const handleSendSms = () => {

			let settings = {
					'4':{	
						id: 4,
						name: 'template_id',
						type: 'TEXT',
						value: templateId,
					},
					'5':{
						id: 5,
						name: 'config',
						type: 'TEXT',						
						value: variables,
					}
			};

	     let finalSettings = {
            group: {
            	id: 3,
            	name: 'Birthday Alerts',
            	slug: 'birthday-alerts',
            },
            settings
        }
	    	updateSettings(props.group, finalSettings).then((updateStatus) => {
            getSettings(props.group).then((settings) => {
            	setNewSettings({...settings});
            	setDefaultSettings({...settings});
            	setUpdateStatus(updateStatus);
            }).catch((error) => {
                console.log(error,'error');
            })
        }).catch((updateError) => {
            console.log(updateError ,'update error');
        });

  	}

	return(
		<>
			<StatusToast status='Voter Survey' delayTime="3000"/>
			<div className="col-md-8 col-lg-8 col-sm-12 m-auto">
				<div className='card mt-4'>
					<div className="card-header">
						<h4 className='d-inline'>Birthday Alert Template</h4>
					</div>
					<div className='card-body'>
					<div className='me-2'>
					<Combobox data={smsTemplates} placeholder={`Select Template.`} options={smsTemplates} dataKey='id' textField='template_id' onSelect={handleSmsTemplate} onChange={handleChangeSmsTemplate} />
					<textarea className='form-control mt-2' name="meassage" id="meassage" value={preloadSmsTemplate.message} rows="5" disabled></textarea>
					{
                        smsTemplate != null || preloadSmsTemplate != null ? 
                        <>
                          <div className='me-auto'>
                          {
                            smsVar != null ? 
                            <table className='table table-borderless'>
                              <thead>
                                <tr className='text-center'>
                                  <th>Variable</th>
                                  <th>Substitute With</th>
                                </tr>
                              </thead>
                              <tbody>
                              {
                               	smsVar?.map((item, index) => 
                                <tr key={index}>
                                  <td>{item}:</td>
                                  <td>
                                    <Combobox placeholder={`Select Variable.`} className='ms-2' data={substitutionOptions} options={substitutionOptions} dataKey='id' textField='name' onSelect={(event) => handleVaribleValue(event, item)} />
                                  </td>
                                </tr>
                                )
                              }
                              </tbody>
                            </table> : ''
                          }
                          </div>
                          <button className="btn btn-success" onClick={handleSendSms}>Save</button>
                        </> : ''
          }
					</div>
				</div>
			</div>
		</div>
		</>
	);

}
export default ViewBirthdayAlert;