import React from 'react';
import '../../assets/css/HouseIncharges-style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilterCircleXmark, faFilter, faPlus, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import InchargesFilter from './InchargesFilter';
import InchargesTable from './InchragesTable';
import { Link  } from 'react-router-dom';
import { getIncharges } from '../../services/Voters';


class Incharges extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilterSection: true,
      value: '',
      incharges: null,
      selectedConstituency: 86,
      selectedBlock: null,
      selectedVillage: null,
      selectedPollingStation: null,
      constituencies: [],
      blocks: [],
      villages: [],
      pollingStations: [],
      selectedConstituencyObj: { "id": 86, "name": "Munugode" },
      inchargeTableLoader: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.onApplyFilter = this.onApplyFilter.bind(this);
  }

  onApplyFilter(selectedConstituency, selectedBlock, selectedVillage, selectedPollingStation, constituencies, blocks, villages, pollingStations, selectedConstituencyObj) {
    this.setState({selectedConstituency, selectedBlock, selectedVillage, selectedPollingStation, constituencies, blocks, villages, pollingStations, selectedConstituencyObj});
  }

  handleChange(event) {
    this.setState({value: event.target.value, showFilterSection: !this.state.showFilterSection});
  }

  componentDidMount() {
    this.setState({incharges: null, inchargeTableLoader: true});
    getIncharges().then((incharges) => {
      this.setState({incharges, inchargeTableLoader: false});
    }).catch((err) => {
      this.setState({inchargeTableLoader: false});
      console.log(err);
    })
  }


  render() {
    return(
        <>
          <div className='pt-3 pb-3'>
            <div className='d-inline'>
              <h4 className='d-inline'>Incharges </h4>
              <div className='d-inline rounded-3 bg-success p-2' onClick={this.handleChange}><FontAwesomeIcon icon={this.state.showFilterSection ? faFilterCircleXmark : faFilter } /></div>
            </div>
            <div className='d-inline float-end'>
              <Link to="/add-incharge" className='btn btn-success'><FontAwesomeIcon icon={faPlus} /> Add</Link>
            </div>
          </div>
          {this.state.showFilterSection && <InchargesFilter onApplyFilter={this.onApplyFilter} selectedConstituency={this.state.selectedConstituency} selectedBlock={this.state.selectedBlock} selectedVillage={this.state.selectedVillage} selectedPollingStation={this.state.selectedPollingStation} constituencies={this.state.constituencies} blocks={this.state.blocks} villages={this.state.villages} pollingStations={this.state.pollingStations} selectedConstituencyObj={this.state.selectedConstituencyObj} /> }
          <hr />
          <InchargesTable incharges={this.state.incharges} inchargeTableLoader={this.state.inchargeTableLoader} />
        </>
    );
  }
}

export default Incharges;