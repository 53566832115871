import React from "react";
import { Link } from "react-router-dom";
import { getSearchVoter, getUserRoles, postCreateUser} from '../../services/Voters';
import { handleInputTelNumber } from '../helper/Validations'
import { Combobox } from "react-widgets";
import withParams from "../GetParams";
import {Operations} from '../toast/operations';
import StatusToast from "../toast/StatusToast";


class NewVolunteer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voterList: [],
      volunteeFormDetail:[],
      isDisable:'',
      roles: null,
      selectedRole: null,
      roleError: null,
      nameError: null,
      mobileNumberError: null,
      emailError: null,
      voterDetailsError: null,
      name: '',
      mobileNumber: '',
      email: '',
      linkVoter: false,
      voterDetails: null,
      password: '',
      passwordError: null
    };

    this.saveVolunteerDetails = this.saveVolunteerDetails.bind(this);
    this.handleRole = this.handleRole.bind(this);
    this.handleName =this.handleName.bind(this);
    this.handleMobileNumber =this.handleMobileNumber.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleLinkVoter = this.handleLinkVoter.bind(this);
    this.handleVoter = this.handleVoter.bind(this);
    this.handleVoterDetails = this.handleVoterDetails.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
  }

  handleName(event) {
    this.setState({name: event.target.value, nameError: ''});
  }

  handleMobileNumber(event) {
    const validateMobile = (email) => {
      return String(email)
        .toLowerCase()
        .match(
        /^[0-9]{10}$/
        );
      };
      if(validateMobile(event.target.value)) {
        this.setState({mobileNumber: event.target.value, mobileNumberError: ''});
      }
      else {
        this.setState({mobileNumber: event.target.value, mobileNumberError: 'Please enter valid mobile number.'});
      }
    
  }

  handleEmail(event) {
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      };
      if(validateEmail(event.target.value)) {
        this.setState({email: event.target.value, emailError: ''});
      }
      else {
        this.setState({email: event.target.value, emailError: 'Please enter valid email.'})
      }
  }

  handleLinkVoter(event) {
    this.setState({linkVoter: !event, voterDetails: null});
  }

  handleVoter(event) {
    if(event == '') {
      this.setState({voterDetails: null});
    }
    getSearchVoter(event).then((voterList) => {
      this.setState({voterList});
    }).catch((err) => {
      throw err;
    })
  }

  handleVoterDetails(event) {
    this.setState({voterDetails: event, voterDetailsError: null});
  }

  handlePassword(event) {
    const validatePassword = (email) => {
      return String(email)
        .match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
        );
      };
      if(validatePassword(event.target.value)) {
        this.setState({password: event.target.value, passwordError: ''});
      }
      else {
        this.setState({password: event.target.value, passwordError: 'Please enter valid password. EX: Abcd@1234'});
      }
  }

  saveVolunteerDetails(event){
    event.preventDefault();

    if(this.state.name == '') {
      this.setState({nameError: "Please fill name." });
    }
    if(this.state.mobileNumber == '') {
      this.setState({mobileNumberError: "Please Fill mobile number." });
    }
    if(this.state.email == '') {
      this.setState({emailError: "Please Fill email." });
    }
    if(this.state.selectedRole == null) {
      this.setState({roleError: "Please select the role." })
    }

    if(this.state.password == '') {
      this.setState({passwordError: 'Please enter the password.'});
    }
    if(this.state.linkVoter == true && this.state.voterDetails == null) {
      this.setState({voterDetailsError: 'Please search and select voter.'});
    }
    else if(this.state.name != '' && this.state.mobileNumber != '' && this.state.email != '' && this.state.emailError != null && this.state.selectedRole != null && this.state.password != '' && this.state.pass == null) {
      let userCreation = {'name': this.state.name, 'mobile_number': this.state.mobileNumber, 'email': this.state.email, 'voter': this.state.voterDetails, 'password': this.state.password, 'role': this.state.selectedRole };
      postCreateUser(userCreation).then((success) => {
        this.props.navigate(`/user-assign/${success.id}/?op=${Operations.CREATE_USER}&status=success`);
      }).catch((error) => {
        this.setState({error});
        this.props.navigate(`/new-volunteer/?op=${Operations.CREATE_USER}&status=error`);
      })
    }
  }

  componentDidMount() {

    getUserRoles().then((roles) => {
      this.setState({roles});
    }).catch((error) => {
      this.setState({error});
    })

  }

  handleRole(event) {
    this.setState({selectedRole: event.target.value, roleError: null});
  }

  render() {
    return(
      <>
        <StatusToast delayTime="3000"/>
          <div className="card">
            <div className="card-body">
              <form>
                <table className="table dataTable dtr-inline">
                  <thead>
                    <tr>
                      <th colSpan={2} className="text-center border-top-0 fs-4">Create Election Volunteer</th>
                    </tr>
                    <tr>
                      <td className="table-form-label-display align-middle fw-bold dtr-control sorting_1">Name</td>
                      <td className="w-50">
                        <label htmlFor="name" className="d-block d-sm-none d-md-none d-lg-none fw-bold">Name</label>

                        <input className="form-control" type="text" placeholder="Name" value={this.state.name} onChange={this.handleName}  />
                        <span className="text-danger fw-600">{this.state.nameError && this.state.nameError}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="table-form-label-display align-middle fw-bold dtr-control sorting_1">Mobile</td>
                      <td>
                        <label htmlFor="mobile" className="d-block d-sm-none d-md-none d-lg-none fw-bold">Mobile</label>
                        <input className="form-control" type="tel" placeholder="Mobile" value={this.state.mobileNumber} onChange={this.handleMobileNumber} onKeyPress={(event) => { handleInputTelNumber(event) }} maxLength={10} minLength={10} />
                        <span className="text-danger fw-600">{this.state.mobileNumberError && this.state.mobileNumberError}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="table-form-label-display align-middle fw-bold dtr-control sorting_1">Email Id</td>
                      <td>
                        <label htmlFor="emailid" className="d-block d-sm-none d-md-none d-lg-none fw-bold">Email Id</label>
                        <input className="form-control" type="email" placeholder="Email Id" value={this.state.email} onChange={this.handleEmail} />
                        <span className="text-danger fw-600">{this.state.emailError && this.state.emailError}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="table-form-label-display align-middle fw-bold dtr-control sorting_1">Password</td>
                      <td>
                        <label htmlFor="password" className="d-block d-sm-none d-md-none d-lg-none fw-bold">Password</label>
                        <input type="text" id="password" name="password" placeholder="Passsword" className="form-control" value={this.state.password} onChange={this.handlePassword} />
                        <span className="text-danger fw-600">{this.state.passwordError && this.state.passwordError}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="table-form-label-display align-middle fw-bold dtr-control sorting_1">Role</td>
                      <td>
                        <label htmlFor="role" className="d-block d-sm-none d-md-none d-lg-none fw-bold">Role</label>
                        <select name="role" id="role" className="form-select" onChange={this.handleRole}>
                          <option value="">Select Role</option>
                          {
                            this.state.roles?.map(role => 
                              <option key={role.value} value={role.value} >{role.name}</option>
                            )
                          }
                        </select>
                        <span className="text-danger fw-600">{this.state.roleError && this.state.roleError}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="table-form-label-display align-middle fw-bold dtr-control sorting_1">Link Voter Id</td>
                      <td>
                        <label htmlFor="role" className="d-block d-sm-none d-md-none d-lg-none fw-bold">Role</label>
                        <input type="checkbox" name="linkVoter" id="linkVoter" defaultChecked={this.state.linkVoter == true ? `checked` : ``} onChange={ event => (this.handleLinkVoter(this.state.linkVoter))} />
                      </td>
                    </tr>
                    {
                      this.state.linkVoter == true ? 
                      <tr>
                          <td className="table-form-label-display align-middle fw-bold dtr-control sorting_1">Voter Id</td>
                          <td>
                            <label htmlFor="voterId" className="d-block d-sm-none d-md-none d-lg-none fw-bold">Voter Id</label>
                            <Combobox data={this.state.voterList} option={this.state.voterList} dataKey="id" textField="id_card_number" onChange={this.handleVoter} onSelect={this.handleVoterDetails} />
                            <span className="text-danger fw-600">{this.state.voterDetailsError && this.state.voterDetailsError}</span>
                          </td>
                        </tr> : ''
                    }
                    {
                      this.state.voterDetails != null && this.state.linkVoter == true ? 
                      <>
                      <tr>
                          <td className="table-form-label-display align-middle fw-bold dtr-control sorting_1">Name</td>
                          <td>
                            <label htmlFor="name" className="d-block d-sm-none d-md-none d-lg-none fw-bold">Name</label>
                            <input type="text" value={`${this.state.voterDetails?.first_name == null ? '' : this.state.voterDetails?.first_name} ${this.state.voterDetails?.last_name == null ? '' : this.state.voterDetails?.last_name }`} className='form-control' disabled />
                          </td>
                        </tr>
                        <tr>
                          <td className="table-form-label-display align-middle fw-bold dtr-control sorting_1">Assembly Constituency</td>
                          <td>
                            <label htmlFor="assemblyconstituency" className="d-block d-sm-none d-md-none d-lg-none fw-bold">Assembly Constituency</label>
                            <input type="text" value={this.state.voterDetails?.polling_station.constituency.name} className='form-control' disabled />
                          </td>
                        </tr>
                        <tr>
                          <td className="table-form-label-display align-middle fw-bold dtr-control sorting_1">Block</td>
                          <td>
                            <label htmlFor="block" className="d-block d-sm-none d-md-none d-lg-none fw-bold">Block</label>
                            <input type="text" value={this.state.voterDetails?.polling_station.village.block.name} className='form-control' disabled />
                          </td>
                        </tr>
                        <tr>
                          <td className="table-form-label-display align-middle fw-bold dtr-control sorting_1">Village</td>
                          <td>
                            <label htmlFor="village" className="d-block d-sm-none d-md-none d-lg-none fw-bold">Village</label>
                            <input type="text" value={this.state.voterDetails?.polling_station.village.name} className='form-control' disabled />
                          </td>
                        </tr> </> : ''
                    }
                    <tr>
                      <td className="table-form-label-display align-middle fw-bold dtr-control sorting_1"></td>
                      <td className="text-end">
                        <Link to={ `/elections` } className="btn btn-danger mx-1">Cancel</Link>
                        <button className="btn btn-success mx-1" onClick={this.saveVolunteerDetails}>Save and Next</button>
                      </td>
                    </tr>
                  </thead>
                </table>
              </form>
            </div>
          </div>
        </>
    )
  }
}

export default withParams(NewVolunteer);