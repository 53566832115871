import React from "react";

class UpdateTraditionalVotingTrend extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			traditionalVotingTrend: []
		}
	}
	
	componentDidMount() {
		this.setState({traditionalVotingTrend: this.props.traditionalVotingTrend});
	}

	handleTraditionalVoting = (event, id) => {
		this.setState({
			traditionalVotingTrend: this.state.traditionalVotingTrend?.map(item => (item.id == id ? {...item, traditionalVotingTrend: event.target.value} : item))
		});
	}

	render() {
		return(
			<div className='overflow-x-auto'>
				<table className='table'>
					<thead>
						<tr>
							<th className='text-center bg-secondary bg-opacity-75' colSpan={2}>Perception Analysis</th>
						</tr>
						<tr className='bg-secondary bg-opacity-25 text-dark'>
							<th className='w-5 text-center'>Sl. No.</th>
							<th className='text-center'>Traditional Voting Trend in the village</th>
						</tr>
					</thead>
					<tbody>
					{
						this.state.traditionalVotingTrend?.map((traditionalVoting, index) => (
							<tr key={traditionalVoting.id}>
								<td className='text-center'>{index + 1}</td>
								<td>
									<textarea className="form-control" name="traditionalVotingTrend" id="traditionalVotingTrend" onChange={(e) => this.handleTraditionalVoting(e, traditionalVoting.id)} defaultValue={traditionalVoting.traditionalVotingTrend} ></textarea>
								</td>
							</tr>
						))
					}
					</tbody>
				</table>
			</div>
		)
	}
}

export default UpdateTraditionalVotingTrend;