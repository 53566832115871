import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
class NotFound extends React.Component {
  render() {
	return(
		<div className="text-center text-danger align-middle pt-p-20">
			<FontAwesomeIcon icon={ faTriangleExclamation } className='fs-1' />
			<h3>404 Not Found</h3>
			<h5>The link you have followed is broken.</h5>
			Go <Link to="/">Home</Link>
		</div>
	)
  }
}

export default NotFound;