import React from "react";
import { getSettings, updateSettings } from '../../services/Voters';
import Error from '../error';
import RotatingLineLoader from "../RotatingLineLoader";
import withParams from '../GetParams';
import {Operations} from '../toast/operations';
import StatusToast from "../toast/StatusToast";

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newSettings: {},
            defaultSettings: {},
            error: null,
            updateStatus: null,
            updateError: null
        };
        this.handleCheckBox = this.handleCheckBox.bind(this);
        this.handleText = this.handleText.bind(this);
        this.handleNumber = this.handleNumber.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDecimal = this.handleDecimal.bind(this);
    }

    handleSubmit() {
        const defaultSettings = this.state.defaultSettings;
        const newSettings = this.state.newSettings;
        let settings = {};
        Object.keys(defaultSettings.settings).map(key => {
            if(defaultSettings.settings[key].value != newSettings.settings[key].value) {
                settings[key] = {...newSettings.settings[key]};
            }
        })
        let finalSettings = {
            group: newSettings.group,
            settings
        }

        updateSettings(this.props.group, finalSettings).then((updateStatus) => {
            getSettings(this.props.group).then((settings) => {
                this.setState({newSettings: {...settings}, defaultSettings: {...settings}, updateStatus});
            }).catch((error) => {
                this.setState({error})
            })
            this.props.navigate(`/${this.props.path}?op=${this.props.operations}&status=success`)
        }).catch((updateError) => {
            this.setState({updateError});
            this.props.navigate(`/${this.props.path}?op=${this.props.operations}&status=error`);
        })
    }

    handleNumber(event, obj) {
        let settings = {...this.state.newSettings.settings};
        settings[obj.id] = {'id': obj.id, 'name': obj.name, 'type': obj.type, 'value': parseInt(event.target.value ? event.target.value : 0)};
        this.setState({newSettings: {
            group: this.state.newSettings.group,
            settings
        }});
    }

    handleDecimal(event, obj) {
        let settings = {...this.state.newSettings.settings};
        settings[obj.id] = {'id': obj.id, 'name': obj.name, 'type': obj.type, 'value': parseFloat(event.target.value ? event.target.value : 0)};
        this.setState({newSettings: {
            group: this.state.newSettings.group,
            settings
        }});
    }

    handleText(event, obj) {
        let settings = {...this.state.newSettings.settings};
        settings[obj.id] = {'id': obj.id, 'name': obj.name, 'type': obj.type, 'value': event.target.value};
        this.setState({newSettings: {
            group: this.state.newSettings.group,
            settings
        }});
    }

    handleCheckBox(event, obj) {
        let settings = {...this.state.newSettings.settings};
        settings[obj.id] = {'id': obj.id, 'name': obj.name, 'type': obj.type, 'value': event.target.checked};
        this.setState({newSettings: {
            group: this.state.newSettings.group,
            settings
        }});
    }
    
    componentDidMount() {
        getSettings(this.props.group).then((settings) => {
            this.setState({newSettings: {...settings}, defaultSettings: {...settings}})
        }).catch((error) => {
            this.setState({error})
        })
    }
    
    render() {
        const settings = this.state.newSettings?.settings;
        const settingsKeys = Object.keys(settings ? settings : []);
        return(
            <>
                <StatusToast status='Voter Survey' delayTime="3000"/>
                <div className="card">
                <div className="card-body">
                    {
                        Object.keys(this.state.newSettings).length > 0 ? 
                        <div>
                            <table className="table">
                                <tbody>
                                { settingsKeys?.map(key => 
                                    
                                        this.state.newSettings?.settings[key].type == 'TEXT' ? 
                                        <tr key={this.state.newSettings?.settings[key].id}>
                                            <td className='d-none d-sm-block'>{this.state.newSettings?.settings[key].name}</td>
                                            <td>
                                            <label htmlFor="" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>{this.state.newSettings?.settings[key].name}</label>
                                            <input type="text" name={`text-${this.state.newSettings?.settings[key].id}`} id={`text-${this.state.newSettings?.settings[key].id}`} className="form-control" value={this.state.newSettings?.settings[key].value} onChange={(event) => this.handleText(event, this.state.newSettings?.settings[key])} />
                                            </td>
                                        </tr> : 
                                        this.state.newSettings?.settings[key].type == 'INTEGER' ? 
                                        <tr key={this.state.newSettings?.settings[key].id}>
                                            <td className='d-none d-sm-block'>{this.state.newSettings?.settings[key].name}</td>
                                            <td>
                                                <label htmlFor="" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>{this.state.newSettings?.settings[key].name}</label>
                                                <input type="number" name={`integer-${this.state.newSettings?.settings[key].id}`} id={`integer-${this.state.newSettings?.settings[key].id}`} className="form-control" value={this.state.newSettings?.settings[key].value > 0 ? this.state.newSettings?.settings[key].value : 0 } onChange={(event) => this.handleNumber(event, this.state.newSettings?.settings[key])} />
                                            </td>
                                        </tr> : 
                                        this.state.newSettings?.settings[key].type == 'BOOLEAN' ? 
                                        <tr key={this.state.newSettings?.settings[key].id}>
                                            <td className='d-none d-sm-block'>{this.state.newSettings?.settings[key].name}</td>
                                            <td>
                                                <input type="checkbox" name={`checked-${this.state.newSettings?.settings[key].id}`} id={`checked-${this.state.newSettings?.settings[key].id}`} className="form-check-input ms-1" checked={this.state.newSettings?.settings[key].value} onChange={(event) => this.handleCheckBox(event, this.state.newSettings?.settings[key])} />
                                                <label htmlFor="" className='d-block d-sm-none d-md-none d-lg-none fw-bold ms-4'>{this.state.newSettings?.settings[key].name}</label>
                                            </td>
                                        </tr> : 
                                        this.state.newSettings?.settings[key].type == 'DECIMAL' ? 
                                        <tr key={this.state.newSettings?.settings[key].id}>
                                            <td className='d-none d-sm-block'>{this.state.newSettings?.settings[key].name}</td>
                                            <td>
                                                <label htmlFor="" className='d-block d-sm-none d-md-none d-lg-none fw-bold'>{this.state.newSettings?.settings[key].name}</label>
                                                <input type="number" step='0.01' name={`decimal-${this.state.newSettings?.settings[key].id}`} id={`decimal-${this.state.newSettings?.settings[key].id}`} className="form-control" value={this.state.newSettings?.settings[key].value > 0 ? this.state.newSettings?.settings[key].value : 0 } onChange={(event) => this.handleDecimal(event, this.state.newSettings?.settings[key])} />
                                            </td>
                                        </tr> : ''
                                    )}
                                </tbody>
                            </table>
                            <div className="col-12 text-center">
                                <button className="btn btn-success" onClick={this.handleSubmit}>Submit</button>
                            </div>
                        </div> : Object.keys(this.state.newSettings).length == 0 ? 
                        <div className="text-center fs-6 fw-500 text-bark-50">No settings found</div> : this.state.error != null ? <Error /> : 
                        <RotatingLineLoader />
                    }
                </div>
            </div>
            </>
        )
    }
}

export default withParams(Settings);