import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import React from "react";

function mobileNumberValidation(mobileNumber) {
  let mob = /^\d{10}$/;
  if(!mobileNumber.match(mob) && mobileNumber != '') {
    return 'Please enter a valid number';
  }
}

function emailValidation(email) {
  let emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if(!email.match(emailRegex) && email != '') {
    return 'Please enter a valid email id';
  }
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function dateValidation(date) {
  var now = new Date();
  let nowYear = now.getFullYear();
  let nowDay = padTo2Digits(now.getDate());
  let nowMonth = padTo2Digits(now.getMonth() + 1);
  let nowDate = [nowYear, nowMonth, nowDay].join('-');
  let pattern =/^\d{4}-\d{2}-\d{2}$/;
  if(!date.match(pattern) && date != '') {
    return 'Please enter a valid date';
  }
  else if(date > nowDate) {
    return 'Date should not be greater than today';
  }
  else {
    return '';
  }
}

function generateRandomDarkColor() {
  let randoms = [];
  for (let i = 0; i < 3; i++)
    randoms.push(
      Math.floor(Math.random() * 200)
        .toString(16)
        .padStart(2, "0")
    );
  return `#${randoms.join("")}`;
}

function sumArray(arr) { 
  let sum = 0; 
  for (let i = 0; i < arr?.length; i++) 
      sum += arr[i]; 

  return sum; 
}

function handleInputTelNumber(event) {
  if (!/[0-9]/.test(event.key)) { event.preventDefault(); }
}

function getUserRoleName(list, role) {
  for(let i=0;i<list.length;i++) {
    if(list[i].value == role) {
      let Name = list[i].name;
      return Name;
    }
}
}

export {mobileNumberValidation, emailValidation, dateValidation, generateRandomDarkColor, sumArray, handleInputTelNumber, getUserRoleName};