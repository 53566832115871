import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
class AccessDenied extends React.Component {
	render() {
		return(
			<div className="text-center text-danger align-middle pt-p-20">
				<FontAwesomeIcon icon={ faTriangleExclamation } className='fs-1' />
				<h3>Access denied</h3>
				<h5>You don’t have permission to access this page</h5>
				Go to <Link to="/login">Login Page</Link>
			</div>
		)
	}
}

export default AccessDenied;