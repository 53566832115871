import React from "react";
import { getVillageProfile, getAllVillages, getOpinion, getCasteList } from '../../services/Voters';
import withParams from '../GetParams';
import UpdateCasteAnalysis from './UpdateCasteAnalysis';
import UpdateTraditionalVotingTrend from './UpdateTraditionalVotingTrend';
import UpdatePresentVotingTrend from './UpdatePresentVotingTrend';
import UpdatePartyPerceptionAnalysis from './UpdatePartyPerceptionAnalysis';
import UpdateLeaderPerceptionAnalysis from './UpdateLeaderPerceptionAnalysis';
import UpdateIssuesActionables from './UpdateIssuesActionables';
import UpdatePoachableLeaders from './UpdatePoachableLeaders';
import UpdateNeutralInfluencers from './UpdateNeutralInfluencers';
import { Link } from 'react-router-dom';
import RotatingLineLoder from '../RotatingLineLoader';
import Error from '../error';

class VillageProfileUpdate extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			villageProfile: null,
			villageProfileError: null,
			id: '',
			villageList: [],
			err: '',
			opinionList: [],
			casteList: [],
			loader: false
		}
		this.handleReset = this.handleReset.bind(this);
	}

	componentDidMount() {
		this.setState({villageProfile: null, loader: true});
		let { id } = this.props.params;
		getVillageProfile(id).then((villageProfile) => {
			this.setState({villageProfile, id, loader: false});
		}).catch((villageProfileError) => {
			this.setState({villageProfileError, loader: false});
		})

		getAllVillages().then((villageList) => {
			this.setState({villageList});
		}).catch((err) => {
			this.setState({err});
		})

		getOpinion().then((opinionList) => {
			this.setState({opinionList});
		}).catch((err) => {
			this.setState({err});
		})

		getCasteList().then((casteList) => {
			this.setState({casteList});
		}).catch((err) => {
			this.setState({err});
		})
	}

	handleReset() {
		this.setState({villageProfile: null, loader: true})
		getVillageProfile(this.state.id).then((villageProfile) => {
			this.setState({villageProfile, loader: false});
		}).catch((villageProfileError) => {
			this.setState({villageProfileError, loader: false});
		})
	}
    render() {
        const villageProfile = this.state.villageProfile;
        return (
			<div className="card">
				<div className="card-body">
					{
						this.state.villageProfile != null ? 
							<div>
								<div className='text-center fw-700 fs-4'>Village Profile</div>
								<div className='overflow-x-auto'>
									<table className="table">
										<thead>
											<tr>
												<th className='text-center bg-secondary bg-opacity-75' colSpan={2}>Demographics</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Village</td>
												<td>{villageProfile?.demographics?.village.name}</td>
											</tr>
											<tr>
												<td>Block</td>
												<td>{villageProfile.demographics?.block.name}</td>
											</tr>
											<tr>
												<td>Population</td>
												<td>{villageProfile.demographics?.population}</td>
											</tr>
											<tr>
												<td>Voter</td>
												<td>{villageProfile.demographics?.voters}</td>
											</tr>
											<tr>
												<td>Category</td>
												<td>{villageProfile.demographics?.category.name}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<UpdateCasteAnalysis villageProfile={villageProfile}/>
								<UpdateTraditionalVotingTrend traditionalVotingTrend={villageProfile?.perceptionAnalysis?.traditionalVotingTrend} />
								<UpdatePresentVotingTrend presentVotingTrend={villageProfile?.perceptionAnalysis?.presentVotingTrend} />
								<UpdatePartyPerceptionAnalysis partyPerceptionAnalysis={villageProfile?.perceptionAnalysis?.partyPerceptionAnalysis} />
								<UpdateLeaderPerceptionAnalysis leaderPerceptionAnalysis={villageProfile.perceptionAnalysis?.leaderPerceptionAnalysis} />
								<UpdateIssuesActionables issuesActionables={villageProfile.issuesActionables} />
								<UpdatePoachableLeaders poachableLeaders={villageProfile.poachableLeaders} />
								<UpdateNeutralInfluencers neutralInfluencers={villageProfile.neutralInfluencers} />
								<div className='float-right'>
									<Link to={`/village-profile-view/${villageProfile.id}`} className='btn btn-warning me-2 text-white'>Cancel</Link>
									<button type="reset" className='btn btn-info text-white me-2' onClick={this.handleReset}>Reset</button>
									<button className='btn btn-success'>Update</button>
								</div>
							</div>
					: this.state.loader != false ? <RotatingLineLoder /> : this.state.villageProfileError != null ? <Error /> : <div>No data found</div>}
				</div>
			</div>
        )
    }
}

export default withParams(VillageProfileUpdate);