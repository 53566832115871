import {useState, useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { changePassword } from '../../services/Voters';
import { Link , useNavigate} from "react-router-dom";
import {Operations} from '../toast/operations';
import StatusToast from "../toast/StatusToast";
import { logout } from '../../services/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye} from '@fortawesome/free-solid-svg-icons';



function ChangePassword(props){

	const [oldPassword,setOldPassword] = useState();
	const [newPassword,setNewPassword] = useState();
	const [confirmPassword,setConfirmPassword] = useState();

	const [fieldType, setFieldType] = useState('password');

	let navigate = useNavigate();


	const handleSeePassword = () =>{

		if(fieldType == 'password'){
			setFieldType('text');
		}
		else{
			setFieldType('password');
		}

	}



	const formSubmission = (event) =>{
		event.preventDefault();

		if(oldPassword != '' && newPassword != '' && confirmPassword !=''){

			if(newPassword == confirmPassword){

				if(oldPassword != newPassword){
					let passwordObject = {'old_password': oldPassword, 'new_password': newPassword};
					changePassword(passwordObject).then((postData) => {
    					logout();
    					props.onLogout();

					}).catch((err) => {
						console.log(err);
						navigate(`/change-password?op=${Operations.ADD_SURVEY}&status=error`);
					})

				}
				else{
					console.log('your newPassword same as oldPassword');
				}
			}
			else{

				console.log('password and confirmpassword does not same');
				
			}

		}
		else{
			console.log('please fill the password');
		}

	}
	const confirmPasswordchange = (event) =>{

		setConfirmPassword(event.target.value);
	}
	const oldPasswordchange = (event) =>{
		setOldPassword(event.target.value);
	}
	const newPasswordchange = (event) =>{

		setNewPassword(event.target.value);
	}
	

	return (
		<>
		<StatusToast status='Voter Survey' delayTime="3000"/>
		<div className="row">
			<div className="col-md-6 m-auto mt-5">
				<div className="card">
					<div className="card-header">
						<h3>Change Password</h3>
					</div>
					<div className="card-body">
						<Form onSubmit={formSubmission}>
							<InputGroup className="mb-3">
						        <Form.Control
						          placeholder="Old Password"
						          onChange={oldPasswordchange}
						          type="password"
						          autoComplete="new-password"
						        />
			      			</InputGroup>
			      			<InputGroup className="mb-3">
						        <Form.Control
						          placeholder="New Password"
						          onChange={newPasswordchange}
						          type={fieldType}
						          autoComplete="new-password"
						        />
						    <InputGroup.Text id="basic-addon2" onClick={handleSeePassword}><FontAwesomeIcon icon={ faEye } /></InputGroup.Text>
			      			</InputGroup>
			      			<InputGroup className="mb-3">
						        <Form.Control
						          placeholder="Confirm Password"
						          onChange={confirmPasswordchange}
						          autoComplete="new-password"
						        />
			      			</InputGroup>
			      			
			      			<button className="btn btn-success" variant="primary" type="submit">
			        			Change Password
			      			</button>
      					</Form>
					</div>
				</div>
			</div>
		</div>
		</>
	);

} 
export default ChangePassword;