import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import StatusToast from "../toast/StatusToast";
import { emailSubmitPasswordReset } from "../../services/Voters";
import {Operations} from '../toast/operations';
import { useNavigate} from "react-router-dom";



function PasswordReset(){

    const [emailInput, setEmailInput] = useState();
	const [errorLog, setErrorLog] = useState();
	const [isDisable,setIsDisable] = useState(false);
	let navigate = useNavigate();

    const newPasswordchange = (event) =>{
		setErrorLog('');
        setEmailInput(event.target.value);
    }

    const handleSubmit = () =>{
		if(emailInput == null || emailInput == '' || emailInput == undefined){
			setErrorLog('Please Enter Email.');
		}
		else{	
			if(validateEmail(emailInput)){
				setIsDisable(true);
				let passwordObject = {'email': emailInput};
				emailSubmitPasswordReset(passwordObject).then((data) => {
					navigate(`/login?op=${Operations.SEND_PASSWORD_RESET_EMAIL}&status=success`);
				}).catch((err) => {
					setIsDisable(false);
					setErrorLog(err.response.data.email);
				})
			}
			else{
				setErrorLog('Please Enter Valid Email.');
			}	
		}
    }

	const validateEmail = (email) => {
		return String(email)
		  .toLowerCase()
		  .match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		  );
	  };
	  
	const handlePwdKeyUp = (event) => {
		if (event.key === 'Enter') {
		  handleSubmit();
		}
	  }

    return(
        <>
		<StatusToast status='Voter Survey' delayTime="3000"/>

        
<div className="row">
			<div className="col-md-6 m-auto mt-5">
				<div className="card">
					<div className="card-header">
						<h3>Confirm Email</h3>
					</div>
					<div className="card-body">
			      			<InputGroup className="mb-1">
						        <Form.Control
						          placeholder="Enter Email"
						          onChange={newPasswordchange}
						          type="email"
						          autoComplete="new-password"
								  onKeyUp={handlePwdKeyUp}
						        />
			      			</InputGroup>
							<span className="text-danger fw-600 mb-3" style={{marginTop:20}}>{errorLog}</span>
							<br />
			      			<button className="mt-2 btn btn-success" variant="primary" onClick={handleSubmit} disabled={isDisable}>
			        			Send Email
			      			</button>	
					</div>
				</div>
			</div>
		</div>
        </>
    );
}


export default PasswordReset;