import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Toast from 'react-bootstrap/Toast';
import {OPERATION_TOAST_CONFIG} from './operations';

function StatusToast(props){
	
	const [searchParams, setSearchParams] = useSearchParams();

	const op = searchParams.get('op');
	const status = searchParams.get('status');
	let config = {};
	let message = '';
	let bg = 'success';
	
	if(op && status && op in OPERATION_TOAST_CONFIG) {
        config = OPERATION_TOAST_CONFIG[op];
		message = status == 'success' ? config.successMessage : config.failureMessage;
		bg = status == 'error' ? 'danger' : 'success';

		setTimeout(() => {
			setSearchParams('');
		}, props.delayTime);
	}

	const [show, setShow] = useState(true);
	if(op && status && op in OPERATION_TOAST_CONFIG) {
	return(
		<>
			<Toast bg={bg} onClose={() => setShow(false)} show={show} delay={props.delayTime} autohide>
	            <Toast.Header>
	            <strong className="me-auto">{config.heading}</strong>
	            <small>0 seconds ago</small>
	            </Toast.Header>
	            <Toast.Body>{message}</Toast.Body>
	        </Toast>
		</>
	); }
	else return <></>;
}

export default StatusToast