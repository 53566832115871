import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Error from '../error';
import RotatingLineLoader from '../RotatingLineLoader';
import { getUserRoleName } from '../helper/Validations';



class VolunteerTable extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			error: '',
		}
	}


	render() {
		return(
			<div className='card mt-3'>
				<div className='card-body'>
						{
							this.props.users != null ? 
							<div className='overflow-x'>
								<table className='table table-responsive-sm table-striped'>
									<thead>
										<tr className="bg-primary">
											<th>#</th>
											<th>Mobile Number</th>
											<th>Name</th>
											<th>Email</th>
											<th>Role</th>
											<th className='text-center'>Actions</th>
										</tr>
									</thead>
									<tbody>
										{
											this.props.users.map((user, index) => 
												<tr key={ user?.id }>
													<td>{ index + 1 }</td>
													<td>{ user?.mobile_number }</td>
													<td>{ user?.name }</td>
													<td>{ user?.email }</td>
													<td>{ this.props.userRoles != null ? getUserRoleName(this.props.userRoles, user?.role) : 'Role not found' }</td>
													<td className='text-center'><Link to={ `/user-assign/${user.id}` } className='btn btn-success' >Assign</Link> </td>
												</tr>
											)
										}
									</tbody>
								</table>
							</div>
							: this.props.userLoader == true ? <RotatingLineLoader /> : this.state.error != '' ? 
							<Error /> :
							<div className='text-center fw-600 text-black-50'>No data found.
							</div>}
				</div>
			</div>
		)
	}
}

export default VolunteerTable;