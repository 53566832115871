import Toast from 'react-bootstrap/Toast';
import {OPERATION_TOAST_CONFIG} from './operations';

function getToastConfig({searchParams}, show = false) {
    if(searchParams) {
        let op = searchParams.get('op');
		let status = searchParams.get('status');
		if(op && status && op in OPERATION_TOAST_CONFIG) {
            let config = OPERATION_TOAST_CONFIG[op];
			let message = status == 'success' ? config.successMessage : config.failureMessage;
            let bg = status == 'error' ? 'danger' : 'success';
			return {heading: config.heading, message, status, show, bg};
		}
    }
    return {heading: '', message: '', status: '', show: false, bg: null};
}

function getToast(toastConfig) {
    return <Toast bg={toastConfig.bg} show={toastConfig.show}>
                <Toast.Header closeButton={false}>
                <strong className="me-auto">{toastConfig.heading}</strong>
                <small>0 seconds ago</small>
                </Toast.Header>
                <Toast.Body>{toastConfig.message}</Toast.Body>
            </Toast>;
}

export {getToastConfig, getToast};