
import { React, useEffect, useState} from 'react';
import { Link, matchRoutes, useLocation } from "react-router-dom";
import { ProSidebar, SubMenu, MenuItem } from 'react-pro-sidebar';
import CustomLink from './CustomLink';

function checkIfActive(menu, route) {
		if(menu.children?.length == 0)
			return menu.url == route;
		else {
			for(let i=0;i<menu.children?.length;i++) {
				let active = checkIfActive(menu.children[i], route);
				if(active)
					return true;
			}
			return false;
		}
	}

function Menu(props) {

	var route = useLocation()?.pathname;
	const [isActive,setIsActive] = useState(false);

	let active = checkIfActive(props, route);
	
	// useEffect( () =>{
	// 	props.menus.map((item) =>{
	// 		if(props.activeRoute == item.props.link){
	// 			setIsActive(true);
	// 		}
	// 	});
	// },[]);

	const handleParentBtnClick = () =>{
		setIsActive(!isActive);
	}

	if(props.menus.length == 0) {
			return <CustomLink link={props.url} classes={route == props.link ? 'single-nav-link active' : 'single-nav-link'} text={props.name} key={props.name} />
	}
	return(
		<>
			<button className={active || isActive ? "dropdown-btn active-link": "dropdown-btn"} key={props.title} onClick={handleParentBtnClick}>
				{props.title}
    			<i className="fa fa-caret-down"></i>
  			</button>
  			<div className={active || isActive ? "dropdown-container nav-active": "dropdown-container"}>
  				{
	  			 	props.menus.map((item) => {
	  			 			if(item.children.length == 0) {
	  			 				return <Link to={item.url} className={route == item.url ? 'active' : ''} key={item.url}>{item.name}</Link>;	
	  			 			}
	  			 			else{
	  			 				return <Menu key={item.name} name={item.name} title={item.name} children={item.children} menus={item.children} url={item.url} />
	  						}
	  			 	})
  			 	}
 	 		</div>

		</>
	)
}

export default Menu;