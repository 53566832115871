import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Voters.css';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '../Pagination';
import { RotatingLines } from  'react-loader-spinner';
import Error from '../error';
import Combobox from 'react-widgets/Combobox';
import { createSendSMS } from '../../services/Voters';
import {getToastConfig, getToast} from '../toast/toast';
import {Operations} from '../toast/operations';
import StatusToast from "../toast/StatusToast";
import { Link , useNavigate} from "react-router-dom";
import withParams from '../GetParams';

class SmsVoterTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 50,
      smsTemplate: null,
      smsVar: [],
      variables: [],
      smsTemplateId: null
    }
    this.onChangePage = this.onChangePage.bind(this);
    this.handlePageSize = this.handlePageSize.bind(this);
    this.handleSmsTemplate = this.handleSmsTemplate.bind(this);
    this.handleSendSms = this.handleSendSms.bind(this);
    this.handleVar = this.handleVar.bind(this);
    this.handleVaribleValue = this.handleVaribleValue.bind(this);
    this.handleOpenSendSms = this.handleOpenSendSms.bind(this);
    this.handleChangeSmsTemplate = this.handleChangeSmsTemplate.bind(this);
  }

  handleOpenSendSms() {
    this.setState({smsVar: null, smsTemplate: null});
  }

  handleVaribleValue(obj, variableId) {
    let variables = this.state.variables;
    let varib = {'name': variableId, 'field': obj.id};
    variables = [...variables, varib];
    this.setState({variables});
  }

  handleVar(event) {
    this.setState({smsVar : event.target.value});
  }

  handleSendSms() {
    let message = this.state.smsTemplate;
    const sendSms = {'template': message.id, 'filters': this.props.filterData, 'variables': this.state.variables};
    createSendSMS(sendSms).then((data) => {
      this.props.navigate(`/sms-voters?op=${Operations.CREATE_SEND_SMS}&status=success`);
      document.getElementById('close').click();
    }).catch((err) => {
      this.props.navigate(`/sms-voters?op=${Operations.CREATE_SEND_SMS}&status=error`);
    })
  }

  onChangePage(event) {
    this.props.onChangePagenation(event);
  }

  handlePageSize(event) {
    this.setState({pageSize: event.target.value});
    this.props.onChangePageSize(event.target.value);
  }

  handleSmsTemplate(event) {
    let str = event.message;
    let regex = /\{\#[a-zA-Z0-9]+\#\}/g;
    let result = str.match(regex);
    this.setState({smsTemplate: event, smsVar: result});
  }

  handleChangeSmsTemplate(event) {
    this.setState({smsTemplateId: event});
  }
  
  render() {
    let column = this.props.filterShowing ? 9 : 12;
      return(
        <>
          <StatusToast status='Voter Survey' delayTime="3000"/>
        <div className={ `col-md-${column} col-lg-${column} col-sm-12 voter-table` }>
          <div className='card shadow mt-4 me-2'>
          <div className='card-body'>
            <div className="d-flex px-4 pt-3">
              <span className='align-self-center'>Show </span>
              <div className='header-font me-auto'>
                <select name="pageSize" id="pageSize" className='align-self-center ms-2 form-select' onChange={this.handlePageSize}>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="250">250</option>
                  <option value="500">500</option>
                </select>
              </div>
              <div className='me-2'>
                <button type="button" className="btn btn-success" data-toggle="modal" data-target="#exampleModalCenter" onClick={this.handleOpenSendSms}>
                  Send SMS
                </button>
                <div className="modal fade bd-example-modal-lg" id="exampleModalCenter" tabIndex="-1" role="document" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">Send SMS</h5>
                        <div className='ms-4 align-self-center'>Total Count: <span className='text-success'>{this.props.voterTableDetails != '' ? this.props.voterTableDetails.count : 0 }</span></div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" id='close'>
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                          <Combobox data={this.props.smsTemplates} placeholder={`Select Template.`} options={this.props.smsTemplates} dataKey='id' textField='template_id' onSelect={this.handleSmsTemplate} onChange={this.handleChangeSmsTemplate} />
                          {
                            this.state.smsTemplate != null ?
                            <div>
                              <div className='form-group'>
                                <label htmlFor="entityId">Entity id</label>
                                <input type="text" name="entityId" id="entityId" value={this.state.smsTemplate?.entity_id} className='form-control' disabled/>
                              </div>
                              <div className='form-group'>
                                <label htmlFor="meassage">Message</label>
                                <textarea className='form-control mt-2' name="meassage" id="meassage" value={this.state.smsTemplate?.message} rows="5" disabled></textarea>
                              </div>
                            </div>
                             : ''
                          }
                      </div>
                      <div className="modal-footer">
                      {
                        this.state.smsTemplate != null ? 
                        <>
                          <div className='me-auto'>
                          {
                            this.state.smsVar != null ? 
                            <table className='table table-borderless'>
                              <thead>
                                <tr className='text-center'>
                                  <th>Variable</th>
                                  <th>Substitute With</th>
                                </tr>
                              </thead>
                              <tbody>
                              {
                                this.state.smsVar?.map((item, index) => 
                                <tr key={index}>
                                  <td>{item}:</td>
                                  <td>
                                    <Combobox className='ms-2' data={this.props.substitutionOptions} options={this.props.substitutionOptions} dataKey='id' textField='name' onSelect={(event) => this.handleVaribleValue(event, item)} />
                                  </td>
                                </tr>
                                )
                              }
                              </tbody>
                            </table> : ''
                          }
                          </div>
                          <button className="btn btn-danger align-bottom" data-dismiss="modal">Close</button>
                          <button className="btn btn-success" onClick={this.handleSendSms}>Send SMS</button>
                        </> : ''
                      }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='align-self-center'>
                {this.props.voterTableDetails != '' ? this.props.voterTableDetails.count : 0 } records <FontAwesomeIcon icon={faDownload} />
              </div>
            </div>
            <div className='p-3 overflow-x'>
            {this.props.voterTableDetails != '' ?
            <div>
              <table className='table border-top table-responsive-sm table-bordered '>
                <thead>
                  <tr className='fw-600 bg-primary'>
                    <td className='align-middle'>Name</td>
                    <td className='align-middle'>Voter ID</td>
                    <td className='align-middle'>Mobile</td>
                  </tr>
                </thead>
                <tbody>
                {
                  this.props.voterTableDetails.data && this.props.voterTableDetails.data.reduce((items, current) => {
                    let returnList = [
                      <tr key={current[0].id_card_number}>
                        <td className='align-middle'>{current[0].first_name} {current[0].last_name}</td>
                        <td className='aling-middle'>{current[0].id_card_number}</td>
                        <td rowSpan={current.length} className={'align-middle text-center'}>{current[0].mobile_number}</td>
                      </tr>
                    ];
                    for(let i=1; i<current.length; i++)
                      returnList.push(
                        <tr key={current[i].id_card_number}>
                        <td className='aling-middle'>{current[i].first_name} {current[i].last_name}</td>
                        <td className='aling-middle'>{current[i].id_card_number}</td>
                      </tr>
                      )
                    return items.concat(returnList);
                  }, [])
                  }
                </tbody>
              </table>
              <div className='text-center'>
              <Pagination pageCount={this.props.voterTableDetails.total_pages} currentPage={this.props.voterTableDetails?.current_page - 1} pageSize={this.state.pageSize} onChangePage={ this.onChangePage } />
              </div>
              </div> : this.props.smsVoterLoaderStatus == true ? 
              <div className='text-center m-5'>
                <RotatingLines strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="96"
                  visible={true} />
              </div> : 
               this.props.voterDetailserr != '' ? 
              <Error /> :
                  <div className='text-center fw-600 fs-6 text-black-50'>No data found</div>
               }
          </div>
        </div>
      </div>
    </div>
    </>
      );
  }
}

export default withParams(SmsVoterTable);