import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Dashboard-Style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Pagination from '../Pagination';
import { RotatingLines } from  'react-loader-spinner';
import Error from '../error';

class DashboardImportantVoters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      constituency: this.props.constituency,
      block: this.props.block,
      village: this.props.village,
      error:'',
    }
    this.onChangePage = this.onChangePage.bind(this);
  }

  onChangePage(event) {
    this.props.onChangeImportantVotersPagination(this.props.constituency, this.props.block, this.props.village, this.props.pollingStation, event, 10);
  }
  render() {
    return(
        <div className='col-md-6 col-sm-6 col-12'>
          <div className='card shadow mt-3'>
            <div className='py-2 mx-1'>
              <div className='ps-1 header-style d-inline'>
                <span>Important Voters </span>
                <span className='text-primary'>
                <FontAwesomeIcon icon={faDownload} />
                </span>
              </div>
              <div className='d-inline'>
                <Link to={`/important-voters`} className='btn btn-success ms-1 float-end header-style'>View All</Link>
              </div>
            </div>
            <div className='mx-1'>
            { this.props.importantVotersData != '' ? 
            <table className="table table-responsive-sm">
              <thead className='table-header-style'>
                <tr>
                  <td className='table-header-font text-start'>Name</td>
                  <td className='table-header-font'>Mobile</td>
                  <td className='table-header-font text-start'>Voter ID</td>
                  <td className='table-header-font text-start'>Village</td>
                </tr>
              </thead>
              <tbody>
                {
                  this.props.importantVotersData?.results.map(importantVoter => 
                  <tr key={importantVoter.id_card_number}>
                    <td className='normal-font text-start'>{ importantVoter.first_name } {importantVoter.last_name}</td>
                    <td className='normal-font'>{ importantVoter.mobile_number }</td>
                    <td className='normal-font text-start text-uppercase'>{ importantVoter.id_card_number }</td>
                    <td className='normal-font text-start'>{ importantVoter.polling_station.village.name }</td>
                  </tr>
                    )
                }
                <tr>
                  <td colSpan={4} className="text-center">
                    <Pagination pageCount={this.props.importantVotersData?.count} currentPage={this.state.importantVoters?.current_page ? this.state.importantVoters?.current_page - 1 : -1} pageSize={10} onChangePage={this.onChangePage} />
                  </td>
                </tr>
              </tbody>
            </table> : this.props.loaderStatus ? 
            <div className='data-loader text-center'>
            <RotatingLines strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="96"
                          visible={true} />
              </div> : this.state.error != '' ? 
              <Error /> : <div className='text-center fw-600 fs-6 text-black-50'>No data found</div>
            }
            </div>
          </div>
        </div>
    );
  }
}

export default DashboardImportantVoters;