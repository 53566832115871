import React from "react";
import "react-widgets/styles.css";
import { getNameFromList } from '../services/Voters';
import Combobox from "react-widgets/Combobox";

class Constituencies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      inputValue: this.props.selectedConstituencyObj ? this.props.selectedConstituencyObj : { "id": 86, "name": "Munugode" }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(event) {
    this.props.onChangeConstituencies(event.id, event);
  }

  handleChange(event) {
    this.setState({inputValue: event});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedconstituency !== this.props.selectedconstituency || prevProps.clear !== this.props.clear) {
      this.setState({...this.state, inputValue: { "id": 86, "name": "Munugode" }});
    }
  }
  
  render() {
    const fieldProperties = this.props.constituencyFields.constituency;
    return(
      <div className={ `col-md-${fieldProperties?.colmd} col-lg-${fieldProperties?.collg} col-${fieldProperties?.col} col-sm-${fieldProperties?.collg}` }>
        <div className='form-group text-start my-auto'>
          { fieldProperties.labelStatus ? <label>{ fieldProperties.label }</label> : '' }
          <Combobox data={this.props.constituencies} options={this.props.constituencies} placeholder={fieldProperties?.placeholder} dataKey='id' textField='name' value={ this.state.inputValue } onChange={this.handleChange} onSelect={this.handleSelect} disabled={this.props.disabled}/>
        </div>
      </div>
    );
  }
}

export default Constituencies;